var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.past_transactions.length == 0)?_c('v-card',{staticClass:"mt-4"},[_c('v-card-text',[_c('strong',[_vm._v("There are no Bridge Transactions yet.")])])],1):_vm._e(),_c('v-card',{staticClass:"mt-4 pb-2 pt-2"},[_c('p',{staticClass:"m-0 p-1"},[_vm._v("All Transactions are verified through Multisig Contracts: ")]),_vm._v(" ETH SIGNER: "),_c('a',{attrs:{"icon":"","href":("" + _vm.EXPLORER_ETHO_ADDRESS + _vm.address_SIGNER_ETH),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.address_SIGNER_ETH)+" ")]),_vm._v(" ETH VERIFIER: "),_c('a',{attrs:{"icon":"","href":("" + _vm.EXPLORER_ETH_ADDRESS + _vm.address_VERIFIER_ETH),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.address_VERIFIER_ETH)+" ")]),_c('br'),_vm._v(" BSC SIGNER: "),_c('a',{attrs:{"icon":"","href":("" + _vm.EXPLORER_ETHO_ADDRESS + _vm.address_SIGNER_BSC),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.address_SIGNER_BSC)+" ")]),_vm._v(" BSC VERIFIER: "),_c('a',{attrs:{"icon":"","href":("" + _vm.EXPLORER_BSC_ADDRESS + _vm.address_VERIFIER_BSC),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.address_VERIFIER_BSC)+" ")]),_c('p',{staticClass:"m-0 p-1"},[_vm._v(" Current Multisig Signatures: "),_vm._l((_vm.MULTISIGOWNERS),function(item){return _c('a',{key:item},[_c('a',{attrs:{"icon":"","href":("" + _vm.EXPLORER_ETHO_ADDRESS + item),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.getMultisigName(item))+" ")])])})],2)]),(_vm.past_transactions.length > 0)?_c('v-card',{staticClass:"mt-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.past_transactions,"height":"34vh"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.BNStrToNumstr(item.amount,1)))]),_vm._v(" $ ")]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.BNStrToNumstr(item.fee,1)))]),_vm._v(" $ ")]}},{key:"item.event_name",fn:function(ref){
var item = ref.item;
return [(item.target_network == _vm.mainnet_chainID)?_c('img',{staticStyle:{"height":"28px","object-fit":"contain","margin-right":"3px"},attrs:{"src":require("../assets/images/storageprotocol/cubits_black_256.png")}}):_vm._e(),(item.target_network == _vm.token_chainID)?_c('img',{staticStyle:{"height":"28px","object-fit":"contain","margin-right":"10px","margin-left":"3px"},attrs:{"src":require("../assets/images/storageprotocol/eth-logo.svg")}}):_vm._e(),(item.target_network == _vm.token_chainID2)?_c('img',{staticStyle:{"height":"28px","object-fit":"contain","margin-right":"3px","margin-left":"0px"},attrs:{"src":require("../assets/images/storageprotocol/bsclogo.png")}}):_vm._e(),_c('b',[_vm._v(_vm._s(item.event_name.toUpperCase()))]),_vm._v(" "+_vm._s(item.target_network == _vm.token_chainID ? 'ERC-20' : item.target_network == _vm.token_chainID2 ? 'BEP-20' : 'SRC-20')+" ")]}},{key:"item.target_network",fn:function(ref){
var item = ref.item;
return [(item.target_network != _vm.mainnet_chainID)?_c('img',{staticStyle:{"height":"19px","object-fit":"contain"},attrs:{"src":require("../assets/images/storageprotocol/sUSDT_to_tether_swap_256.png")}}):_vm._e(),(item.target_network == _vm.mainnet_chainID)?_c('img',{staticStyle:{"height":"19px","object-fit":"contain"},attrs:{"src":require("../assets/images/storageprotocol/tether_to_sUSDT_swap_256.png")}}):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'WAITING')?_c('img',{staticStyle:{"height":"25px","margin-top":"-2px","object-fit":"contain"},attrs:{"src":require("../assets/images/etho/hourglass3.png")}}):_vm._e(),(item.status == 'EXECUTED')?_c('img',{staticStyle:{"height":"24px","margin-top":"-4px","object-fit":"contain"},attrs:{"src":require("../assets/images/etho/check.png")}}):_vm._e(),(item.status == 'FAILED')?_c('img',{staticStyle:{"height":"27px","margin-top":"-3px","object-fit":"contain"},attrs:{"src":require("../assets/images/etho/close.png")}}):_vm._e(),_c('a',{staticStyle:{"margin-left":"11px"},attrs:{"id":("tooltip_" + (item.tx_id.substr(0,8)))}},[_vm._v(_vm._s(item.signature_count))]),_c('b-tooltip',{attrs:{"target":("tooltip_" + (item.tx_id.substr(0,8))),"triggers":"hover"}},[_vm._v(" "+_vm._s(item.signature_names)+" ")])]}},{key:"item.tx_id",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"px-1",staticStyle:{"color":"black"},attrs:{"icon":"","href":("" + (item.receipt)),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(item.tx_id.substr(0,5))+"..."+_vm._s(item.tx_id.substr(-3)))])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"px-1",staticStyle:{"color":"black"},attrs:{"icon":"","href":("" + (item.address)),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(item.to))])]}}],null,false,3107948976)})],1):_vm._e(),_c('v-card',{staticClass:"mt-4 pb-4"},[_c('br'),_vm._v(" Multisig Wallet (ETHO NETWORK): "),_c('a',{attrs:{"icon":"","href":("" + _vm.EXPLORER_ETHO_ADDRESS + _vm.DEV_WALLET_ETHO),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.DEV_WALLET_ETHO)+" ")]),_c('br'),_vm._v(" Multisig Wallet (ETH NETWORK): "),_c('a',{attrs:{"icon":"","href":("" + _vm.EXPLORER_ETH_ADDRESS + _vm.DEV_WALLET_ETH),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.DEV_WALLET_ETH)+" ")]),_c('br'),_vm._v(" Multisig Wallet (BSC NETWORK): "),_c('a',{attrs:{"icon":"","href":("" + _vm.EXPLORER_BSC_ADDRESS + _vm.DEV_WALLET_BSC),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.DEV_WALLET_BSC)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }