<template >
  <v-container fluid>
    <v-row dense align="center" justify="center">
      <v-col>
        <v-card height="auto" align="center" justify="center">
          <v-card-actions>
            <v-row dense  align="center" justify="center">
            <div class="d-md-block justify-content-center ">
              <a :href="`${EXPLORER_ETHO_ADDRESS}${address_BA}`" target="_blank" rel="noopener noreferrer"><img :src='"../assets/images/etho/logo-only.svg"' style="height: 100px; object-fit: contain;" /></a>
              <p class="custom-transform-class text-none">ETHO<br>
              <span class="custom-transform-class text-none" style="font-size: 10px;">Etho Protocol Network</span>
              <br>
              <span class="font-weight-black totalAssetFont">{{ EthoWithComma(BNStrToNumstr(currentLocked_ETHO)) }} TOTAL</span>
              
              </p>
 
            </div>
          </v-row>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col align="center" justify="center">
        <v-card class="mx-16 py-0">
        <v-btn v-if="directionIndicatorEthoToEth" @click="changeEthDirection(true)" icon>
          <v-icon style="font-size: 42px;">mdi-arrow-right-bold</v-icon>
        </v-btn>

        <v-btn v-if="directionIndicatorEthToEtho" @click="changeEthDirection(true)" icon>
          <v-icon style="font-size: 42px;">mdi-arrow-left-bold</v-icon>
        </v-btn>
        </v-card>
        <p class="custom-transform-class text-none" style="font-size: 10px;">Swap Direction</p>
          
        <a class="mx-16 py-2 mt-5" v-b-tooltip.hover :title="bridge_status === 'OK' ? 'Bridge Balance is OK.' : 'Bridge Balance is not OK.'">
          <p class="custom-transform-class text-none mb-1" style="font-size: 16px;">Monitor</p>
          <p class="custom-transform-class text-h5 monitorLabel" style="background-color: #46c946;" v-if="bridge_status === 'OK'">{{ bridge_status }}</p>
          <p class="custom-transform-class text-h6 monitorLabel" style="background-color: #e91a1a;font-size: 36px !important;" v-if="bridge_status === 'NOT OK!'">{{ bridge_status }}</p>
          <p class="custom-transform-class text-h6 monitorLabel" style="background-color: grey;" v-if="bridge_status === 'loading...'">{{ bridge_status }}</p>
        </a>


      </v-col>

      <v-col>
        <v-card height="auto" align="center" justify="center">
          <v-card-actions>
            <v-row dense  align="center" justify="center">
            <div class="d-md-block justify-content-center" align="center" justify="center">
              <a :href="`${EXPLORER_BSC_ADDRESS}${address_TKN}`" target="_blank" rel="noopener noreferrer"><img :src='"../assets/images/etho/bsclogo.png"' style="height: 100px; object-fit: contain;" /></a>
              <p class="custom-transform-class text-none">Wrapped ETHO<br>
              <span class="custom-transform-class text-none" style="font-size: 10px;">Binance Smart Chain</span>
              <br>
              <span class="font-weight-black totalAssetFont">{{ EthoWithComma(BNStrToNumstr(currentLocked_ETH)) }} TOTAL</span>
             
              </p>

            </div>
          </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


    <v-row dense align="center" justify="center" v-if="directionIndicatorEthoToEth">
      <v-col>
        <v-card height="auto" align="center" justify="center">
          <v-card-actions>
            <div align="center" justify="center">
            <div class="d-md-block justify-content-center balanceInfoBlock">
              <p class="convertText" style="float:left;">Balance:  <span class="font-weight-black">{{ EthoWithComma(BNStrToNumstr(currentBalanceETHO)) }} <img :src='"../assets/images/etho/logo-only.svg"' class="miniETHO" style="height: 12px;" /> </span></p> 
             <br>
              <p class="convertText" style="float:left;">Fee:  <span class="font-weight-black">{{ EthoWithComma(BNStrToNumstr(feeBNStr)) }} <img :src='"../assets/images/etho/logo-only.svg"' class="miniETHO" style="height: 12px;" /> </span></p> 
              <br>
              <p class="convertText" style="float:left;">Minimum:  <span class="font-weight-black">{{ EthoWithComma(BNStrToNumstr(minBNStr)) }} <img :src='"../assets/images/etho/logo-only.svg"' class="miniETHO" style="height: 12px;" /> </span></p> 
            </div>
          </div>
          </v-card-actions>
        </v-card>
      </v-col>

      <div class="dividerCol">
      </div>

      <v-col>
        <v-card height="auto" align="center" justify="center">
          <v-card-actions>
            <v-row dense  align="center" justify="center">
            <div class="d-md-block justify-content-center balanceInfoBlock">
              <!-- <p class="text-left convertText">Balance:  <span class="font-weight-black">{{ EthoWithComma(BNStrToNumstr(balanceNumStr_TKN)) }} <img :src='"../assets/images/etho/eth-logo.svg"' class="miniETH" style="height: 12px;" /> </span></p>  -->
              <p class="convertText" style="float:left;">Balance:  <span class="font-weight-black">{{ EthoWithComma(BNStrToNumstr(balanceNumStr_TKN)) }} <img :src='"../assets/images/etho/bsclogo.png"' class="miniETH" style="height: 12px;" /> </span></p> 
            </div>
          </v-row>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>

    <v-row dense align="center" justify="center" v-if="directionIndicatorEthToEtho">

      <v-col>
        <v-card height="auto" align="center" justify="center">
          <v-card-actions>
            <v-row dense  align="center" justify="center">
            <div class="d-md-block justify-content-center balanceInfoBlock">
              <p class="text-left convertText">Balance:  <span class="font-weight-black">{{ EthoWithComma(BNStrToNumstr(balanceNumStr_Ether1) ) }} <img :src='"../assets/images/etho/logo-only.svg"' class="miniETHO" style="height: 12px;" /> </span></p> 
            </div>
          </v-row>
          </v-card-actions>
        </v-card>
      </v-col>

      <div class="dividerCol">
      </div>

      <v-col>
        <v-card height="auto" align="center" justify="center">
          <v-card-actions>
            <div align="center" justify="center">
            <div class="d-md-block justify-content-center balanceInfoBlock">
              <p class="convertText" style="float:left;">Balance:  <span class="font-weight-black">{{ EthoWithComma(BNStrToNumstr(currentBalanceETH)) }} <img :src='"../assets/images/etho/bsclogo.png"' class="miniETH" style="height: 12px;" /> </span></p> 
             <br>
              <p class="convertText" style="float:left;">Fee:  <span class="font-weight-black">{{ EthoWithComma(BNStrToNumstr(feeBNStr)) }} <img :src='"../assets/images/etho/bsclogo.png"' class="miniETH" style="height: 12px;" /> </span></p> 
              <br>
              <p class="convertText" style="float:left;">Minimum:  <span class="font-weight-black">{{ EthoWithComma(BNStrToNumstr(minTKNStr)) }} <img :src='"../assets/images/etho/bsclogo.png"' class="miniETH" style="height: 12px;" /> </span></p> 
            </div>
          </div>
          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>


    <v-row justify="center" align="center" v-if="contractInfoOk">


      <v-col cols="8">

        <v-btn class="py-4" style="z-index: 999999;position: absolute;right: 20px;top: 30px;width: 50px;" v-if="canSwap" @click="restoreInputAmount()" icon>
          <p class="text-none" style="font-size: 12px;margin:2px;">All</p>
        </v-btn>

        <v-text-field
          v-if="directionIndicatorEthoToEth"
          v-model="inputAmount"
          label="Amount"
          outlined
          dense
          required
          @click:append="restoreInputAmount"
              :rules="[
                () => amountValid || 'Enter proper amount',
                () => amountEnough || amountZero || 'Amount is too low',
                () => balanceEnough || amountEqual || 'Balance too low',
              ]"
        ></v-text-field>


        <v-text-field
          v-if="directionIndicatorEthToEtho"
          v-model="inputAmount"
          label="Amount"
          outlined
          dense
          required
          @click:append="handleClickAppend"
          :rules="[() => amountValid || 'Enter proper amount', () => amountEnough || 'Amount is too low', () => balanceEnough || 'Balance too low']"
        ></v-text-field>


        
        <v-card class="mb-8" height="44" align="center" justify="center" v-if="directionIndicatorEthoToEth && amountEnough">
          <p class="text-center py-3" style="font-size: 12px;">
            You will receive:  <span class="font-weight-black">{{ amountEnough ? '~' + BNStrToNumstr(amountReceivedBNStr) : '...' }}  Wrapped ETHO </span><img :src='"../assets/images/etho/bsc.png"' style="margin-left:8px; height: 14px; object-fit: contain;" />
            <!-- Fee:  <span class="font-weight-black red--text">{{ amountEnough ? '~' + BNStrToNumstr(this.amountBN.sub(amountReceivedBNStr)) : '...' }} ETHO </span><img :src='"../assets/images/etho/logo.png"' style="margin-left:16px; height: 18px; object-fit: contain;" /> -->
          </p> 
         
        </v-card>
        <v-card  class="mb-8" height="40" align="center" justify="center" v-if="directionIndicatorEthToEtho && amountEnough">
          <p class="text-center py-2" style="font-size: 12px;">You will receive:  <span class="font-weight-black">{{ amountEnough ? '~' + BNStrToNumstr(amountReceivedBNStr) : '...' }}  ETHO </span><img :src='"../assets/images/etho/logo.png"' style="margin-left:16px; height: 18px; object-fit: contain;" /></p> 
        </v-card>
      </v-col>
    </v-row>

    <v-card width="200">
        <v-btn text :color="aboutToNullify ? 'warning' : 'success'" large :loading="loading_request" :disabled="requestDisabled" @click="handleClickRequest">
          {{ aboutToNullify ? 'Take Back' : 'Request Swap' }}
        </v-btn>
    </v-card>

    <v-row justify="center" align="center" class="mt-8">
      <v-alert border="right" color="red lighten-2" dark v-if="!!warningMessage"><p class="text-center" style="color:white; font-size: 12px;">{{ warningMessage }}</p></v-alert>
      <v-alert border="right" color="orange lighten-2" dark v-if="!!hintMessage"><p class="text-center" style="color:white; font-size: 12px;">{{ hintMessage }}</p></v-alert>
      <v-alert border="right" color="blue-grey" dark v-if="!!inbetweenstatus"><p class="text-center" style="color:white; font-size: 12px;">{{inbetweenstatus}}</p></v-alert>
      <v-alert border="right" color="blue-grey" dark v-if="!!successMessage"><p class="text-center" style="color:white; font-size: 12px;">{{ successMessage }}</p></v-alert>

      <v-alert border="right" color="blue-grey" dark v-if="loading_controllerInfo"><p class="text-center" style="color:white; font-size: 12px;">Loading Contract Information</p></v-alert>
      <v-alert border="right" color="blue-grey" dark v-if="!!hashes"><p class="text-center" style="color:white; font-size: 12px;">Transaction hashes:
        <br />
         {{ directionIndicatorEthoToEth ? 'Deposit' : 'Collect' }} (ETHO Network):
        <a style="color:black;" :href="`${EXPLORER_ETHO_TX}${hashes.txHashCollect}`" target="_blank" rel="noopener noreferrer">
          {{ hashes.txHashCollect }}
        </a>
        <br />
        {{ directionIndicatorEthoToEth ? 'Mint' : 'Burn' }} (BSC Network):
        <a style="color:black;" :href="`${EXPLORER_ETH_TX}${hashes.txHashDispense}`" target="_blank" rel="noopener noreferrer">
          {{ hashes.txHashDispense }}
        </a></p>
      </v-alert>
    </v-row>

    <v-row dense>
      <v-col>
        <v-card>
          <v-card-actions>
            <v-spacer></v-spacer>

           <v-btn icon class="px-14" href="https://pancakeswap.info/token/0x48b19b7605429acaa8ea734117f39726a9aab1f9" target="_blank">
              <img :src='"../assets/images/etho/pancakeswap.png"' style="margin-left:16px; height: 18px; object-fit: contain;" />
            </v-btn>

            <v-btn icon class="px-14" href="https://bscscan.com/token/0x48b19b7605429acaa8ea734117f39726a9aab1f9" target="_blank">
              <img :src='"../assets/images/etho/bscscan.svg"' style="margin-left:16px; height: 18px; object-fit: contain;" />
            </v-btn>

 
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable camelcase */
//import Vue from 'vue'
import { Contract, providers, BigNumber,utils } from 'ethers'
// import Axios from 'axios'
//import firebase from 'firebase/app'
//import 'firebase/firestore'
import BridgeAssistO from '/abis/Token.json'
import Token from '/abis/Token.json'
import Oracle from '/abis/Token.json'
import Signer from '/abis/Token.json'

//const address_BAO = '0x22a2a6ac2b0c26023353077e6a5572503ec053a5' // 3DFA1F055CA9F5ACBE6FD6A8B07A89EAC0EDE6244EA7953AC8D1D2B50F652A03 0x22a2a6ac2b0c26023353077e6a5572503ec053a5
const address_TKN = '0x55d398326f99059fF775485246999027B3197955'; // USDT CONTRACT
const address_BA = '0x7777777777777777777777777777777777777777'; // 3DFA1F055CA9F5ACBE6FD6A8B07A89EAC0EDE6244EA7953AC8D1D2B50F652A03 0x22a2a6ac2b0c26023353077e6a5572503ec053a5
const address_ORCL = '0x1509c8F22b7a14bab4EA829324BFcc44371A5667'; // NOT NEEDED
const address_SIGNER = '0x9990000000000000000000000000000123456789';
const address_SIGNER_authority = "0x684D3FafdB1A6aaadA80F77822165203fD4e543d" ; //"0xcaded6e8b266b9fb5781506885283552387c748b"; //'0xB5e919EAfCF60932060Cd4A9d9b9568736CDFBf0'; // transmitter client for receipts

const address_DEVFUND = '0xBA57dFe21F78F921F53B83fFE1958Bbab50F6b46'; // NOT NEEDED

const TOKEN_CONFIRMATIONS = 12;
const ETHO_CONFIRMATIONS = 22;

// const ETH_LOGO = "../assets/images/etho/eth-logo.svg";
// console.log("ETH_LOGO",ETH_LOGO)
const EXPLORER_BSC_ADDRESS = "https://bscscan.com/address/";
const EXPLORER_ETHO_ADDRESS = "https://explorer.storageprotocol.com/address/";
const EXPLORER_ETHO_TX = "https://explorer.storageprotocol.com/tx/";
// const EXPLORER_ETHO_TX = "https://testnetexplorer2.storageprotocol.com/tx/";
//const EXPLORER_ETH_TX = "https://testnet.bscscan.com/tx/";
//const EXPLORER_ETH_TX = "https://ropsten.etherscan.io/tx/";
const EXPLORER_ETH_TX = "https://etherscan.io/tx/";
// const EXPLORER_ETH_TX = "https://sepolia.etherscan.io/tx/";

// const providerO = new providers.JsonRpcProvider('https://rpc.storageprotocol.com')

//const token_rpc_url = "https://ropsten.infura.io/v3/ad333b804b7c4892a5826cfcc09db9ba";

//const mainnet_rpc_url = "https://rpc.storageprotocol.com";
const token_chainID = 56; //"0x3";
//const token_chainID = 97; //"0x3";
const mainnet_chainID = 420500100; //"0x14095a";

//const provider_TOKEN = new providers.InfuraProvider(3, 'ad333b804b7c4892a5826cfcc09db9ba');
// const provider_TOKEN = new providers.InfuraProvider(11155111, '9aa3d95b3bc440fa88ea12eaa4456161');
const provider_TOKEN = new providers.JsonRpcProvider('https://bsc-dataseed.binance.org');
const provider_ETHO = new providers.JsonRpcProvider('https://rpc.storageprotocol.com');
//const provider_TOKEN = new providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
//const provider_TOKEN = new providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/')

const TKN = new Contract(address_TKN, Token.abi, provider_TOKEN)
const ORCL = new Contract(address_ORCL, Oracle.abi, provider_ETHO)

// mainnetblock = await provider_TOKEN.getBlock("latest")
// console.log(provider_TOKEN.getBlock("latest"))
// const TRANSMITTER_ACCOUNT = "0xcaded6e8b266b9fb5781506885283552387c748b"
const TRANSMITTER_ACCOUNT = utils.arrayify(address_SIGNER_authority)


const SGNR = new Contract(address_SIGNER, Signer.abi, provider_ETHO) 

const ETH_PRICE_URL = "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=eth"
const ETHO_ETH_MIN_DEPOSIT = 1 // in dollars
const ETH_MIN_WITHDRAWAL = 1 // in dollars

// let _tx = "0x6a7d68cdd80d2b645ffce5aafcae892bdcc1389375665f92439cb6209de0b637"

// let encodePacked =  utils.concat([ TRANSMITTER_ACCOUNT, utils.toUtf8Bytes(_tx) ])
// const _hash = utils.keccak256(encodePacked)

// console.log("_hash",_hash)
// let result_hashes = SGNR.GetBridgeReceipt(_hash);

// console.log("result_hashes",result_hashes)
// console.log("_tx",_tx)



//const url_baseIP = "https://ethobridge.uc.r.appspot.com";
//const url_baseIP = "http://localhost:5533";
//const url_baseIP = "http://52.50.170.41:5533";
// const url_baseIP = "https://bridge.storageprotocol.com/api";
//const url_baseIP = "https://52.50.170.41/api";


//const url_baseIP = "http://77.20.0.26:5533";

// const url_ethoTOwetho = url_baseIP+"/ETHOtoWETHO/ETH/";
// const url_wethoTOetho = url_baseIP+"/WETHOtoETHO/ETH/";

//// console.log("TKN",TKN)

function removeTrailingZeros(str) {
if (str === '0') return str
if (str.slice(-1) === '0') return removeTrailingZeros(str.substr(0, str.length - 1))
if (str.slice(-1) === '.') return str.substr(0, str.length - 1)
return str
}
function numstrToBN(input) {
const spl = input.split('.')
if (spl[1]) spl[1] = spl[1].substr(0, 18)
return BigNumber.from(spl.join('') + '000000000000000000'.substr(0, 18 - (spl[1] || '').length))
}
function BNStrToNumstr(str, precision = 3) {
////// console.log("str",str)
str = str.toString()
if (str === '0') return str
if (isNaN(Number(str))) return 'NaN'
if (str.length <= 18) return removeTrailingZeros(('0.' + '000000000000000000'.substr(0, 18 - str.length) + str).substr(0, 18 - str.length + precision + 2))
else return [str.substr(0, str.length - 18), str.slice(-18)].join('.').substr(0, str.length - 18 + precision + 1)
}
function EthoWithComma(amount) {
  var str = amount.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
}


function Sleep(milliseconds) {
return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export default {
name: "BscBridge",
data() {
  return {
    balanceNumStr_Ether1: '',
    directionIndicatorEthToEtho: false,
    directionIndicatorEthoToEth: true,
    provider: providers.Web3Provider | null,
    signer: providers.JsonRpcSigner | null,
    wallet: '',
    EXPLORER_ETHO_TX:EXPLORER_ETHO_TX,
    EXPLORER_ETHO_ADDRESS:EXPLORER_ETHO_ADDRESS,
    EXPLORER_BSC_ADDRESS:EXPLORER_BSC_ADDRESS,
    EXPLORER_ETH_TX:EXPLORER_ETH_TX,
    ETH_PRICE_URL:ETH_PRICE_URL,
    // ETH_LOGO:ETH_LOGO,
    address_TKN:address_TKN,
    address_BA:address_BA,
    address_ORCL:address_ORCL,
    address_SIGNER:address_SIGNER,
    bridge_status: 'loading...',
    inbetweenstatus: '',
    currentLocked_ETHO: '',
    currentLocked_ETH: '',
    currentBurned: '',
    currentBalance: '',
    currentBalanceETH: '',
    currentBalanceETHO: '',
    balanceNumStr_TKN: '',
    inputAmount: '',
    etho_price: -1,
    last_etho_price: Date.now(),
    costsInfo: {
      WETHOtoETHO: '',
      ETHOtoWETHO: ''
    } | null,
    paramsInfo: {
      minETH: 0,
      minETHO: 0,
      preShutdownFlag: false
    } | null,
    warningMessage: '',
    hintMessage: '',
    successMessage: '',
    loading_request: false,
    loading_controllerInfo: true,
    loading_contractInfo: true,
    // inBetween: false,
    hashes: { txHashCollect: '', txHashDispense: '' } | null,
  }
},
computed: {
  // eth_logo() {
  //   return require(ETH_LOGO);
  // },
  burnedEnough() {
    if (!this.controllerInfoOk || !this.contractInfoOk) return false
    if(this.directionIndicatorEthoToEth) {
      return this.burnedBN.gte(this.minBNStr)
    } else {
      return this.burnedBN.gte(this.minTKNStr)
    }
  },
  burnedBN() {
    return BigNumber.from(this.currentBurned)
  },
  convertBN(_nr) {
    return BigNumber.from((_nr.toString()))
  },
  amountEqual() {
    if(this.directionIndicatorEthoToEth) {
      return this.amountBN.eq(this.currentLocked_ETHO)
    }
    return this.amountBN.eq(this.currentBurned)
  },
  entryExists() {
    if (!this.contractInfoOk || !this.currentLocked_ETHO) return false
    return this.currentLocked_ETHO.gt(0)
  },
  canMaximizeAmount() {
    return !this.burnedNonZero && ((this.amountBN.lt(this.currentBalanceETHO) && this.directionIndicatorEthoToEth) || (this.amountBN.lt(this.currentBalanceETH) && this.directionIndicatorEthToEtho))
  },
  amountReceivedBNStr_ready() {
    if (!this.controllerInfoOk || !this.contractInfoOk) return 'NaN'
    return this.currentLocked_ETHO.sub(this.feeBNStr).toString()
  },
  providerOk() {
    return !!this.provider && !!this.signer && !!this.wallet
  },
  controllerInfoOk() {
    return !!this.costsInfo && !!this.paramsInfo
  },
  contractInfoOk() {
    // console.log("cinfo",!!this.currentLocked_ETHO,!!this.currentBalance)
    // console.log("cinfo",!!this.currentBalanceETHO,!!this.currentBalanceETH)
    if(this.directionIndicatorEthoToEth) {
      // return !!this.currentLocked_ETHO && !!this.currentBalance
      return !!this.currentBalanceETHO
    } else {
      return !!this.currentBalanceETH
    }
    //return !!this.currentBurned && !!this.currentBalance
    // return !!this.currentBalance

  },
  allSafe() {
    return this.providerOk && this.controllerInfoOk && this.contractInfoOk && !this.warningMessage && !this.loading_controllerInfo
  },
  requestDisabled() {
    return !this.allSafe || !this.balanceEnough || (!this.amountEnough && !this.aboutToNullify)
  },
  aboutToNullify() {
    // return this.amountZero//&& this.entryExists //  && this.inputAmount != 0 
    return false
  },
  amountZero() {
    return this.inputAmount === '0'
  },
  amountValid() {
    return !!Number(this.inputAmount) || this.amountZero
  },
  amountEnough() {
    if (!this.controllerInfoOk) return false
    if(this.directionIndicatorEthoToEth) {
      return this.amountBN.gte(this.minBNStr)
    } else {
      return this.amountBN.gte(this.minTKNStr)
    }
  },
  canSwap() {
    if (!this.controllerInfoOk) return false
    if(this.directionIndicatorEthoToEth) {
      return this.currentBalanceETHO.gte(this.minBNStr)
    } else {
      return this.currentBalanceETH.gte(this.minTKNStr)
    }
  },
  balanceEnough() {
    if (!this.controllerInfoOk) return false
    
    if(this.directionIndicatorEthoToEth) {
      return this.amountBN.lte(this.currentBalanceETHO)
    } else {
      return this.amountBN.lte(this.currentBalanceETH)
    }
  },
  lockedEnough() {
    if (!this.controllerInfoOk || !this.contractInfoOk) return false
    if(this.directionIndicatorEthToEtho) {
      return BigNumber.from(this.currentLocked_ETHO).gte(this.minTKNStr)
      //return true
    } else {
      return true // always enough Wrapped etho existing ;)
    }

  },
  amountBN() {
    if (!this.amountValid) return BigNumber.from(0)
    return numstrToBN(this.inputAmount)
  },
  amountReceivedBNStr() {
    if (!this.controllerInfoOk) return 'NaN'
    return this.amountBN.sub(this.feeBNStr).toString()
  },
  feeBNStr() {
    //// console.log("ETHOOOOOO BRIDGE",this.controllerInfoOk)
    if (!this.controllerInfoOk) return 'NaN'
    if(this.directionIndicatorEthoToEth) {
      //return BigNumber.from(this.costsInfo.ETHOtoWETHO).mul(this.paramsInfo.costToFeeQuotient).div('100').toString()
      return BigNumber.from(this.costsInfo.ETHOtoWETHO).toString()
      
    } else {
      //return BigNumber.from(this.costsInfo.WETHOtoETHO).mul(this.paramsInfo.costToFeeQuotient).div('100').toString()
      return BigNumber.from(this.costsInfo.WETHOtoETHO).toString()
    }
    
  },
  minTKNStr() {
    if (!this.controllerInfoOk) return 'NaN'
    //return BigNumber.from(this.costsInfo.WETHOtoETHO).add(this.paramsInfo.minETH).toString()
    return BigNumber.from(this.paramsInfo.minETHO).toString()
  },
  minBNStr() {
    if (!this.controllerInfoOk) return 'NaN'
    return BigNumber.from(this.paramsInfo.minETH).toString()
    //return BigNumber.from(this.costsInfo.ETHOtoWETHO).add(this.paramsInfo.minETHO).toString()
    //return BigNumber.from(this.costsInfo.ETHOtoWETHO)
    //  .mul(this.paramsInfo.costToFeeQuotient)
    //  .mul(this.paramsInfo.feeToMinQuotient)
    //  .mul('120')
    //  .div('1000000')
    //  .toString()
  },
},
async mounted() {
  await this.loadControllerInfo()
  if (this.controllerInfoOk) await this.connectProvider()
  this.loading_contractInfo = false
  setInterval(async () => {
    if (this.controllerInfoOk) {
      this.loading_controllerInfo = true
      await this.loadControllerInfo()
    }
  }, 30000)
  window.ethereum.on('networkChanged', () => {
    this.updateNetwork();
  })
  window.ethereum.on('accountsChanged', () => {
    this.handleAccountsChanged();
  })
},
methods: {
  changeEthDirection(manually_changed) {
    console.log("CHANGEDIRECTION MANUALLY:",manually_changed)
    if(this.directionIndicatorEthToEtho) {
      this.directionIndicatorEthToEtho = false;
      this.directionIndicatorEthoToEth = true;
      this.updateNetwork(manually_changed)
    } else {
      this.directionIndicatorEthToEtho = true;
      this.directionIndicatorEthoToEth = false;
      this.updateNetwork(manually_changed)
    }
  },
  async updateNetwork(manually_changed) {
    this.hashes = null
    this.warningMessage = '';
    this.hintMessage = '';
    this.successMessage = '';
    this.loading_controllerInfo = true
    await this.loadControllerInfo()
    if (this.controllerInfoOk) await this.connectProvider(manually_changed)    
    this.loading_contractInfo = false
  },
  BNStrToNumstr,
  EthoWithComma,
  async SwitchNetwork(targetID) {
      console.log("SWITCHING NETWORK NOW...")
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: targetID }], // chainId must be in hexadecimal numbers
      });
  },
  restoreInputAmount() {
    //this.inputAmount = removeTrailingZeros(BNStrToNumstr(this.currentLocked_ETHO, 18))
    //this.inputAmount = removeTrailingZeros(BNStrToNumstr(this.minBNStr, 8))
    if(this.directionIndicatorEthoToEth) {
      this.inputAmount = BNStrToNumstr(this.currentBalanceETHO.sub(BigNumber.from("100000000000000000")),18) || 0
    } else {
      // this.inputAmount = BNStrToNumstr(this.currentBalanceETH.sub(BigNumber.from("1000000000000000000")),18) || 0
      this.inputAmount = BNStrToNumstr(this.currentBalanceETH,18) || 0
    }

  },
  handleClickAppend() {
    if (this.burnedEnough && !this.amountEqual) this.restoreInputAmount()
    else if (this.canMaximizeAmount) this.maximizeInputAmount()
  },
  async loadEther1Balance() {
    if(this.directionIndicatorEthoToEth) {
      try {

        // ETHO BALANCE
        let thisBalance = await provider_ETHO.getBalance(this.wallet);
        this.currentBalanceETHO = BigNumber.from(thisBalance);

        this.balanceNumStr_Ether1 = this.currentBalanceETHO;

      } catch (error) {
        console.error(error)
      }
    } else if(this.directionIndicatorEthToEtho) {
      try {

        // ETHO BALANCE
        let thisBalance = await provider_ETHO.getBalance(this.wallet);
        this.currentBalanceETHO = BigNumber.from(thisBalance);

        this.balanceNumStr_Ether1 = this.currentBalanceETHO;

      } catch (error) {
        console.error(error)
      }
    }
  },
  async loadTKNBalance() {
    try {
      let balanceAmount = await TKN.balanceOf(this.wallet);
      this.balanceNumStr_TKN = BigNumber.from(balanceAmount);
      
      // ETH BALANCE
      this.currentBalanceETH = BigNumber.from(balanceAmount);
    } catch (error) {
      console.error(error)
    }
  },
  async getEthoPrice() {
    try {
      let xmlHttp = new XMLHttpRequest();
      xmlHttp.open( "GET", "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=usd", false );
      xmlHttp.send( null );
      let jsonData = JSON.parse(xmlHttp.responseText)
      console.log("etho_price","--",jsonData,jsonData["ether-1"]["usd"])
      if (jsonData["ether-1"] !== undefined) {
        return parseFloat(jsonData["ether-1"]["usd"])
      } else {
        return -1
      }
    } catch (error) {
      return -1
    }

  },
  async connectProvider(manually_changed) {
    try {
      var insideScope = this.$parent.$parent.showBscBridge
      if (insideScope) {
        if (!window.ethereum) throw new Error('Please set up MetaMask properly')
        await (window.ethereum).enable()
        this.provider = new providers.Web3Provider((window.ethereum) || window.web3)

        var found_chainID = (await this.provider.getNetwork()).chainId

        

        //console.log("manually_changed",manually_changed,"A token_chainID",token_chainID,"found_chainID",found_chainID,"controllerInfoOk",this.controllerInfoOk,"direction",this.directionIndicatorEthoToEth,"showEthBridge",insideScope)

      
        if(this.directionIndicatorEthoToEth) {
          if (found_chainID !== mainnet_chainID) {
            if (found_chainID === token_chainID && manually_changed !== true) { // swap networks..
              
              this.changeEthDirection()
            } else {
              // if (manually_changed === true) {
                this.SwitchNetwork("0x"+mainnet_chainID.toString(16))
              // }

              throw new Error('You selected wrong network in MetaMask. Make sure you imported and selected Etho Protocol network and refresh the page')
            }
          }
        } else if (this.directionIndicatorEthToEtho) {
          if (found_chainID !== token_chainID) {
            if (found_chainID === mainnet_chainID && manually_changed !== true) { // swap networks..
              
              this.changeEthDirection()
            } else {
              // if (manually_changed === true) {
                this.SwitchNetwork("0x"+token_chainID.toString(16))
              // }
              throw new Error('You selected wrong network in MetaMask. Make sure you selected Binance Smart Chain network and refresh the page')
            }
          }
        }

        this.signer = this.provider.getSigner()
        this.wallet = await this.signer.getAddress()
        //// console.log("this.wallet",this.wallet)
        await this.loadContractInfo()
      }


      //if (this.entryExists) this.restoreInputAmount()
    } catch (error) {
      this.warningMessage = 'Could not connect MetaMask. Error: ' + error.message
      console.error(error)
    }
  },
  async handleClickRequest() {
    this.hashes = null
    this.warningMessage = '';
    this.hintMessage = '';
    this.successMessage = '';
    this.loading_request = true
    // this.signer = this.provider.getSigner()

    if(this.directionIndicatorEthoToEth) {
      try {
        if (this.aboutToNullify) {
          await this.deposit()
        } else {
          //if ((this.entryExists && !this.amountEqual) || !this.entryExists) 
          var local_tx = await this.deposit()
          //// console.log("local_tx",local_tx)
          await this.requestOE(local_tx)
        }
        this.inputAmount = ''
      } catch (error) {
        this.hintMessage = error.message
        console.error(error)
      }
    } else if(this.directionIndicatorEthToEtho) {
      try {
        if ((this.burnedNonZero && !this.amountEqual) || !this.burnedNonZero) 
          var local_tx2 = await this.burn()
          //// console.log("local_tx2",local_tx2)
          await this.requestOE(local_tx2)
          this.inputAmount = ''
      } catch (error) {
        this.hintMessage = error.message
        console.error(error)
      }
    }

    // this.inBetween = false
    this.inbetweenstatus = ''
    this.loading_request = false
  },
  async burn() {
    var execute_chainID = (await this.provider.getNetwork()).chainId
    if (execute_chainID !== token_chainID) { // make sure user doesn't send into false network for sum bug reason
      throw new Error('You selected wrong network in MetaMask. Make sure you selected Binance Smart Chain network and refresh the page')
    }

    //// console.log("BURN...",this.amountBN,this.amountBN.toString())

    const TKN = new Contract(address_TKN, Token.abi, this.signer)

    //// console.log("TKN",TKN)

    //const ptx = await TKN.populateTransaction.burn(address_BA, this.amountBN)
    const ptx = await TKN.populateTransaction.burn(this.amountBN.toString()) // make user burn directly
    console.log({
      ptx,
      amt: this.amountBN.toString(),
      bal: this.currentBalanceETH.toString(),
    })
    
    const tx = await this.signer.sendTransaction(ptx)
    //// console.log({ tx })
    // this.inBetween = true

    this.triggerinbetweentracking(tx)

    const receipt = await tx.wait()

    this.triggerinbetweentracking2("token",receipt)

    await this.loadContractInfo() // pull this earlier since we changed contract
    
    //// console.log(receipt,receipt.transactionHash)
    return receipt.transactionHash
  },
  async triggerinbetweentracking(_tx) {
    console.log("-->tx:",_tx)
    let mainnetblock = await provider_TOKEN.getBlock("latest")
    let tokenblock = await provider_ETHO.getBlock("latest")
    console.log("token:",mainnetblock)
    console.log("etho:",tokenblock)
    console.log("tokennumber:",mainnetblock.number)
    console.log("ethonumber:",tokenblock.number)

    this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete."
  },
  async triggerinbetweentracking2(_net,_receipt) {
    console.log("--->receipt:",_receipt)
    console.log("token:",provider_TOKEN.blockNumber)
    console.log("etho:",provider_ETHO.blockNumber)
    let startblock = -1
    let startblock2 = -1
    if (_net == "etho") {
      let ethoblock = await provider_ETHO.getBlock("latest")
      startblock = ethoblock.number
    } else {
      let tokenblock = await provider_TOKEN.getBlock("latest")
      startblock = tokenblock.number
    }
    
    if (startblock == -1) {
      this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete."
    } else {
      var waitblocks = 20
      if (_net == "etho") {
        waitblocks = ETHO_CONFIRMATIONS
      } else {
        waitblocks = TOKEN_CONFIRMATIONS
      }
      let currentblock = 0
      while (currentblock < waitblocks) {
        if (_net == "etho") {
          let ethoblock = await provider_ETHO.getBlock("latest")
          currentblock = ethoblock.number- startblock - 2
          if (currentblock < 0) {
            currentblock = 0
          }
          this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete. Confirmation Block: "+currentblock+" / "+(ETHO_CONFIRMATIONS-2)
        } else {
          let tokenblock = await provider_TOKEN.getBlock("latest")
          currentblock = tokenblock.number - startblock - 2
          if (currentblock < 0) {
            currentblock = 0
          }
          this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete. Confirmation Block: "+currentblock+" / "+(TOKEN_CONFIRMATIONS-2)
        }
      }

      this.inbetweenstatus = "Confirmation Blocks Reached. Requesting Confirmation."

    if (_net == "etho") {
      let tokenblock = await provider_TOKEN.getBlock("latest")
      startblock2 = tokenblock.number
    } else {
      let ethoblock = await provider_ETHO.getBlock("latest")
      startblock2 = ethoblock.number
    }

      let waitblocks2 = 3
      let currentblock2 = 0
      while (currentblock2 < waitblocks2) { // CHECK OPPOSITE NETWORK, since we act crosschain now...
        if (_net == "etho") {
          let tokenblock = await provider_TOKEN.getBlock("latest")
          currentblock2 = tokenblock.number - startblock2
        } else {
          let ethoblock = await provider_ETHO.getBlock("latest")
          currentblock2 = ethoblock.number- startblock2
        }
        if (!this.successMessage) {
          this.inbetweenstatus = "Crosschain Confirmation: "+currentblock2+" / "+waitblocks2
        }
        
      }
      if (!this.successMessage) {
        this.inbetweenstatus = "Requesting Receipt from Controller...."
      }

    }

  },
  async deposit() {
    var execute_chainID = (await this.provider.getNetwork()).chainId
    if (execute_chainID !== mainnet_chainID) { // make sure user doesn't send into false network for sum bug reason
        throw new Error('You selected wrong network in MetaMask. Make sure you imported and selected Etho Protocol network and refresh the page')
    }

    const BAO = new Contract(address_BA, BridgeAssistO.abi, this.signer)
    const ptx = await BAO.populateTransaction.deposit()

    //const ptx = {
    //  from: this.wallet,
    //  to: address_BA
    //}

    ptx.value = this.amountBN
    console.log({
      ptx,
      amt: this.amountBN.toString(),
      bal: this.currentBalanceETHO.toString(),
    })
    const tx = await this.signer.sendTransaction(ptx)
    //// console.log({ tx })
    // this.inBetween = true
    this.triggerinbetweentracking(tx)

    const receipt = await tx.wait()

    this.triggerinbetweentracking2("etho",tx)

    //// console.log({ receipt })
    await this.loadContractInfo()
    
    return receipt.transactionHash
  },
  async requestOE(_tx) {
    // console.log("requestOE",_tx)
    // var _this = this

    async function getOE(_this) {

      // const SGNR = new Contract(address_SIGNER, Signer.abi, provider_ETHO.getSigner())
      // const SGNR = new Contract(address_SIGNER, Signer.abi, _this.signer)
      var _hash = utils.keccak256(utils.concat([ TRANSMITTER_ACCOUNT, utils.toUtf8Bytes(_tx) ]))
      // console.log("_hash",_hash)
      var result_hashes = null
      if(_this.directionIndicatorEthoToEth) {
        try {

          result_hashes = await SGNR.GetBridgeReceipt(_hash);


        } catch (error) {
          console.error({ ...error })
          throw new Error("FAILED TO CONTACT CONTRACT")
        }
        if (result_hashes !== null && result_hashes !== "" && result_hashes !== false) {
          // console.log("result_hashes",result_hashes)
          // console.log("_tx",_tx)
          return {
            "txHashCollect": _tx,
            "txHashDispense": result_hashes
          }
        } else {
          return null
        }
      } else if (_this.directionIndicatorEthToEtho) {
        try {


          result_hashes = await SGNR.GetBridgeReceipt(_hash);

        } catch (error) {
          console.error({ ...error })
          throw new Error("FAILED TO CONTACT CONTRACT")
        }
        if (result_hashes !== null && result_hashes !== "" && result_hashes !== false) {
          // console.log("result_hashes2",result_hashes)
          // console.log("_tx2",_tx)
          return {
            "txHashCollect": result_hashes,
            "txHashDispense":  _tx
          }
        } else {
          return null
        }
      }
    }

    var OE_response = null
    while (OE_response === null) {
      OE_response = await getOE(this)
      // console.log("OE_response",OE_response)
      if (OE_response === null) {
        await Sleep(3000) // sleep for 3 seconds
      } else {
        // updates balances after successful trade over
        // await Sleep(2000) // sleep for 3 seconds
        // await this.loadTKNBalance()
        // await this.loadEther1Balance()
        await this.updateBalances()
        console.log("OE FINISHED",OE_response)
      }
    }
    //// console.log(" WHILE LOOP IS OVER!")
    //await this.loadContractInfo()
    this.inbetweenstatus = ''
    this.successMessage = 'Success! Save transaction hashes below'

    this.hashes = OE_response


  },
  async handleAccountsChanged() {
    // console.log("A HANDLE ACCOUNT CHANGED")

    this.balanceNumStr_Ether1 = ""
    this.balanceNumStr_TKN = ""
    this.currentBalanceETH = ""
    this.feeBNStr = ""
    this.minTKNStr = ""
    this.currentBalanceETHO = ""

    this.warningMessage = '';
    this.hintMessage = '';
    this.successMessage = '';

    this.signer = this.provider.getSigner()
    this.wallet = await this.signer.getAddress()
    //// console.log("this.wallet",this.wallet)
    await this.loadContractInfo()
  },
  async loadControllerInfo() {
      this.loading_controllerInfo = false


      if (this.etho_price == -1 || ((this.last_etho_price-Date.now())/1000) > 15) { // more than 30 seconds passed
        this.etho_price = await this.getEthoPrice()

      }
      // console.log("etho_price",etho_price)
      if (this.etho_price !== -1) {
        this.last_etho_price = Date.now()
        try {
          let  ETH_TO_ETHO_current_fee = await ORCL.getPrice("BSC_ETHO_FEE",0,address_DEVFUND);
          let  ETHO_TO_ETH_current_fee = await ORCL.getPrice("ETHO_BSC_FEE",0,address_DEVFUND); 
          // console.log("ETHO_TO_ETH_current_fee",ETHO_TO_ETH_current_fee)
          let  ETHO_TO_ETH_current_minimum = ETHO_TO_ETH_current_fee.add( BigNumber.from(parseInt((ETHO_ETH_MIN_DEPOSIT / this.etho_price )+1) +"000000000000000000"));
          let  ETH_TO_ETHO_current_minimum = ETH_TO_ETHO_current_fee.add( BigNumber.from(parseInt((ETH_MIN_WITHDRAWAL / this.etho_price )+1) +"000000000000000000"));

          //this.paramsInfo = ((await db.collection('config').doc('changeables').get()).data()) || null
          //this.paramsInfo = {
          //  costToFeeQuotient: 500,
        //   feeToMinQuotient: 50000,
          //  preShutdownFlag: false
          //}
          // ETHO_TO_{}_current_fee
          this.paramsInfo = {
            "minETH":ETHO_TO_ETH_current_minimum,
            "minETHO":ETH_TO_ETHO_current_minimum,
          }
          this.costsInfo = {
            "WETHOtoETHO": ETH_TO_ETHO_current_fee,
            "ETHOtoWETHO": ETHO_TO_ETH_current_fee
          }

          // console.log(this.paramsInfo)
          // console.log(this.costsInfo)

          // this.paramsInfo = (await Axios.get(`${url_baseIP}`+'/info/params/eth')).data

          // if (!this.paramsInfo) throw new Error('Received invalid param data from API')
          // try {
          //   this.costsInfo = (await Axios.get(`${url_baseIP}`+'/info/costs/eth')).data
          //   //// console.log("this.costsInfo",this.costsInfo)
          // } catch (error) {
          //   throw error.response?.data || error.message
          // }
          // if (this.paramsInfo.preShutdownFlag) this.warningMessage = 'Controller will soon shut down for maintenance. Usage is blocked. Please wait'
        } catch (error) {
          this.warningMessage = 'Could not load info from controller. Usage is blocked. Try refreshing the page, try later or contact support'
          console.error(error)
        }


      }

     
      
    },


  async updateBalances() {
    var locked_ETHO_Amount = await provider_ETHO.getBalance(address_BA);
    this.currentLocked_ETHO = BigNumber.from(locked_ETHO_Amount);

    var locked_ETH_Amount = await TKN.totalSupply();
    this.currentLocked_ETH = BigNumber.from(locked_ETH_Amount);

    // ETH BALANCE
    // var balanceAmount = await TKN.balanceOf(this.wallet);
    // this.currentBalanceETH = balanceAmount.toString()

    // ETHO BALANCE
    // var thisBalance = await this.signer.getBalance();
    // this.currentBalanceETHO = thisBalance.toString()


    await this.loadTKNBalance();
    await this.loadEther1Balance();


    if (this.currentLocked_ETH.lte(this.currentLocked_ETHO)) {
      this.bridge_status = "OK"
    } else {
      this.bridge_status = "NOT OK!"
    }
  },

  async loadContractInfo() {

    await this.updateBalances();

    if(this.directionIndicatorEthoToEth) {
      try {
        //const BAO = new Contract(address_BAO, BridgeAssistO.abi, this.signer)
        //var lockedAmount = await BAO.entryOf(this.wallet);
        //var locked_ETH_Amount = await providerO.getBalance(address_BA);
        //var thisBalance = await this.signer.getBalance();
        //var thisBalance = "30073900000000000000000";
        
        //const res = await Promise.all([BAO.entryOf(this.wallet), this.signer.getBalance()])

        // this.currentBalance = this.currentBalanceETHO;
        // console.log("this.currentBalance",this.currentBalance);
        // console.log("this.currentBalance2",BigNumber.from(this.currentBalance));
        // console.log("this.minBNStr",this.minBNStr);
        //this.restoreInputAmount(BNStrToNumstr(this.currentBalance))
        ////// console.log("thisBalance",thisBalance,"this.minBNStr",this.minBNStr,"this.minTKNStr",this.minTKNStr,"this.currentLocked_ETHO",this.currentLocked_ETHO,"this.currentLocked_ETH",this.currentLocked_ETH)

        if (this.currentBalanceETHO.lt(this.minBNStr) && this.inbetweenstatus != '') this.warningMessage = 'Your mainnet balance is lower than minimum amount. Usage is blocked'
        
        // await this.loadTKNBalance()
      } catch (error) {
        console.error(error)

        this.warningMessage = 'Could not load info from contract. Usage is blocked. Try refreshing the page, try later or contact support'
      }
    }
    else if(this.directionIndicatorEthToEtho) {
      try {
        //const TKN = new Contract(address_TKN, Token.abi, this.signer)
        //// console.log("This Wallet: " + this.wallet);
        //var burnedAmount = await TKN.balanceOf(this.wallet) // FALSE !!! //await TKN.allowance(this.wallet, address_BA);
        //var balanceAmount = await TKN.balanceOf(this.wallet);
        //const res = await Promise.all([TKN.allowance(this.wallet, address_BA), TKN.balanceOf(this.wallet)])
        ////// console.log(res0);



        //this.currentBurned = burnedAmount.toString();
        ////// console.log(this.currentBurned);
        ////// console.log("balanceAmount",balanceAmount.toString());
        // this.currentBalance = this.currentBalanceETH.toString()
        //this.restoreInputAmount(BNStrToNumstr(this.currentBalance))
        //// console.log(this.currentBalance);
        //// console.log("this.minTKNStr",this.minTKNStr)
        //// console.log("this.currentLocked_ETHO",this.currentLocked_ETHO)
        //// console.log("balanceAmount",balanceAmount.toString())
        if (this.currentLocked_ETH.lt(this.minTKNStr) && this.inbetweenstatus != '') this.warningMessage = 'Your token balance is lower than minimum amount. Usage is blocked'
        else if (this.currentLocked_ETHO.lt(this.currentLocked_ETH)) {
          //// console.log("balanceAmount",balanceAmount.toString(),"this.minTKNStr",this.minTKNStr,"this.currentLocked_ETHO",this.currentLocked_ETHO,"this.currentLocked_ETH",this.currentLocked_ETH)
          this.hintMessage = 'Your token balance is higher than the locked amount.'
        }

        // await this.loadEther1Balance()
      } catch (error) {
        this.warningMessage = 'Could not load info from contract. Usage is blocked. Try refreshing the page, try later or contact support'
      }
    }
    
  },
},
};
</script>
