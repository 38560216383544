<template>
  <b-navbar type="light" class="navbar-light theme-nav fixed-top main_color_header" toggleable="lg" fixed="top" v-b-scrollspy:nav-scroller>
  
      <b-navbar-brand class="navbar-brand" :to="{name:'Main_page'}"><img :src='"../assets/images/storageprotocol/storage_protool_logo.svg"' height="80" alt="logo"></b-navbar-brand>

  </b-navbar>
  </template>
  <script>
  export default {
    name: 'nav-bar',
    methods: {
      openHome() {
        window.open('https://ethoprotocol.com');
      },
      openStaking() {
        window.open('https://staking.ethoprotocol.com');
      },
      openBridge() {
        window.open('https://bridge.ethoprotocol.com');
      },
      openUploads() {
        window.open('https://uploads.ethoprotocol.com');
      },
      openNodes() {
        window.open('https://nodes.ethoprotocol.com');
      },
      openExplorer1() {
        window.open('https://explorer.ethoprotocol.com/');
      },
      openExplorer2() {
        window.open('https://blocks.ethoprotocol.com/home');
      },
    },
  };
  </script>