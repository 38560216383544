<template >
    <v-container fluid>
      <v-row dense align="center" justify="center">
        <v-col>
          <v-card class="vcard2" height="auto" align="center" justify="center">
       
  
            <v-card-actions>
              <v-row dense  align="center" justify="center">
              <div class="d-md-block justify-content-center mt-5">

                <p class="mb-0">
                  <a :href="`${EXPLORER_ETHO_ADDRESS}${address_TKN}`" target="_blank" rel="noopener noreferrer">
                    <img :src="`${MAINNET_ASSET_LOGO}`" style="height: 100px; object-fit: contain;margin-left:9px;" />
                  </a>
                </p>
                <p class="mb-0">
                  <img :src="`${MAINNET_NETWORK_ERC20}`" style="height: 40px; object-fit: contain;" /> 
                </p>
                <p class="mb-2">{{MAINNET_NETWORK_TICKER}} </p>

                <p class="mb-2"><span class="custom-transform-class text-none" style="font-size: 10px;" >{{ MAINNET_NETWORKNAME }}</span> </p>

                <p class="mb-2">
                  <span class="font-weight-black totalAssetFont">
                    {{ EthoWithComma(BNStrToNumstr(currentLocked_ETHO)) }}  
                    <img :src="`${MAINNET_ASSET_LOGO}`" style="height: 14px; object-fit: contain;margin-top:-3px;" />  TOTAL
                    <!-- TOTAL -->
                  </span>
                </p>




   
              </div>
            </v-row>
            </v-card-actions>

            <div class="text-center" style="background-color:#0957f3">
              <span class="white--text">USDT Pool</span>
            </div>
      
          </v-card>
        </v-col>

        <v-col align="center" justify="center" >
          
          <v-card class="mx-16 py-1 mt-3 vcard3">
            
            

          <v-btn v-if="directionIndicatorEthoToEth" @click="changeEthDirection(true)" icon>
            <v-icon style="font-size: 42px;color:rgb(45, 45, 45);">mdi-arrow-right-bold</v-icon>
            <!-- <img :src='"../assets/images/storageprotocol/tether_to_cubits_swap_256.png"' style="height: 100px; object-fit: contain;" /> -->
          </v-btn>

          <v-btn v-if="directionIndicatorEthToEtho" @click="changeEthDirection(true)" icon>
            <v-icon style="font-size: 42px;color:rgb(45, 45, 45);">mdi-arrow-left-bold</v-icon> 
            <!-- <img :src='"../assets/images/storageprotocol/cubits_to_tether_swap_256.png"' style="height: 100px; object-fit: contain;" /> -->
          </v-btn>
          
          </v-card>
          <p class="custom-transform-class text-none" style="font-size: 10px;">Swap Direction</p>

       
          <!-- <v-card class="mx-16 py-2 mt-5" v-b-tooltip.hover title="Bridge is in Balance." v-if="bridge_status === 'OK'"> -->
          <!-- <a class="mx-16 py-2 mt-5" v-b-tooltip.hover :title="bridge_status === 'OK' ? 'Bridge Balance is OK.' : 'Bridge Balance is not OK.'"> -->
         
          <a v-b-tooltip.hover :title="bridge_status === 'OK' ? 'Bridge Balance is OK.' : 'Bridge Balance is not OK.'">
            <p class="custom-transform-class text-none mb-1" style="font-size: 16px;">Monitor</p>
            

            <!-- <img class="mb-1" :src='"../assets/images/storageprotocol/cubits_to_tether_swap_256.png"' style="height: 100px; object-fit: contain;"/> --> 
            <p class="custom-transform-class text-h5 monitorLabel" style="background-color: #0957f3;" v-if="bridge_status === 'OK'">{{ bridge_status }}</p>
            <p class="custom-transform-class text-h6 monitorLabel" style="background-color: #a12626;font-size: 36px !important;" v-if="bridge_status === 'FAILED!' || bridge_status === 'LOW'">{{ bridge_status }}</p>
            <p class="custom-transform-class text-h6 monitorLabel" style="background-color: grey;" v-if="bridge_status === 'loading...'">{{ bridge_status }}</p>

            <!-- <p class="custom-transform-class text-none mb-1" style="font-size: 16px;">
              Current rate:
            </p> -->
 


          </a>

          <v-card class="mx-16 py-0 mt-7 mb-3 vcard5">

            <p class="custom-transform-class text-none mt-4" style="font-size: 16px;background-color:#edf3ff" v-if="directionIndicatorEthoToEth == true">

              {{ etho_price != -1 ? 1 : '' }}  
              <img :src="`${MAINNET_ASSET_LOGO}`" style="height: 20px; margin-top:-3px; object-fit: contain;"/> 
              
              ~=
              {{ etho_price != -1 ? etho_price : 'loading...' }}  
              <img :src="`${MAINNET_NETWORK_LOGO}`" style="height: 22px;margin-top:-2px;margin-right:14px; object-fit: contain;"/> 

            </p>


            <p class="custom-transform-class text-none mt-4" style="font-size: 16px;background-color:#edf3ff" v-if="directionIndicatorEthoToEth != true">

            {{ etho_price != -1 ? 1 : '' }}  
            <img :src="`${MAINNET_NETWORK_LOGO}`" style="height: 22px;margin-top:-2px;margin-right:0px; object-fit: contain;"/> 


            ~=
            {{ etho_price != -1 ? Math.round(1/etho_price * 10000) / 10000 : 'loading...' }}  
            <img :src="`${MAINNET_ASSET_LOGO}`" style="height: 20px; margin-top:-3px;margin-right:0px; object-fit: contain;"/> 

            </p>


          </v-card>



        </v-col> 




        <v-col>
          <v-card class="vcard2" height="auto" align="center" justify="center">
            
            <v-card-actions>
              <v-row dense  align="center" justify="center">
              <div class="d-md-block justify-content-center  mt-5" align="center" justify="center">
     
                <p class="mb-9">
                  <a :href="`${EXPLORER_ETH_ADDRESS}${address_TKN}`" target="_blank" rel="noopener noreferrer">
                    <img :src="`${TOKEN_ASSET_LOGO}`" style="height: 100px; object-fit: contain;margin-left:6px;" />
                  </a>
                </p>
    
                <p class="mb-3 mt-1">{{TOKEN_NETWORK_TICKER}} </p>

                <p class="mb-2"><span class="custom-transform-class text-none" style="font-size: 10px;" >{{ TOKEN_NETWORKNAME }}</span> </p>

                <p class="mb-2">
                  <span class="font-weight-black totalAssetFont">
                    {{ EthoWithComma(BNStrToNumstr(currentLocked_ETH)) }} 
                    <img :src="`${TOKEN_ASSET_LOGO}`" style="height: 14px; object-fit: contain;margin-top:-3px;" />  TOTAL
                    <!-- TOTAL -->
                  </span>
                </p>





              </div>
            </v-row>
            </v-card-actions>

            <div class="text-center" style="background-color:#0957f3">
              <span class="white--text">Circulating Supply</span>
            </div>

          </v-card>
        </v-col>
      </v-row>


      <v-row dense align="center" justify="center" v-if="directionIndicatorEthoToEth">
        <v-col>
          <v-card class="vcard2" height="auto" align="center" justify="center">
            <v-card-actions>
              <div align="center" justify="center">
              <div class="d-md-block balanceInfoBlock ml-6 mt-7">
                <p class="convertText row" >Balance:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(currentBalanceETHO)) }} <img :src="`${MAINNET_ASSET_LOGO}`" class="miniETHO" style="height: 12px;" /> </span></p> 
                
                <p class="convertText row" >Fee:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(feeBNStr)) }} <img :src="`${MAINNET_ASSET_LOGO}`" class="miniETHO" style="height: 12px;" /> </span></p> 
      
                <p class="convertText row">Minimum:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(minBNStr)) }}  <img :src="`${MAINNET_ASSET_LOGO}`" class="miniETHO" style="height: 12px;" /> </span></p> 
              </div>
            </div>
            </v-card-actions>
          </v-card>
        </v-col>

        <div class="dividerCol">
        </div>

        <v-col>
          <v-card class="vcard2" height="auto" align="center" justify="center">
            <v-card-actions>
              <v-row dense  align="center" justify="center">
              <div class="d-md-block balanceInfoBlock mt-7">
                <!-- <p class="text-left convertText">Balance:  <span class="font-weight-black">{{ EthoWithComma(BNStrToNumstr(balanceNumStr_TKN)) }} <img :src='"../assets/images/storageprotocol/tether_256.png"' class="miniETH" style="height: 12px;" /> </span></p>  -->
                <p class="convertText row" style="float:left;">Balance:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(balanceNumStr_TKN)) }} <img :src="`${TOKEN_ASSET_LOGO}`" class="miniETH" style="height: 12px;" /> </span></p> 
              </div>
            </v-row>
            </v-card-actions>
          </v-card>
        </v-col>

      </v-row>

      <v-row dense align="center" justify="center" v-if="directionIndicatorEthToEtho">

        <v-col>
          <v-card height="auto" align="center" justify="center">
            <v-card-actions>
              <v-row dense  align="center" justify="center">
              <div class="d-md-block balanceInfoBlock mt-7">
                <p class="convertText row">Balance:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(balanceNumStr_Ether1) ) }} <img :src="`${MAINNET_ASSET_LOGO}`" class="miniETHO" style="height: 12px;" /> </span></p> 
              </div>
            </v-row>
            </v-card-actions>
          </v-card>
        </v-col>

        <div class="dividerCol">
        </div>

        <v-col>
          <v-card height="auto" align="center" justify="center">
            <v-card-actions>
              <div align="center" justify="center">
              <div class="d-md-block balanceInfoBlock ml-6 mt-7">
                <p class="convertText row">Balance:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(currentBalanceETH)) }} <img :src="`${TOKEN_ASSET_LOGO}`" class="miniETH" style="height: 12px;" /> </span></p> 
               <br>
                <p class="convertText row">Fee:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(feeBNStr)) }} <img :src="`${TOKEN_ASSET_LOGO}`" class="miniETH" style="height: 12px;" /> </span></p> 
                <br>
                <p class="convertText row">Minimum:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(minTKNStr)) }} <img :src="`${TOKEN_ASSET_LOGO}`" class="miniETH" style="height: 12px;" /> </span></p> 
              </div>
            </div>
            </v-card-actions>
          </v-card>
        </v-col>

      </v-row>


      <v-row justify="center" align="center" v-if="contractInfoOk">

 
        <v-col cols="8">

          <v-btn class="py-4" style="z-index: 999999;position: absolute;right: 20px;top: 30px;width: 50px;" v-if="canSwap" @click="restoreInputAmount()" icon>
            <p class="text-none" style="font-size: 12px;margin:2px;">All</p>
          </v-btn>

          <v-text-field
            v-if="directionIndicatorEthoToEth"
            v-model="inputAmount"
            label="Amount"
            outlined
            dense
            required
            
            @click:append="restoreInputAmount"
                :rules="[
                  () => amountValid || 'Enter proper amount',
                  () => amountEnough || amountZero || 'Amount is too low',
                  () => balanceEnough || amountEqual || 'Balance too low',
                ]"
          ></v-text-field>


          <v-text-field
            v-if="directionIndicatorEthToEtho"
            v-model="inputAmount"
            label="Amount"
            outlined
            dense
            required
            
            @click:append="handleClickAppend"
            :rules="[() => amountValid || 'Enter proper amount', () => amountEnough || 'Amount is too low', () => balanceEnough || 'Balance too low']"
          ></v-text-field>


          
          <v-card class="mb-8" height="44" align="center" justify="center" v-if="directionIndicatorEthoToEth && amountEnough">
            <p class="text-center py-2" style="font-size: 14px;">
              You will receive:  <span class="font-weight-black" v-if="converted_amount_ready">{{ amountEnough ? '~' + EthoWithComma(BNStrToNumstr(converted_amount)) : '...' }}   </span><img :src="`${TOKEN_ASSET_LOGO}`" class="mr-1" style="margin-left:1px; margin-top:-3px; height: 18px; object-fit: contain;" /> 
              <!-- Fee:  <span class="font-weight-black red--text">{{ amountEnough ? '~' + BNStrToNumstr(this.amountBN.sub(amountReceivedBNStr)) : '...' }} ETHO </span><img :src='"../assets/images/assets/images/storageprotocol/sUSDT_256.png"' style="margin-left:16px; height: 18px; object-fit: contain;" /> -->
            </p> 
           
          </v-card> 
          <v-card  class="mb-8" height="44" align="center" justify="center" v-if="directionIndicatorEthToEtho && amountEnough">
            <p class="text-center py-2" style="font-size: 14px;">You will receive:  <span class="font-weight-black" v-if="converted_amount_ready">{{ amountEnough ? '~' + EthoWithComma(BNStrToNumstr(converted_amount)) : '...' }}  </span><img :src="`${MAINNET_ASSET_LOGO}`" class="mr-1" style="margin-left:1px; margin-top:-3px; height: 18px; object-fit: contain;" /></p> 
          </v-card>
        </v-col>
      </v-row>

      <v-card width="200">
          <v-btn text :color="aboutToNullify ? 'warning' : 'success'" large :loading="loading_request" :disabled="requestDisabled" @click="handleClickRequest">
            {{ aboutToNullify ? 'Take Back' : 'Request Swap' }}
          </v-btn>
      </v-card>

      <!-- <v-row justify="center" align="center" class="m-4">
        <v-alert border="right" color="#a12626" dark v-if="!!warningMessage"><p class="text-center" style="color:white; font-size: 12px;">{{ warningMessage }}</p></v-alert>
        <v-alert border="right" color="#a12626" dark v-if="!!hintMessage"><p class="text-center" style="color:white; font-size: 12px;">{{ hintMessage }}</p></v-alert>
        <v-alert border="right" color="#9d9d9d" dark v-if="!!inbetweenstatus"><p class="text-center" style="color:white; font-size: 12px;">{{inbetweenstatus}}</p></v-alert>
        <v-alert border="right" color="#9d9d9d" dark v-if="!!successMessage"><p class="text-center" style="color:white; font-size: 12px;">{{ successMessage }}</p></v-alert>

        <v-alert border="right" color="#9d9d9d" dark v-if="loading_controllerInfo"><p class="text-center" style="color:white; font-size: 12px;">Loading Contract Information</p></v-alert>

      </v-row>

      <v-row justify="center" align="center" class="mt-2 mb-4">

        <v-alert border="right" color="#9d9d9d" dark v-if="!!hashes">
          <p class="text-center" style="color:white; font-size: 12px;">Transaction hash:
            <br />
            Swap (ETHO Network):
            <br />
            <a style="color:black;" :href="`${EXPLORER_ETHO_TX}${hashes.txHashCollect}`" target="_blank" rel="noopener noreferrer">
              {{ hashes.txHashCollect }}
            </a>
            <br />
          </p>

        </v-alert>
      </v-row> -->



      <v-row justify="center" align="center" class="mt-3">
        <v-alert border="right" color="#9d9d9d" class="mb-2" dark v-if="!!inbetweenstatus">
          <p class="text-center mb-0" style="color:white; font-size: 12px;">
            {{inbetweenstatus}}

          </p>

        </v-alert>
      </v-row>

      
        <v-row justify="center" align="center" class="mt-4">
          <v-alert border="right" color="#9d9d9d" class="mb-2" dark v-if="!!successMessage"><p class="text-center mb-0" style="color:white; font-size: 12px;">{{ successMessage }}</p></v-alert>
        </v-row>
        <v-row justify="center" align="center" class="mt-4">
          <v-alert border="right" color="#9d9d9d" class="mb-2" dark v-if="loading_controllerInfo"><p class="text-center mb-0" style="color:white; font-size: 12px;">Loading Contract Information</p></v-alert>
        </v-row>

        <v-row justify="center" align="center" class="mt-4">
          <v-alert border="right" color="#9d9d9d" dark v-if="!!hashes">
            <p class="text-center" style="color:white; font-size: 12px;">Transaction hash:
              <br />
              {{ swapMessage }}
              <br />
              <a style="color:black;" :href="`${EXPLORER_ETHO_TX}${hashes.txHashCollect}`" target="_blank" rel="noopener noreferrer">
                {{ hashes.txHashCollect }}
              </a>
              <br />
            </p>

          </v-alert>

      
        </v-row>








      <v-row dense>
        <v-col>
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>

             <!-- <v-btn icon class="px-14" :href="`${DEX_LINK}`" target="_blank">
                <img :src="`${DEX_IMG}`" style="margin-left:16px; height: 18px; object-fit: contain;" />
              </v-btn>

              <v-btn icon class="px-14" :href="`${TKN_LINK}`" target="_blank">
                <img :src="`${TKN_IMG}`" style="margin-left:16px; height: 18px; object-fit: contain;" />
              </v-btn> -->

   
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>
/* eslint-disable camelcase */
//import Vue from 'vue'
import { Contract, providers, BigNumber } from 'ethers'
// import Axios from 'axios'
//import firebase from 'firebase/app'
//import 'firebase/firestore'
// import USDT_STORAGEPROTOCOL_TOKEN from '/abis/Token.json'
import Token from '/abis/Token.json'
// import Oracle from '/abis/oracle_contract.json'
// import Signer from '/abis/MultiSig_Controller.json'

//const address_BAO = '0x22a2a6ac2b0c26023353077e6a5572503ec053a5' // 3DFA1F055CA9F5ACBE6FD6A8B07A89EAC0EDE6244EA7953AC8D1D2B50F652A03 0x22a2a6ac2b0c26023353077e6a5572503ec053a5
// const address_TKN_receiver = '0x63e5950a52EC412cf3C54856963E8f67aa57D605' //'0x63e5950a52EC412cf3C54856963E8f67aa57D605' //'0xF93e51367f18bbA105D93951B57f875317850393'; // USDT CONTRACT
const address_TKN = '0x7777777777777777777777777777777777777777' //'0x63e5950a52EC412cf3C54856963E8f67aa57D605' //'0xF93e51367f18bbA105D93951B57f875317850393'; // USDT CONTRACT
// const address_USDT_storageprotocol = '0x7777777777777777777777777777777777777777'; // 3DFA1F055CA9F5ACBE6FD6A8B07A89EAC0EDE6244EA7953AC8D1D2B50F652A03 0x22a2a6ac2b0c26023353077e6a5572503ec053a5
// const address_ORCL = '0x1509c8F22b7a14bab4EA829324BFcc44371A5667'; // NOT NEEDED
const address_SIGNER = '0x9990000000000000000000000000000123456789';
// const address_SIGNER_authority = "0x6668685991423444373955509583400957353773" ; //"0xcaded6e8b266b9fb5781506885283552387c748b"; //'0xB5e919EAfCF60932060Cd4A9d9b9568736CDFBf0'; // transmitter client for receipts VERY IMPORTANT TO UPDATE


// const address_DEVFUND = '0xBA57dFe21F78F921F53B83fFE1958Bbab50F6b46'; // NOT NEEDED

const TOKEN_CONFIRMATIONS = 1;
const ETHO_CONFIRMATIONS = 1;

// const ETH_LOGO = "../assets/images/storageprotocol/tether_256.png";
// console.log("ETH_LOGO",ETH_LOGO)
const EXPLORER_ETH_ADDRESS = "https://etherscan.io/address/";
const EXPLORER_ETHO_ADDRESS = "https://explorer.storageprotocol.com/address/";
const EXPLORER_ETHO_TX = "https://explorer.storageprotocol.com/tx/";
// const EXPLORER_ETHO_TX = "https://testnetexplorer2.storageprotocol.com/tx/";
//const EXPLORER_ETH_TX = "https://testnet.bscscan.com/tx/";
//const EXPLORER_ETH_TX = "https://ropsten.etherscan.io/tx/";
const EXPLORER_ETH_TX = "https://etherscan.io/tx/";
// const EXPLORER_ETH_TX = "https://sepolia.etherscan.io/tx/";

// const providerO = new providers.JsonRpcProvider('https://rpc.storageprotocol.com')

//const token_rpc_url = "https://ropsten.infura.io/v3/ad333b804b7c4892a5826cfcc09db9ba";

//const mainnet_rpc_url = "https://rpc.storageprotocol.com";
// const token_chainID = 1; //"0x3";

const TOKEN_NETWORKNAME = "Storage Protocol"
const TOKEN_NETWORK_TICKER = "Cubits"
const TOKEN_NETWORK_LOGO = require('@/assets/images/storageprotocol/eth-logo.svg')
const TOKEN_NETWORK_ERC20 = require('@/assets/images/etho/src20.jpg')
const TOKEN_ASSET_LOGO = require('@/assets/images/storageprotocol/cubits_blue_256.png')

const MAINNET_NETWORKNAME = "Storage Protocol"
const MAINNET_NETWORK_TICKER = "USDT"
const MAINNET_ASSET_LOGO = require('@/assets/images/storageprotocol/sUSDT_256.png')
const MAINNET_NETWORK_ERC20 = require('@/assets/images/etho/src20.jpg')
const MAINNET_NETWORK_LOGO = require('@/assets/images/storageprotocol/cubits_black_256.png')

const DEX_LINK = "https://info.uniswap.org/#/tokens/0x0b5326Da634f9270FB84481DD6F94d3dC2cA7096"
const DEX_IMG = require('@/assets/images/etho/uniswap.png')
const TKN_LINK = "https://storageprotocol.com/address/0x0b5326Da634f9270FB84481DD6F94d3dC2cA7096"
const TKN_IMG = require('@/assets/images/etho/etherscan-logo.png')

const swapMessage = "Swap (CUBITS Network):"

const token_chainID = 420500100; //"0x3";
const mainnet_chainID = 420500100; //"0x14095a";

const ETHO_ETH_FEE_DEPOSIT = 0 // in dollars
const ETH_FEE_WITHDRAWAL = 0 // in dollars

const ETHO_ETH_MIN_DEPOSIT = 0.1 // in dollars
const ETH_MIN_WITHDRAWAL = 0.1 // in dollars

//const provider_TOKEN = new providers.InfuraProvider(3, 'ad333b804b7c4892a5826cfcc09db9ba');
// const provider_TOKEN = new providers.InfuraProvider(11155111, '9aa3d95b3bc440fa88ea12eaa4456161');
// const provider_TOKEN = new providers.JsonRpcProvider('https://mainnet.infura.io/v3/a289a5a7fb754ae8aa8fa1915f87eda0');
const provider_TOKEN = new providers.JsonRpcProvider('https://rpc.storageprotocol.com');
// const provider_ETHO = new providers.JsonRpcProvider('https://rpc.storageprotocol.com');
//const provider_TOKEN = new providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
//const provider_TOKEN = new providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/')


// const ORCL = new Contract(address_ORCL, Oracle.abi, provider_ETHO)

// console.log(TKN_ETH_NETWORK)
// console.dir(TKN_ETH_NETWORK)
// console.log(TKN_ETH_NETWORK.populateTransaction)
// console.dir(TKN_ETH_NETWORK.populateTransaction)

// mainnetblock = await provider_TOKEN.getBlock("latest")
// console.log(provider_TOKEN.getBlock("latest"))
// const TRANSMITTER_ACCOUNT = "0xcaded6e8b266b9fb5781506885283552387c748b"
// const TRANSMITTER_ACCOUNT = utils.arrayify(address_SIGNER_authority)

const TKN_ETH_NETWORK = new Contract(address_TKN, Token.abi, provider_TOKEN)
// const TKN_STORAGEPROTOCOL = new Contract(address_USDT_storageprotocol, USDT_STORAGEPROTOCOL_TOKEN.abi, provider_ETHO)
// const SGNR = new Contract(address_SIGNER, Signer.abi, provider_TOKEN) 

// const ETH_PRICE_URL = "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=eth"






// let _tx = "0x6a7d68cdd80d2b645ffce5aafcae892bdcc1389375665f92439cb6209de0b637"

// let encodePacked =  utils.concat([ TRANSMITTER_ACCOUNT, utils.toUtf8Bytes(_tx) ])
// const _hash = utils.keccak256(encodePacked)

// console.log("_hash",_hash)
// let result_hashes = SGNR.GetBridgeReceipt(_hash);

// console.log("result_hashes",result_hashes)
// console.log("_tx",_tx)



//const url_baseIP = "https://ethobridge.uc.r.appspot.com";
//const url_baseIP = "http://localhost:5533";
//const url_baseIP = "http://52.50.170.41:5533";
// const url_baseIP = "https://bridge.storageprotocol.com/api";
//const url_baseIP = "https://52.50.170.41/api";


//const url_baseIP = "http://77.20.0.26:5533";

// const url_ethoTOwetho = url_baseIP+"/ETHOtoWETHO/ETH/";
// const url_wethoTOetho = url_baseIP+"/WETHOtoETHO/ETH/";

//// console.log("TKN",TKN)

function removeTrailingZeros(str) {
  if (str === '0') return str
  if (str.slice(-1) === '0') return removeTrailingZeros(str.substr(0, str.length - 1))
  if (str.slice(-1) === '.') return str.substr(0, str.length - 1)
  return str
}
function numstrToBN(input) {
  const spl = input.split('.')
  if (spl[1]) spl[1] = spl[1].substr(0, 18)
  return BigNumber.from(spl.join('') + '000000000000000000'.substr(0, 18 - (spl[1] || '').length))
}
function InputFix(str) {
  let check_zeros = str.split(".")
  if (parseFloat(check_zeros[1]) > 0) {
    return check_zeros.join(".");
  } else {
    return check_zeros[0];
  }
}
function BNStrToNumstr(str, precision = 2) {
  ////// console.log("str",str)
  str = str.toString()
  if (str === '0') return str
  if (isNaN(Number(str))) return 'NaN'
  if (str.length <= 18) return removeTrailingZeros(('0.' + '000000000000000000'.substr(0, 18 - str.length) + str).substr(0, 18 - str.length + precision + 2))
  else return [str.substr(0, str.length - 18), str.slice(-18)].join('.').substr(0, str.length - 18 + precision + 1)
}
function EthoWithComma(amount) {
    var str = amount.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    // str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, "");
    if (parseFloat(str[1]) > 0) {
      return str.join(".");
    } else {
      return str[0];
    }

}


function Sleep(milliseconds) {
 return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export default {
  name: "EthBridge",
  data() {
    return {
      balanceNumStr_Ether1: '',
      directionIndicatorEthToEtho: false,
      directionIndicatorEthoToEth: true,
      provider: providers.Web3Provider | null,
      signer: providers.JsonRpcSigner | null,
      wallet: '',
      TOKEN_NETWORKNAME:TOKEN_NETWORKNAME,
      MAINNET_NETWORKNAME:MAINNET_NETWORKNAME,
      TOKEN_NETWORK_TICKER:TOKEN_NETWORK_TICKER,
      MAINNET_NETWORK_TICKER:MAINNET_NETWORK_TICKER,
      TOKEN_NETWORK_LOGO:TOKEN_NETWORK_LOGO,
      TOKEN_ASSET_LOGO:TOKEN_ASSET_LOGO,
      TOKEN_NETWORK_ERC20:TOKEN_NETWORK_ERC20,
      MAINNET_ASSET_LOGO:MAINNET_ASSET_LOGO,
      MAINNET_NETWORK_ERC20:MAINNET_NETWORK_ERC20,
      MAINNET_NETWORK_LOGO:MAINNET_NETWORK_LOGO,
      EXPLORER_ETHO_TX:EXPLORER_ETHO_TX,
      EXPLORER_ETHO_ADDRESS:EXPLORER_ETHO_ADDRESS,
      EXPLORER_ETH_ADDRESS:EXPLORER_ETH_ADDRESS,
      EXPLORER_ETH_TX:EXPLORER_ETH_TX,
      DEX_LINK:DEX_LINK,
      DEX_IMG:DEX_IMG,
      TKN_LINK:TKN_LINK,
      TKN_IMG:TKN_IMG,
      // ETH_PRICE_URL:ETH_PRICE_URL,
      // ETH_LOGO:ETH_LOGO,
      address_TKN:address_TKN,
      // address_USDT_storageprotocol:address_USDT_storageprotocol,
      // address_ORCL:address_ORCL,
      address_SIGNER:address_SIGNER,
      bridge_status: 'loading...',
      swapMessage: swapMessage,
      inbetweenstatus: '',
      total_cubits: -1,
      converted_amount: -1,
      currentLocked_ETHO: '',
      currentLocked_ETH: '',
      currentBurned: '',
      currentBalance: '',
      currentBalanceETH: '',
      currentBalanceETHO: '',
      balanceNumStr_TKN: '',
      inputAmount: '',
      etho_price: -1,
      last_etho_price: Date.now(),
      costsInfo: {
        WETHOtoETHO: '',
        ETHOtoWETHO: ''
      } | null,
      paramsInfo: {
        minETH: 0,
        minETHO: 0,
        preShutdownFlag: false
      } | null,
      warningMessage: '',
      hintMessage: '',
      successMessage: '',
      loading_request: false,
      loading_controllerInfo: true,
      loading_contractInfo: true,
      // inBetween: false,
      hashes: { txHashCollect: '', txHashDispense: '' } | null,
    }
  },
  watch: {
    inputAmount: function(val) {
        // console.log("INPTU CHAGNED",val)
        this.recalcAmount(val)
      }
  },
  computed: {
    // eth_logo() {
    //   return require(ETH_LOGO);
    // },
    burnedEnough() {
      if (!this.controllerInfoOk || !this.contractInfoOk) return false
      if(this.directionIndicatorEthoToEth) {
        return this.burnedBN.gte(this.minBNStr)
      } else {
        return this.burnedBN.gte(this.minTKNStr)
      }
    },
    burnedBN() {
      return BigNumber.from(this.currentBurned)
    },
    convertBN(_nr) {
      return BigNumber.from((_nr.toString()))
    },
    amountEqual() {
      if(this.directionIndicatorEthoToEth) {
        return this.amountBN.eq(this.currentLocked_ETHO)
      }
      return this.amountBN.eq(this.currentBurned)
    },
    entryExists() {
      if (!this.contractInfoOk || !this.currentLocked_ETHO) return false
      return this.currentLocked_ETHO.gt(0)
    },
    canMaximizeAmount() {
      return !this.burnedNonZero && ((this.amountBN.lt(this.currentBalanceETHO) && this.directionIndicatorEthoToEth) || (this.amountBN.lt(this.currentBalanceETH) && this.directionIndicatorEthToEtho))
    },
    amountReceivedBNStr_ready() {
      if (!this.controllerInfoOk || !this.contractInfoOk) return 'NaN'
      return this.currentLocked_ETHO.sub(this.feeBNStr).toString()
    },
    converted_amount_ready() {
      if (!this.controllerInfoOk || !this.contractInfoOk) return 'NaN'
      if (this.converted_amount != -1) {
        return true;
      } else {
        return false;
      }
    },
    providerOk() {
      return !!this.provider && !!this.signer && !!this.wallet
    },
    controllerInfoOk() {
      return !!this.costsInfo && !!this.paramsInfo
    },
    contractInfoOk() {
      // console.log("cinfo",!!this.currentLocked_ETHO,!!this.currentBalance)
      // console.log("cinfo",!!this.currentBalanceETHO,!!this.currentBalanceETH)
      if(this.directionIndicatorEthoToEth) {
        // return !!this.currentLocked_ETHO && !!this.currentBalance
        return !!this.currentBalanceETHO
      } else {
        return !!this.currentBalanceETH
      }
      //return !!this.currentBurned && !!this.currentBalance
      // return !!this.currentBalance

    },
    allSafe() {
      return this.providerOk && this.controllerInfoOk && this.contractInfoOk && !this.warningMessage && !this.loading_controllerInfo
    },
    requestDisabled() {
      return !this.allSafe || !this.balanceEnough || (!this.amountEnough && !this.aboutToNullify)
    },
    aboutToNullify() {
      // return this.amountZero//&& this.entryExists //  && this.inputAmount != 0 
      return false
    },
    amountZero() {
      return this.inputAmount === '0'
    },
    amountValid() {
      return !!Number(this.inputAmount) || this.amountZero
    },
    amountEnough() {
      if (!this.controllerInfoOk) return false
      if(this.directionIndicatorEthoToEth) {
        return this.amountBN.gte(this.minBNStr)
      } else {
        return this.amountBN.gte(this.minTKNStr)
      }
    },
    canSwap() {
      if (!this.controllerInfoOk) return false
      if(this.directionIndicatorEthoToEth) {
        return this.currentBalanceETHO.gte(this.minBNStr)
      } else {
        return this.currentBalanceETH.gte(this.minTKNStr)
      }
    },
    balanceEnough() {
      if (!this.controllerInfoOk) return false
      
      if(this.directionIndicatorEthoToEth) {
        return this.amountBN.lte(this.currentBalanceETHO)
      } else {
        return this.amountBN.lte(this.currentBalanceETH)
      }
    },
    lockedEnough() {
      if (!this.controllerInfoOk || !this.contractInfoOk) return false
      if(this.directionIndicatorEthToEtho) {
        return BigNumber.from(this.currentLocked_ETHO).gte(this.minTKNStr)
        //return true
      } else {
        return true // always enough Wrapped etho existing ;)
      }

    },
    amountBN() {
      if (!this.amountValid) return BigNumber.from(0)
      return numstrToBN(this.inputAmount)
    },

    amountReceivedBNStr() {
      if (!this.controllerInfoOk) return 'NaN'

      return this.amountBN.sub(this.feeBNStr).toString()
      // let cubitrate = await TKN_ETH_NETWORK.cubits_to_usdt(this.amountBN)
      // return cubitrate.toString();
    },
    feeBNStr() {
      //// console.log("ETHOOOOOO BRIDGE",this.controllerInfoOk)
      if (!this.controllerInfoOk) return 'NaN'
      if(this.directionIndicatorEthoToEth) {
        //return BigNumber.from(this.costsInfo.ETHOtoWETHO).mul(this.paramsInfo.costToFeeQuotient).div('100').toString()
        return BigNumber.from(this.costsInfo.ETHOtoWETHO).toString()
        
      } else {
        //return BigNumber.from(this.costsInfo.WETHOtoETHO).mul(this.paramsInfo.costToFeeQuotient).div('100').toString()
        return BigNumber.from(this.costsInfo.WETHOtoETHO).toString()
      }
      
    },
    minTKNStr() {
      if (!this.controllerInfoOk) return 'NaN'
      //return BigNumber.from(this.costsInfo.WETHOtoETHO).add(this.paramsInfo.minETH).toString()
      return BigNumber.from(this.paramsInfo.minETHO).toString()
    },
    minBNStr() {
      if (!this.controllerInfoOk) return 'NaN'
      return BigNumber.from(this.paramsInfo.minETH).toString()
      //return BigNumber.from(this.costsInfo.ETHOtoWETHO).add(this.paramsInfo.minETHO).toString()
      //return BigNumber.from(this.costsInfo.ETHOtoWETHO)
      //  .mul(this.paramsInfo.costToFeeQuotient)
      //  .mul(this.paramsInfo.feeToMinQuotient)
      //  .mul('120')
      //  .div('1000000')
      //  .toString()
    },
  },
  async mounted() {
    // console.log("HI")
    await this.loadControllerInfo()
    if (this.controllerInfoOk) await this.connectProvider()
    this.loading_contractInfo = false
    setInterval(async () => {
      if (this.controllerInfoOk) {
        this.loading_controllerInfo = true
        await this.loadControllerInfo()
      }
    }, 30000)
    window.ethereum.on('networkChanged', () => {
      this.updateNetwork();
    })
    window.ethereum.on('accountsChanged', () => {
      this.handleAccountsChanged();
    })

  },
  methods: {
    changeEthDirection(manually_changed) {
      console.log("CHANGEDIRECTION MANUALLY:",manually_changed)
      if(this.directionIndicatorEthToEtho) {
        this.directionIndicatorEthToEtho = false;
        this.directionIndicatorEthoToEth = true;
        this.updateNetwork(manually_changed)
      } else {
        this.directionIndicatorEthToEtho = true;
        this.directionIndicatorEthoToEth = false;
        this.updateNetwork(manually_changed)
      }

      // if we changed manually, also recalc amount ?
      // this.recalcAmount(this.currentBalanceETH)
      // or set amount to 0 instead
      this.inputAmount = 0
    },
    async updateNetwork(manually_changed) {
      this.hashes = null
      this.warningMessage = '';
      this.hintMessage = '';
      this.successMessage = '';
      this.loading_controllerInfo = true
      await this.loadControllerInfo()
      if (this.controllerInfoOk) await this.connectProvider(manually_changed)    
      this.loading_contractInfo = false
    },
    BNStrToNumstr,
    EthoWithComma,
    async SwitchNetwork(targetID) {
        console.log("SWITCHING NETWORK NOW...")
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: targetID }], // chainId must be in hexadecimal numbers
        });
    },

    restoreInputAmount() {
      //this.inputAmount = removeTrailingZeros(BNStrToNumstr(this.currentLocked_ETHO, 18))
      //this.inputAmount = removeTrailingZeros(BNStrToNumstr(this.minBNStr, 8))

      // let cubitrate = await TKN_ETH_NETWORK.cubits_to_usdt(this.amountBN)
      // return cubitrate.toString();
      if(this.directionIndicatorEthoToEth) {
        // let etho_amt = this.currentBalanceETHO // 10000... is gas fee leftover?
        this.inputAmount = InputFix(removeTrailingZeros(BNStrToNumstr(this.currentBalanceETHO,18))) || 0
        // this.recalcAmount(etho_amt)
      } else {
        // this.inputAmount = BNStrToNumstr(this.currentBalanceETH.sub(BigNumber.from("1000000000000000000")),18) || 0
        this.inputAmount = InputFix(removeTrailingZeros(BNStrToNumstr(this.currentBalanceETH.sub(BigNumber.from("100000000000000000")),18))) || 0
        // this.recalcAmount(this.currentBalanceETH)
      }

      
      // recalcAmount
      // console.log("RECALCULATING")
      // this.recalcInputs()

    },
    handleClickAppend() {
      if (this.burnedEnough && !this.amountEqual) this.restoreInputAmount()
      else if (this.canMaximizeAmount) this.maximizeInputAmount()
    },
    async recalcAmount(_value) {
      // console.log("RECALC AMOUNT!",_value)
      // console.log("RECALC AMOUNT!",numstrToBN(_value))
      // let result = await TKN_ETH_NETWORK.cubits_to_usdt(numstrToBN(_value))
      if(this.directionIndicatorEthoToEth) {
        try { 
          let result = await TKN_ETH_NETWORK.usdt_to_cubits(numstrToBN(_value))
          console.log("result",result)
          if (result) { //THIS CHECK NEEDS TO BE IMPROVED !
            this.converted_amount = result.sub(this.feeBNStr)
          } else {
            this.converted_amount = -1
          }
        } catch (error) {
          this.converted_amount = -1
        }


      } else {


        try { 
          let result = await TKN_ETH_NETWORK.cubits_to_usdt(numstrToBN(_value)) 
          console.log("result",result)
          if (result) { //THIS CHECK NEEDS TO BE IMPROVED !
            this.converted_amount = result.sub(this.feeBNStr)
          } else {
            this.converted_amount = -1
          }
        } catch (error) {
          this.converted_amount = -1
        }
      }

      // this.converted_amount = InputFix(removeTrailingZeros(BNStrToNumstr(result,18)))
    },
    async loadEther1Balance() {
      // console.log("LOADING")
      if(this.directionIndicatorEthoToEth) {
        try {

          // ETHO BALANCE
          // let thisBalance = await provider_ETHO.getBalance(this.wallet);
          let thisBalance = await TKN_ETH_NETWORK.balanceOf(this.wallet); 
          this.currentBalanceETHO = BigNumber.from(thisBalance);

          this.balanceNumStr_Ether1 = this.currentBalanceETHO;

        } catch (error) {
          console.error(error)
        }
      } else if(this.directionIndicatorEthToEtho) {
        try {

          // ETH BALANCE
          let thisBalance = await TKN_ETH_NETWORK.balanceOf(this.wallet); 
          this.currentBalanceETHO = BigNumber.from(thisBalance); //thisBalance.toString();  

          this.balanceNumStr_Ether1 = this.currentBalanceETHO;

        } catch (error) {
          console.error(error)
        }
      }
    },
    async loadTKNBalance() {
      try {

        let balanceAmount = await provider_TOKEN.getBalance(this.wallet); //await TKN_ETH_NETWORK.balanceOf(this.wallet); // await provider_ETHO.getBalance(this.wallet)
        this.balanceNumStr_TKN = BigNumber.from(balanceAmount);
        
        // ETH BALANCE
        this.currentBalanceETH = BigNumber.from(balanceAmount);
      } catch (error) {
        console.error(error)
      }
    },
    async getEthoPrice() {
      try { 
        // let xmlHttp = new XMLHttpRequest();
        // xmlHttp.open( "GET", "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=usd", false );
        // xmlHttp.send( null );
        // let jsonData = JSON.parse(xmlHttp.responseText)
        // console.log("etho_price","--",jsonData,jsonData["ether-1"]["usd"])

        // if (jsonData["ether-1"] !== undefined) {
        //   return parseFloat(jsonData["ether-1"]["usd"])
        // } else {
        //   return -1
        // }
        // console.log("get price")
        // let cubits_per_usdt = await TKN_ETH_NETWORK.cubits_buy_value(BigNumber.from("1000000000000000000"));

        var locked_ETHO_Amount = await TKN_ETH_NETWORK.cubitsUSDTpool(); 
        let currentLocked_ETHO = BigNumber.from(locked_ETHO_Amount)

        var locked_ETH_Amount = await TKN_ETH_NETWORK.real_supply_cubits();
        let currentLocked_ETH = BigNumber.from(locked_ETH_Amount)

        // let cubits_per_usdt = currentLocked_ETHO.mul('10000000000000000000000').div(currentLocked_ETH);
        let cubits_per_usdt = currentLocked_ETH.mul('1000000000000000000').div(currentLocked_ETHO);
        // let cubits_per_usdt = await TKN_ETH_NETWORK.cubits_sell_value(BigNumber.from("100000000000000000000"));
        console.log("cubits_per_usdt",cubits_per_usdt)
        let display_nr = EthoWithComma(BNStrToNumstr(cubits_per_usdt))
        // return parseInt(cubits_per_usdt)
        console.log("cubits_per_usdt2",display_nr)
        return Math.round(display_nr * 1000) / 1000
      } catch (error) {
        // return -1
        return 0
      }

    },
    async getTotalCubits() {
      try { 
        
        let cubits_per_usdt = await TKN_ETH_NETWORK.totalcubits();
        if (cubits_per_usdt) {
          return BigNumber.from(cubits_per_usdt) ; //parseFloat(cubits_per_usdt)
        }
      } catch (error) {
        return -1
      }

    },
    async connectProvider(manually_changed) {

      try {
        var insideScope = this.$parent.$parent.showCubitsBridge
        if (insideScope) {
          if (!window.ethereum) throw new Error('Please set up MetaMask properly')
          await (window.ethereum).enable()

          this.provider = new providers.Web3Provider((window.ethereum) || window.web3)

          var found_chainID = (await this.provider.getNetwork()).chainId

          

          // console.log("manually_changed",manually_changed,"A token_chainID",token_chainID,"found_chainID",found_chainID,"controllerInfoOk",this.controllerInfoOk,"direction",this.directionIndicatorEthoToEth,"showEthBridge",insideScope)

          if(this.directionIndicatorEthoToEth) {

            if (found_chainID !== mainnet_chainID) {
              if (found_chainID === token_chainID && manually_changed !== true) { // swap networks..
                
                this.changeEthDirection()
              } else {
                // if (manually_changed === true) {
                  this.SwitchNetwork("0x"+mainnet_chainID.toString(16))
                // }

                throw new Error('You selected wrong network in MetaMask. Make sure you imported and selected Etho Protocol network and refresh the page')
              }
            }
          } else if (this.directionIndicatorEthToEtho) {

            if (found_chainID !== token_chainID) {
              if (found_chainID === mainnet_chainID && manually_changed !== true) { // swap networks..
                
                this.changeEthDirection()
              } else {
                // if (manually_changed === true) {
                  this.SwitchNetwork("0x"+token_chainID.toString(16))
                // }
                throw new Error('You selected wrong network in MetaMask. Make sure you selected Binance Smart Chain network and refresh the page')
              }
            }
          }

          this.signer = this.provider.getSigner()
          this.wallet = await this.signer.getAddress()
          //// console.log("this.wallet",this.wallet)
          await this.loadContractInfo()
        } else {
          console.log("SCOPE ERROR")
        }


        //if (this.entryExists) this.restoreInputAmount()
      } catch (error) {
        this.warningMessage = 'Could not connect MetaMask. Error: ' + error.message
        console.error(error)
      }
    },
    async handleClickRequest() {
      this.hashes = null
      this.warningMessage = '';
      this.hintMessage = '';
      this.successMessage = '';
      this.loading_request = true
      // this.signer = this.provider.getSigner()

      if(this.directionIndicatorEthoToEth) {
        try {
          if (this.aboutToNullify) {
            await this.deposit()
          } else {
            //if ((this.entryExists && !this.amountEqual) || !this.entryExists) 
            var local_tx = await this.deposit() 
            //// console.log("local_tx",local_tx)
            await this.requestOE(local_tx)
          }
          this.inputAmount = ''
        } catch (error) {
          this.hintMessage = error.message
          console.error(error)
        }
      } else if(this.directionIndicatorEthToEtho) {
        try {
          if ((this.burnedNonZero && !this.amountEqual) || !this.burnedNonZero) 
            var local_tx2 = await this.burn()
            //// console.log("local_tx2",local_tx2)
            await this.requestOE(local_tx2)
            this.inputAmount = ''
        } catch (error) {
          this.hintMessage = error.message
          console.error(error)
        }
      }
       

      // this.inBetween = false
      this.inbetweenstatus = ''
      this.loading_request = false
    },
    async burn() {
      var execute_chainID = (await this.provider.getNetwork()).chainId
      if (execute_chainID !== token_chainID) { // make sure user doesn't send into false network for sum bug reason
        throw new Error('You selected wrong network in MetaMask. Make sure you selected Ethereum Mainnet Chain network and refresh the page')
      }

      //// console.log("BURN...",this.amountBN,this.amountBN.toString())


      //// console.log("TKN",TKN)

      //const ptx = await TKN.populateTransaction.burn(address_BA, this.amountBN)
      // const ptx = await TKN.populateTransaction.burn(this.amountBN.toString()) // make user burn directly
 
      const LOCAL_TKN_ETH_NETWORK = new Contract(address_TKN, Token.abi, this.signer)

      const ptx = await LOCAL_TKN_ETH_NETWORK.populateTransaction.sellCubits(this.amountBN) 
      // const ptx = await LOCAL_TKN_ETH_NETWORK.populateTransaction.buyCubits( address_TKN_receiver, this.amountBN.toString()) // make user burn directly
      // console.log({
      //   ptx,
      //   amt: this.amountBN.toString(),
      //   bal: this.currentBalanceETH.toString(),
      // })






      
      const tx = await this.signer.sendTransaction(ptx)
      //// console.log({ tx })
      // this.inBetween = true

      this.triggerinbetweentracking(tx)

      const receipt = await tx.wait()

      // this.triggerinbetweentracking2("token",receipt)
      // await Sleep(500)

      await this.loadContractInfo() // pull this earlier since we changed contract
      
      //// console.log(receipt,receipt.transactionHash)
      return receipt.transactionHash
    },
    async triggerinbetweentracking(_tx) {
      console.log("-->tx:",_tx)
      let mainnetblock = await provider_TOKEN.getBlock("latest")
      let tokenblock = await provider_TOKEN.getBlock("latest")
      console.log("token:",mainnetblock)
      console.log("etho:",tokenblock)
      console.log("tokennumber:",mainnetblock.number)
      console.log("ethonumber:",tokenblock.number)

      this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete."
    },
    async triggerinbetweentracking2(_net,_receipt) {
      console.log("--->receipt:",_receipt)
      console.log("token:",provider_TOKEN.blockNumber)
      console.log("etho:",provider_TOKEN.blockNumber)
      let startblock = -1
      // let startblock2 = -1
      if (_net == "etho") {
        let ethoblock = await provider_TOKEN.getBlock("latest")
        startblock = ethoblock.number
      } else {
        let tokenblock = await provider_TOKEN.getBlock("latest")
        startblock = tokenblock.number
      }
      
      if (startblock == -1) {
        this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete."
      } else {
        var waitblocks = 20
        if (_net == "etho") {
          waitblocks = ETHO_CONFIRMATIONS
        } else {
          waitblocks = TOKEN_CONFIRMATIONS
        }
        let currentblock = 0
        while (currentblock < waitblocks) {
          var display_offset = 0
          if (_net == "etho") {
            let ethoblock = await provider_TOKEN.getBlock("latest")
            currentblock = ethoblock.number- startblock - display_offset
            if (currentblock < 0) {
              currentblock = 0
            }
            this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete. Confirmation Block: "+currentblock+" / "+(ETHO_CONFIRMATIONS-display_offset)
          } else {
            let tokenblock = await provider_TOKEN.getBlock("latest")
            currentblock = tokenblock.number - startblock - display_offset
            if (currentblock < 0) {
              currentblock = 0
            }
            this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete. Confirmation Block: "+currentblock+" / "+(TOKEN_CONFIRMATIONS-display_offset)
          }
        }

        this.inbetweenstatus = "Confirmation Blocks Reached. Requesting Confirmation."

        // if (_net == "etho") {
        //   let tokenblock = await provider_TOKEN.getBlock("latest")
        //   startblock2 = tokenblock.number
        // } else {
        //   let ethoblock = await provider_TOKEN.getBlock("latest")
        //   startblock2 = ethoblock.number
        // }

        // let waitblocks2 = 3
        // let currentblock2 = 0
        // while (currentblock2 < waitblocks2) { // CHECK OPPOSITE NETWORK, since we act crosschain now...
        //   if (_net == "etho") {
        //     let tokenblock = await provider_TOKEN.getBlock("latest")
        //     currentblock2 = tokenblock.number - startblock2
        //   } else {
        //     let ethoblock = await provider_TOKEN.getBlock("latest")
        //     currentblock2 = ethoblock.number- startblock2
        //   }
        //   if (!this.successMessage) {
        //     this.inbetweenstatus = "Crosschain Confirmation: "+currentblock2+" / "+waitblocks2
        //   }
          
        // }
        // if (!this.successMessage) {
        //   this.inbetweenstatus = "Requesting Receipt from Controller...."
        // } 

      }

    },
    async deposit() {
      var execute_chainID = (await this.provider.getNetwork()).chainId
      if (execute_chainID !== mainnet_chainID) { // make sure user doesn't send into false network for sum bug reason
          throw new Error('You selected wrong network in MetaMask. Make sure you imported and selected Etho Protocol network and refresh the page')
      }

      // const LOCAL_TKN_STORAGEPROTOCOL = new Contract(address_USDT_storageprotocol, USDT_STORAGEPROTOCOL_TOKEN.abi, this.signer)
      const LOCAL_TKN_STORAGEPROTOCOL = new Contract(address_TKN, Token.abi, this.signer)
      const ptx = await LOCAL_TKN_STORAGEPROTOCOL.populateTransaction.buyCubits(this.amountBN) 

      //const ptx = {
      //  from: this.wallet,
      //  to: address_BA
      //}

      // ptx.value = this.amountBN
      // console.log({
      //   ptx,
      //   amt: this.amountBN.toString(),
      //   bal: this.currentBalanceETHO.toString(),
      // })

      // const tx = await this.signer.sendTransaction(ptx)
      // this.triggerinbetweentracking(tx)
      // const receipt = await tx.wait()
      // this.triggerinbetweentracking2("etho",tx)
      // await this.loadContractInfo()
      // return receipt.transactionHash


      const tx = await this.signer.sendTransaction(ptx)
      //// console.log({ tx })
      // this.inBetween = true

      this.triggerinbetweentracking(tx)

      const receipt = await tx.wait()

      // this.triggerinbetweentracking2("token",receipt)
      // await Sleep(500)
      await this.loadContractInfo() // pull this earlier since we changed contract
      
      //// console.log(receipt,receipt.transactionHash)
      return receipt.transactionHash

    },
    async requestOE(_tx) {

      await this.updateBalances()
      this.successMessage = 'Success! Save transaction hash below'

      await Sleep(500)

      // this.inbetweenstatus = 'Tx Successfull: '+_tx

      this.hashes = {
        "txHashCollect": _tx,
        // "txHashDispense":  _tx
      }

    },
    async handleAccountsChanged() {
      // console.log("A HANDLE ACCOUNT CHANGED")

      this.balanceNumStr_Ether1 = ""
      this.balanceNumStr_TKN = ""
      this.currentBalanceETH = ""
      this.feeBNStr = ""
      this.minTKNStr = ""
      this.currentBalanceETHO = ""

      this.warningMessage = '';
      this.hintMessage = '';
      this.successMessage = '';

      this.signer = this.provider.getSigner()
      this.wallet = await this.signer.getAddress()
      //// console.log("this.wallet",this.wallet)
      await this.loadContractInfo()
    },
    async loadControllerInfo() {
      this.loading_controllerInfo = false


      if (this.etho_price == -1 || ((this.last_etho_price-Date.now())/1000) > 15) { // more than 30 seconds passed
        this.etho_price = await this.getEthoPrice()
        // this.total_cubits = await this.getTotalCubits()
      }

      if (this.etho_price !== -1) {
        this.last_etho_price = Date.now()
        try {
          let  ETH_TO_ETHO_current_fee = BigNumber.from(parseInt(ETH_FEE_WITHDRAWAL *100) +"0000000000000000"); 
          let  ETHO_TO_ETH_current_fee = BigNumber.from(parseInt(ETHO_ETH_FEE_DEPOSIT*100) +"0000000000000000"); 
          // console.log("ETHO_TO_ETH_current_fee",ETHO_TO_ETH_current_fee)
          let  ETHO_TO_ETH_current_minimum = ETHO_TO_ETH_current_fee.add( BigNumber.from(parseInt(ETHO_ETH_MIN_DEPOSIT *100) +"0000000000000000"));
          let  ETH_TO_ETHO_current_minimum = ETH_TO_ETHO_current_fee.add( BigNumber.from(parseInt(ETH_MIN_WITHDRAWAL*100) +"0000000000000000"));
          // let  ETHO_TO_ETH_current_minimum = ETHO_TO_ETH_current_fee;
          // let  ETH_TO_ETHO_current_minimum = ETH_TO_ETHO_current_fee;

          this.paramsInfo = {
            "minETH":ETHO_TO_ETH_current_minimum,
            "minETHO":ETH_TO_ETHO_current_minimum,
          }
          this.costsInfo = {
            "WETHOtoETHO": ETH_TO_ETHO_current_fee,
            "ETHOtoWETHO": ETHO_TO_ETH_current_fee
          }


        } catch (error) {
          this.warningMessage = 'Could not load info from controller. Usage is blocked. Try refreshing the page, try later or contact support'
          console.error(error)
        }


      }

     
      
    },

    async updateBalances() {
      // let BURNED_AMOUNT_ETH = "9180703289000000000000000"

      // var locked_ETHO_Amount = await provider_ETHO.getBalance(address_BA);
      // console.log("START")
      var locked_ETHO_Amount = await TKN_ETH_NETWORK.cubitsUSDTpool(); // await TKN_ETH_NETWORK.totalUSDT();
      this.currentLocked_ETHO = BigNumber.from(locked_ETHO_Amount) //.sub(BURNED_AMOUNT_ETH);

      var locked_ETH_Amount = await TKN_ETH_NETWORK.real_supply_cubits(); // await TKN_ETH_NETWORK.totalcubits();
      // var locked_ETH_Amount = await this.getTotalCubits();
      this.currentLocked_ETH = BigNumber.from(locked_ETH_Amount) //.sub(BURNED_AMOUNT_ETH);
      console.log(locked_ETHO_Amount,locked_ETH_Amount)
      // ETH BALANCE
      // var balanceAmount = await TKN.balanceOf(this.wallet);
      // this.currentBalanceETH = balanceAmount.toString()

      // ETHO BALANCE
      // var thisBalance = await this.signer.getBalance();
      // this.currentBalanceETHO = thisBalance.toString()

      await this.loadTKNBalance();
      await this.loadEther1Balance();

      // let combinedETHO = BigNumber.from(this.currentLocked_ETH).sub(BURNED_AMOUNT_ETH)
      // console.log("currentLocked_ETH22",BigNumber.from(this.currentLocked_ETH),BigNumber.from(this.currentLocked_ETHO))
      // console.log("<<",this.currentLocked_ETH.div(this.currentLocked_ETHO))
      let exchange_ratio = this.currentLocked_ETHO.mul('10000').div(this.currentLocked_ETH)
      // if (exchange_ratio.lte(BigNumber.from("75"))) {  // .mul(this.paramsInfo.costToFeeQuotient).div('100').toString()
      //   this.bridge_status = "LOW!"
      // } else if (exchange_ratio.lte(BigNumber.from("75"))) {  // .mul(this.paramsInfo.costToFeeQuotient).div('100').toString()
      //   this.bridge_status = "OK"
      // } else {
      //   this.bridge_status = "FAILED!"
      // }
      console.log("exchange_ratio",exchange_ratio)
      if (exchange_ratio.lte(BigNumber.from("100"))) {  // .mul(this.paramsInfo.costToFeeQuotient).div('100').toString()
        this.bridge_status = "LOW"
      } else {
        this.bridge_status = "OK"
      }

      this.etho_price = await this.getEthoPrice() // update price changes also...

    },

    async loadContractInfo() {

      await this.updateBalances();
      if(this.directionIndicatorEthoToEth) {
        try {
          //const BAO = new Contract(address_BAO, USDT_STORAGEPROTOCOL_TOKEN.abi, this.signer)
          //var lockedAmount = await BAO.entryOf(this.wallet);
          //var locked_ETH_Amount = await providerO.getBalance(address_BA);
          //var thisBalance = await this.signer.getBalance();
          //var thisBalance = "30073900000000000000000";
          
          //const res = await Promise.all([BAO.entryOf(this.wallet), this.signer.getBalance()])

          // this.currentBalance = this.currentBalanceETHO;
          // console.log("this.currentBalance",this.currentBalance);
          // console.log("this.currentBalance2",BigNumber.from(this.currentBalance));
          // console.log("this.minBNStr",this.minBNStr);
          //this.restoreInputAmount(BNStrToNumstr(this.currentBalance))
          ////// console.log("thisBalance",thisBalance,"this.minBNStr",this.minBNStr,"this.minTKNStr",this.minTKNStr,"this.currentLocked_ETHO",this.currentLocked_ETHO,"this.currentLocked_ETH",this.currentLocked_ETH)

          if (this.currentBalanceETHO.lt(this.minBNStr) && this.inbetweenstatus != '') this.warningMessage = 'Your '+`${MAINNET_NETWORK_TICKER}`+' balance is lower than minimum amount. Usage is blocked'
          
          // await this.loadTKNBalance()
        } catch (error) {
          console.error(error)

          this.warningMessage = 'Could not load info from contract. Usage is blocked. Try refreshing the page, try later or contact support'
        }
      }
      else if(this.directionIndicatorEthToEtho) {
        try {
          //const TKN = new Contract(address_TKN, Token.abi, this.signer)
          //// console.log("This Wallet: " + this.wallet);
          //var burnedAmount = await TKN.balanceOf(this.wallet) // FALSE !!! //await TKN.allowance(this.wallet, address_BA);
          //var balanceAmount = await TKN.balanceOf(this.wallet);
          //const res = await Promise.all([TKN.allowance(this.wallet, address_BA), TKN.balanceOf(this.wallet)])
          ////// console.log(res0);



          //this.currentBurned = burnedAmount.toString();
          ////// console.log(this.currentBurned);
          ////// console.log("balanceAmount",balanceAmount.toString());
          // this.currentBalance = this.currentBalanceETH.toString()
          //this.restoreInputAmount(BNStrToNumstr(this.currentBalance))
          //// console.log(this.currentBalance);
          //// console.log("this.minTKNStr",this.minTKNStr)
          //// console.log("this.currentLocked_ETHO",this.currentLocked_ETHO)
          //// console.log("balanceAmount",balanceAmount.toString())
          if (this.currentLocked_ETH.lt(this.minTKNStr) && this.inbetweenstatus != '') this.warningMessage = 'Your '+`${TOKEN_NETWORK_TICKER}`+' balance is lower than minimum amount. Usage is blocked'
          else if (this.currentLocked_ETH.lt(this.currentLocked_ETHO)) {
            //// console.log("balanceAmount",balanceAmount.toString(),"this.minTKNStr",this.minTKNStr,"this.currentLocked_ETHO",this.currentLocked_ETHO,"this.currentLocked_ETH",this.currentLocked_ETH)
            this.hintMessage = 'Unlocked '+`${TOKEN_NETWORK_TICKER}`+' balance is higher than the locked amount.' 
          }

          // await this.loadEther1Balance()
        } catch (error) {
          this.warningMessage = 'Could not load info from contract. Usage is blocked. Try refreshing the page, try later or contact support'
        }
      }
      
    },
  },
};
</script>
