<template >
  <div class="container mt-16 mb-n8">
        <div class="row">
          <div class="col-sm-12">
<!-- 
            <img :src='"../assets/images/storageprotocol/cubits_to_tether_swap_256.png"' style="margin-left:7px;height: 100px; object-fit: contain;" />

            <h2 class="title my-16"><span>The Storageprotocol</span> Blockchain Bridge</h2>
            style="background: linear-gradient(to left, #277c5b 0, #3759a9 100%)"
 -->

              <v-card
                max-width="100%"
                background-color="#cccccc"
                class="my-10 px-3 py-2 border border-grey"
                
              >  
              
  
         
                <v-tabs
                  background-color="#0957f3"
                  center-active
                  dark
                  class="pb-2"
                  :vertical="$vuetify.breakpoint.name === 'xs'"
                  active-class="active"
                >

                  <!-- <v-tab class="border-right border-dark" @click="showEthBridge=true, showBscBridge=false, showHistory=false">ETH<img :src='"../assets/images/etho/eth-logo.svg"' style="margin-left:7px;height: 40px; object-fit: contain;" /></v-tab> 
                  <v-tab class="border-right border-warning" @click="showEthBridge=false, showBscBridge=true, showHistory=false">BSC<img :src='"../assets/images/etho/bsclogo.png"' style="margin-left:7px;height: 40px; object-fit: contain;" /></v-tab>
                  <v-tab class="border-right border-grey" @click="showEthBridge=false, showBscBridge=false, showHistory=true">TRANSACTIONS<img :src='"../assets/images/etho/history.png"' style="margin-bottom:1px;margin-left:5px; height: 47px; object-fit: contain;" /></v-tab> -->

                  <v-tab class="border-right" @click="showEthBridge=true, showBscBridge=false, showHistory=false, showCubitsBridge=false">ERC-20<img :src='"../assets/images/etho/eth-logo.svg"' style="margin-left:7px;height: 40px; object-fit: contain;" /></v-tab> 
                  <!-- <v-tab class="border-right" @click="showEthBridge=false, showBscBridge=true, showHistory=false, showCubitsBridge=false">BEP-20<img :src='"../assets/images/etho/bsclogo.png"' style="margin-left:7px;height: 40px; object-fit: contain;" /></v-tab> -->
                  <v-tab class="border-right" @click="showEthBridge=false, showBscBridge=false, showHistory=false, showCubitsBridge=true">Cubits<img :src='"../assets/images/storageprotocol/cubits_grey_256.png"' style="margin-bottom:1px;margin-left:5px; height: 43px; object-fit: contain;" /></v-tab>
                  <v-tab class="border-right" @click="showEthBridge=false, showBscBridge=false, showHistory=true, showCubitsBridge=false">TRANSACTIONS<img :src='"../assets/images/storageprotocol/history.png"' style="margin-bottom:1px;margin-left:5px; height: 47px; object-fit: contain;" /></v-tab>
         

                </v-tabs>

                <!-- <v-btn icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn> -->

   
                <EthBridge v-if="showEthBridge"></EthBridge>
                <BscBridge v-if="showBscBridge"></BscBridge>
                <CubitsBridge v-if="showCubitsBridge"></CubitsBridge>
                <History v-if="showHistory"></History>



              </v-card>
         </div>
      </div>
  </div>
</template>
<script>
/* eslint-disable camelcase */
//import Vue from 'vue'
// <v-tab @click="showEthBridge=false, showBscBridge=true">BSC</v-tab>
// <v-tab @click="showEthBridge=true, showBscBridge=false">ETH</v-tab>
import EthBridge from "./ethbridge";	
import BscBridge from "./bscbridge";
import CubitsBridge from "./cubitsbridge";
import History from "./history";
// import Receipts from "./bscbridge";

//import firebase from 'firebase/app'
//import 'firebase/firestore'


export default {
  name: "Bridge",
	components: {	
    EthBridge,	
    BscBridge,	
    CubitsBridge,	
    History,	
  },
  data() {
    return {
      showEthBridge: true, // true
      showBscBridge: false, // false
      showCubitsBridge: false, // false
      showHistory: false, // false
    }
  },

};
</script>
