<template >

 

    <v-container fluid>


     



      <v-row dense align="center" justify="center">
        <v-col>
          <v-card class="vcard2" height="auto" align="center" justify="center">
            <v-card-actions>
              <v-row dense  align="center" justify="center">
              <div class="d-md-block justify-content-center mt-5">
                <a :href="`${EXPLORER_ETHO_ADDRESS}${address_USDT_storageprotocol}`" target="_blank" rel="noopener noreferrer">
                  <img :src="`${MAINNET_NETWORK_LOGO}`" style="height: 100px; object-fit: contain;"/>
                  <img :src="`${MAINNET_ASSET_LOGO}`" style="height: 100px; object-fit: contain;margin-left:-30px;" />
                  
                </a>
                <p class="mb-0">
                  <img :src="`${MAINNET_NETWORK_ERC20}`" style="height: 40px; object-fit: contain;" /> 
                </p>
      
                  <p class="mb-2">{{MAINNET_NETWORK_TICKER}} </p>
                  <!-- <p class="mb-2"><a class="custom-transform-class text-none" style="font-size: 10px;" >{{ address_USDT_storageprotocol }}</a> </p> -->


                  <p class="mb-2"><span class="custom-transform-class text-none" style="font-size: 10px;" >{{ MAINNET_NETWORKNAME }}</span> </p>

                  <p class="mb-2">
                    <span class="font-weight-black totalAssetFont">
                      {{ EthoWithComma(BNStrToNumstr(currentLocked_ETHO)) }} $   
                      <img :src="`${MAINNET_ASSET_LOGO}`" style="height: 14px; object-fit: contain;margin-top:-3px;" />  TOTAL
                      <!-- TOTAL -->
                    </span>
                  </p>

        
  
           
   
              </div>
            </v-row>
            </v-card-actions>

            <div class="text-center" style="background-color:#0957f3">
              <span class="white--text">Minted USDT</span>
            </div>

          </v-card>
        </v-col>

        <v-col align="center" justify="center" >
          
          <v-card class="mx-16 py-1 mt-3 vcard3">
            
            

          <v-btn v-if="directionIndicatorEthoToEth" @click="changeEthDirection(true)" icon>
            <v-icon style="font-size: 42px;color:rgb(45, 45, 45);">mdi-arrow-right-bold</v-icon>
            <!-- <img :src='"../assets/images/storageprotocol/tether_to_cubits_swap_256.png"' style="height: 100px; object-fit: contain;" /> -->
          </v-btn>

          <v-btn v-if="directionIndicatorEthToEtho" @click="changeEthDirection(true)" icon>
            <v-icon style="font-size: 42px;color:rgb(45, 45, 45);">mdi-arrow-left-bold</v-icon> 
            <!-- <img :src='"../assets/images/storageprotocol/cubits_to_tether_swap_256.png"' style="height: 100px; object-fit: contain;" /> -->
          </v-btn>
          
          </v-card>
          <p class="custom-transform-class text-none" style="font-size: 10px;">Swap Direction</p>

       
          <!-- <v-card class="mx-16 py-2 mt-5" v-b-tooltip.hover title="Bridge is in Balance." v-if="bridge_status === 'OK'"> -->
          <!-- <a class="mx-16 py-2 mt-5" v-b-tooltip.hover :title="bridge_status === 'OK' ? 'Bridge Balance is OK.' : 'Bridge Balance is not OK.'"> -->
         
          <a v-b-tooltip.hover :title="bridge_status === 'OK' ? 'Bridge Balance is OK.' : 'Bridge Balance is not OK.'">
            <p class="custom-transform-class text-none mb-1" style="font-size: 16px;">Monitor</p>
            

            <!-- <img class="mb-1" :src='"../assets/images/storageprotocol/cubits_to_tether_swap_256.png"' style="height: 100px; object-fit: contain;"/> --> 
            <p class="custom-transform-class text-h5 monitorLabel" style="background-color: #0957f3;" v-if="bridge_status === 'OK'">{{ bridge_status }}</p>
            <p class="custom-transform-class text-h6 monitorLabel" style="background-color: #a12626;font-size: 36px !important;" v-if="bridge_status === 'FAILED!'">{{ bridge_status }}</p>
            <p class="custom-transform-class text-h6 monitorLabel" style="background-color: grey;" v-if="bridge_status === 'loading...'">{{ bridge_status }}</p>

            <!-- <p class="custom-transform-class text-none mb-1" style="font-size: 16px;">
              Current rate:
            </p> -->
 


          </a>

          <v-card class="mx-16 py-0 mt-7 mb-3 vcard5">

              <p class="custom-transform-class text-none mt-4" style="font-size: 16px;background-color:#edf3ff">


               {{ etho_price != -1 ? 1 : '' }}  
              <img :src="`${MAINNET_ASSET_LOGO}`" style="height: 20px; margin-top:-3px;margin-right:0px; object-fit: contain;"/> 
              
              ~=
              {{ etho_price != -1 ? etho_price: 'loading...' }}  
              <img :src="`${MAINNET_NETWORK_LOGO}`" style="height: 20px;margin-top:-3px;margin-right:0px; object-fit: contain;"/> 

              </p>



              <!-- <p class="custom-transform-class text-none mt-2" style="font-size: 16px;background-color:#f0f0f0"> -->

              <!-- <span class="font-weight-black totalAssetFont">
                {{ EthoWithComma(BNStrToNumstr(total_cubits)) }}  <img :src="`${MAINNET_NETWORK_LOGO}`" style="height: 14px; object-fit: contain;margin-top:-2px;" />  Cubits
              </span> -->
              <!-- </p> -->

          </v-card>

          <!-- <v-card class="mx-16 py-0 mb-3 vcard5"> -->

            <!--  
              <p class="custom-transform-class text-none mt-4" style="font-size: 16px;background-color:#edf3ff">

                {{ etho_price != -1 ? 1 : '' }}  
                <img :src="`${MAINNET_NETWORK_LOGO}`" style="height: 20px;margin-top:-3px;margin-right:0px; object-fit: contain;"/> 


                ~=
                {{ etho_price != -1 ? Math.round(100/etho_price * 1000) / 1000 : 'loading...' }}  
                <img :src="`${MAINNET_ASSET_LOGO}`" style="height: 20px; margin-top:-3px;margin-right:0px; object-fit: contain;"/> 

              </p> -->

              <!-- <p class="custom-transform-class text-none mt-2" style="font-size: 16px;background-color:#f0f0f0"> -->

              <!-- <span class="font-weight-black totalAssetFont">
                {{ EthoWithComma(BNStrToNumstr(total_cubits)) }}  <img :src="`${MAINNET_NETWORK_LOGO}`" style="height: 14px; object-fit: contain;margin-top:-2px;" />  Cubits
              </span> -->
              <!-- </p> -->

         <!-- </v-card> -->


        </v-col> 




        <v-col>
          <v-card class="vcard2" height="auto" align="center" justify="center">
            <v-card-actions>
              <v-row dense  align="center" justify="center">
              <div class="d-md-block justify-content-center  mt-5" align="center" justify="center">
                <a :href="`${EXPLORER_ETH_ADDRESS}${address_TKN}`" target="_blank" rel="noopener noreferrer">
                  <img :src="`${TOKEN_NETWORK_LOGO}`" style="height: 100px; object-fit: contain;" />
                  <!-- <img :src="`${TOKEN_NETWORK_LOGO}`" style="height: 100px; object-fit: contain;" /> -->
                  <!-- <img :src='"../assets/images/storageprotocol/eth-logo.svg"' style="height: 100px; object-fit: contain;" /> -->
                  <img :src="`${TOKEN_ASSET_LOGO}`" style="height: 100px; object-fit: contain;margin-left:-13px;" />
                  
                </a>
                <p class="mb-0">
                  <img :src="`${TOKEN_NETWORK_ERC20}`" style="height: 40px; object-fit: contain;" />
                </p>
     

                <p class="mb-2">{{TOKEN_NETWORK_TICKER}} </p>
                <!-- <p class="mb-2"><a class="custom-transform-class text-none" style="font-size: 10px;" >{{ address_TKN }}</a> </p> -->


                <p class="mb-2"><span class="custom-transform-class text-none" style="font-size: 10px;" >{{ TOKEN_NETWORKNAME }}</span> </p>

                <p class="mb-2">
                  <span class="font-weight-black totalAssetFont">
                    {{ EthoWithComma(BNStrToNumstr(currentLocked_ETH)) }} $   
                    <img :src="`${TOKEN_ASSET_LOGO}`" style="height: 14px; object-fit: contain;margin-top:-3px;" />  TOTAL
                    <!-- TOTAL -->
                  </span>
                </p>



              </div>
            </v-row>
            </v-card-actions>

            <div class="text-center" style="background-color:#26a17b">
              <span class="white--text">Locked USDT</span>
            </div>

          </v-card>
        </v-col>
      </v-row>


      <v-row dense align="center" justify="center" v-if="directionIndicatorEthoToEth">
        <v-col>
          <v-card class="vcard2" height="auto" align="center" justify="center">
            <v-card-actions>
              <div align="center" justify="center">
              <div class="d-md-block balanceInfoBlock ml-6 mt-7">
                <p class="convertText row" >Balance:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(currentBalanceETHO)) }} $ <img :src="`${MAINNET_ASSET_LOGO}`" class="miniETHO" style="height: 12px;" /> </span></p> 
                
                <p class="convertText row" >Fee:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(feeBNStr)) }} $ <img :src="`${MAINNET_ASSET_LOGO}`" class="miniETHO" style="height: 12px;" /> </span></p> 
      
                <p class="convertText row">Minimum:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(minBNStr)) }}  $ <img :src="`${MAINNET_ASSET_LOGO}`" class="miniETHO" style="height: 12px;" /> </span></p> 
              </div>
            </div>
            </v-card-actions>
          </v-card>
        </v-col>

        <div class="dividerCol">
        </div>

        <v-col>
          <v-card class="vcard2" height="auto" align="center" justify="center">
            <v-card-actions>
              <v-row dense  align="center" justify="center">
              <div class="d-md-block balanceInfoBlock mt-7">
                <!-- <p class="text-left convertText">Balance:  <span class="font-weight-black">{{ EthoWithComma(BNStrToNumstr(balanceNumStr_TKN)) }} <img :src='"../assets/images/storageprotocol/tether_256.png"' class="miniETH" style="height: 12px;" /> </span></p>  -->
                <p class="convertText row" style="float:left;">Balance:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(balanceNumStr_TKN)) }} $ <img :src="`${TOKEN_ASSET_LOGO}`" class="miniETH" style="height: 12px;" /> </span></p> 
              </div>
            </v-row>
            </v-card-actions>
          </v-card>
        </v-col>

      </v-row>

      <v-row dense align="center" justify="center" v-if="directionIndicatorEthToEtho">

        <v-col>
          <v-card height="auto" align="center" justify="center">
            <v-card-actions>
              <v-row dense  align="center" justify="center">
              <div class="d-md-block balanceInfoBlock mt-7">
                <p class="convertText row">Balance:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(balanceNumStr_Ether1) ) }} $ <img :src="`${MAINNET_ASSET_LOGO}`" class="miniETHO" style="height: 12px;" /> </span></p> 
              </div>
            </v-row>
            </v-card-actions>
          </v-card>
        </v-col>

        <div class="dividerCol">
        </div>

        <v-col>
          <v-card height="auto" align="center" justify="center">
            <v-card-actions>
              <div align="center" justify="center">
              <div class="d-md-block balanceInfoBlock ml-6 mt-7">
                <p class="convertText row">Balance:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(currentBalanceETH)) }} $ <img :src="`${TOKEN_ASSET_LOGO}`" class="miniETH" style="height: 12px;" /> </span></p> 
               <br>
                <p class="convertText row">Fee:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(feeBNStr)) }} $ <img :src="`${TOKEN_ASSET_LOGO}`" class="miniETH" style="height: 12px;" /> </span></p> 
                <br>
                <p class="convertText row">Minimum:  <span class="font-weight-black ml-1">{{ EthoWithComma(BNStrToNumstr(minTKNStr)) }} $ <img :src="`${TOKEN_ASSET_LOGO}`" class="miniETH" style="height: 12px;" /> </span></p> 
              </div>
            </div>
            </v-card-actions>
          </v-card>
        </v-col>

      </v-row>


      <v-row justify="center" align="center" v-if="contractInfoOk">

 
        <v-col cols="8">

          <v-btn class="py-4" style="z-index: 999999;position: absolute;right: 20px;top: 30px;width: 50px;" v-if="canSwap" @click="restoreInputAmount()" icon>
            <p class="text-none" style="font-size: 12px;margin:2px;">All</p>
          </v-btn>

          <v-text-field
            v-if="directionIndicatorEthoToEth"
            v-model="inputAmount"
            label="Amount"
            outlined
            dense
            required
            @click:append="restoreInputAmount"
                :rules="[
                  () => amountValid || 'Enter proper amount',
                  () => amountEnough || amountZero || 'Amount is too low',
                  () => balanceEnough || amountEqual || 'Balance too low',
                ]"
          >
        
        
        <template #prepend-inner>
          <div style="width:22px">
            <img :src="`${MAINNET_ASSET_LOGO}`" style="margin-top:2px; height: 18px; object-fit: contain;" />
          </div>
        </template>

        </v-text-field>

        


          <v-text-field
            v-if="directionIndicatorEthToEtho"
            v-model="inputAmount"
            label="Amount"
            outlined
            dense
            required
            @click:append="handleClickAppend"
            :rules="[() => amountValid || 'Enter proper amount', () => amountEnough || 'Amount is too low', () => balanceEnough || 'Balance too low']"
          >

          <template #prepend-inner>
            <div style="width:22px">
              <!-- <v-img style="margin: auto 0" max-height="25" max-width="30" :src="`${MAINNET_ASSET_LOGO}`"/> -->
              <img :src="`${TOKEN_ASSET_LOGO}`" style="height: 18px; object-fit: contain;margin-top:2px;" />
            </div>
          </template>

        </v-text-field>

  
          <v-card class="mb-8" height="44" align="center" justify="center" v-if="directionIndicatorEthoToEth && amountEnough">
            <p class="text-center py-2" style="font-size: 12px;">
              You will receive:  <span class="font-weight-black">{{ amountEnough ? '~' + EthoWithComma(BNStrToNumstr(amountReceivedBNStr)) : '...' }}  $  </span><img :src="`${TOKEN_ASSET_LOGO}`" class="mr-1" style="margin-left:5px; margin-top:-3px; height: 18px; object-fit: contain;" />{{ TOKEN_NETWORK_TICKER }}<img :src="`${TOKEN_NETWORK_ERC20}`" style="height: 30px; margin-top:-3px; object-fit: contain;" /> 
              <!-- Fee:  <span class="font-weight-black red--text">{{ amountEnough ? '~' + BNStrToNumstr(this.amountBN.sub(amountReceivedBNStr)) : '...' }} ETHO </span><img :src='"../assets/images/assets/images/storageprotocol/sUSDT_256.png"' style="margin-left:16px; height: 18px; object-fit: contain;" /> -->
            </p> 
           
          </v-card> 
          <v-card  class="mb-8" height="44" align="center" justify="center" v-if="directionIndicatorEthToEtho && amountEnough">
            <p class="text-center py-2" style="font-size: 12px;">You will receive:  <span class="font-weight-black">{{ amountEnough ? '~' + EthoWithComma(BNStrToNumstr(amountReceivedBNStr)) : '...' }} $  </span><img :src="`${MAINNET_ASSET_LOGO}`" class="mr-1" style="margin-left:4px; margin-top:-3px; height: 18px; object-fit: contain;" />{{ MAINNET_NETWORK_TICKER }}<img :src="`${MAINNET_NETWORK_ERC20}`" style="height: 30px; margin-top:-3px; object-fit: contain;" /></p> 
          </v-card>
        </v-col>
      </v-row>

  
      <v-card width="200" v-if="inbetweenstatus != TOKEN_UNLOCK_MSG && inbetweenstatus != TOKEN_SWITCH_MESSAGE">
          <v-btn text :color="aboutToNullify ? 'warning' : 'success'" large :loading="loading_request" :disabled="requestDisabled" @click="handleClickRequest">
            {{ aboutToNullify ? 'Take Back' : 'Request Swap' }}
          </v-btn>

            <!-- SPECIAL USDT WITHDRAW BUTTON -->
          <!-- <v-btn class="m-1" @click="withdraw(current_withdrawal)" v-if="TOKEN_SWITCH_MESSAGE && current_withdrawal && live_networkID != -1 && parseInt(live_networkID, 16) == token_chainID"> 
            withdraw
          </v-btn> -->

      </v-card>

  
      <v-row justify="center" align="center" class="mt-4">
        <v-alert border="right" color="#a12626" class="mb-0" dark v-if="!!warningMessage"><p class="text-center mb-0" style="color:white; font-size: 12px;">{{ warningMessage }}</p></v-alert>
      </v-row>

      <v-row justify="center" align="center" class="mt-4">
        <v-alert border="right" color="#a12626" class="mb-0" dark v-if="!!hintMessage"><p class="text-center mb-0" style="color:white; font-size: 12px;">{{ hintMessage }}</p></v-alert>
      </v-row>
      
      <v-row justify="center" align="center" class="mt-3">
        <v-alert border="right" color="#9d9d9d" class="mb-2" dark v-if="!!inbetweenstatus">
          <p class="text-center mb-0" style="color:white; font-size: 12px;">
            {{inbetweenstatus}}

          </p>

        </v-alert>
      </v-row>

      
        <v-row justify="center" align="center" class="mt-4">
          <v-alert border="right" color="#9d9d9d" class="mb-2" dark v-if="!!successMessage"><p class="text-center mb-0" style="color:white; font-size: 12px;">{{ successMessage }}</p></v-alert>
        </v-row>
        <v-row justify="center" align="center" class="mt-4">
          <v-alert border="right" color="#9d9d9d" class="mb-2" dark v-if="loading_controllerInfo"><p class="text-center mb-0" style="color:white; font-size: 12px;">Loading Contract Information</p></v-alert>
        </v-row>

        <v-row justify="center" align="center" class="mt-4">
          <v-alert border="right" color="#9d9d9d" dark v-if="!!hashes"><p class="text-center" style="color:white; font-size: 12px;">Transaction hashes:
            <br />
            Mint (CUBITS Network):
            <a style="color:black;" :href="`${EXPLORER_ETHO_TX}${hashes.txHashCollect}`" target="_blank" rel="noopener noreferrer">
              {{ hashes.txHashCollect }}
            </a>
            <br />
            Lock (ETH Network):
            <a style="color:black;" :href="`${EXPLORER_ETH_TX}${hashes.txHashDispense}`" target="_blank" rel="noopener noreferrer">
              {{ hashes.txHashDispense }}
            </a></p>
          </v-alert>

          <v-alert border="right" color="#9d9d9d" dark v-if="!!hashes2"><p class="text-center" style="color:white; font-size: 12px;">Transaction hashes:
            <br />
            Burn (CUBITS Network):
            <a style="color:black;" :href="`${EXPLORER_ETHO_TX}${hashes2.txHashCollect}`" target="_blank" rel="noopener noreferrer">
              {{ hashes2.txHashCollect }}
            </a>
            <br />
            Unlock (ETH Network):
            <a style="color:black;" :href="`${EXPLORER_ETH_TX}${hashes2.txHashDispense}`" target="_blank" rel="noopener noreferrer">
              {{ hashes2.txHashDispense }}
            </a></p>
          </v-alert>
        </v-row>



        <v-card v-if="pending_withdrawals.length == 0" class="mt-0 mb-4">
        <v-card-text>
          <strong v-if="loading_withdrawals_active == false">You have no pending USDT withdrawals.</strong>

          <strong v-if="loading_withdrawals_active == true">
            loading recent USDT withdrawals... {{ loading_withdrawals_count  }}
            <!-- checking USDT withdrawals... -->
            <!-- <v-btn size="x-small" v-if="loading_withdrawals_active == true" large :loading="true" variant="flat"> </v-btn> -->
          </strong>
          

        </v-card-text>
      </v-card>

      <!-- AREA FOR WITHDRWALS -->
      <v-card v-if="pending_withdrawals.length > 0" class="mt-0 mb-4">
        <!-- <v-card-text>
          <strong>You have unlocked funds.</strong>
        </v-card-text> -->

       <v-data-table :headers="withdrawal_headers" :items="pending_withdrawals" height="auto" hide-default-header hide-default-footer>
         
         <template v-slot:item.activity="{ item }">
          
            <v-btn size="x-small" @click="withdraw(item)" v-if="!(current_withdrawal && item[0] == current_withdrawal[0]) && live_networkID != -1 && parseInt(live_networkID, 16) == token_chainID && item.status == 'WITHDRAW'"> 
               {{item.status}}
             </v-btn>
             <v-btn size="x-small" v-if="current_withdrawal && item[0] == current_withdrawal[0]" large :loading="true"> </v-btn> 

             <!-- <v-btn size="small" @click="SwitchNetwork('0x'+token_chainID.toString(16))" v-if="live_networkID != -1 && parseInt(live_networkID, 16) == mainnet_chainID && item.status == 'WITHDRAW'" :disabled="true">  -->
             <v-btn slim="true" size="small" @click="SwitchNetwork('0x'+token_chainID.toString(16))" v-if="live_networkID != -1 && parseInt(live_networkID, 16) == mainnet_chainID && item.status == 'WITHDRAW'"> 
               Switch Network 
               <img :src="`${TOKEN_NETWORK_LOGO}`" class="ml-2 mr-1" height="20px" />
               <!-- {{ TOKEN_NETWORKNAME }} -->
               
             </v-btn>

         </template>

         <template v-slot:item.status="{ item }">
  

             <img :src='"../assets/images/etho/hourglass3.png"' style="height: 25px;margin-top:-2px; object-fit: contain;" v-if="item.status == 'WAITING'"/>
             <img :src='"../assets/images/etho/check.png"' style="height: 24px;margin-top:-4px; object-fit: contain;" v-if="item.status == 'WITHDRAW'"/>
             <img :src='"../assets/images/etho/close.png"' style="height: 27px;margin-top:-3px; object-fit: contain;" v-if="item.status == 'FAILED'"/>
             <!-- <a style="margin-left:11px;" v-b-tooltip.hover title="`${item.signature_names}`">{{item.signature_count}}</a>  -->
             <a style="margin-left:11px;" :id="`tooltip_${item.original_txID.substr(0,8)}`">{{item.signature_count}}</a> 
             <!-- <b-tooltip :target="`tooltip_${item.original_txID.substr(0,8)}`" triggers="hover">
               {{item.signature_names}}
             </b-tooltip> -->


         </template>

         
         <template v-slot:item.amount="{ item }">
          
               {{BNStrToNumstr(item.amount)}}
               <img :src="`${TOKEN_ASSET_LOGO}`" style="height: 14px; object-fit: contain;margin-top:-3px;" />
          
         </template>

         <template v-slot:item.original_txID="{ item }">
          
           <!-- {{item.original_txID.substr(0,5)}}...{{item.original_txID.substr(-3)}} -->
           <a icon style="color:black;" class="px-1" :href="`${item.receipt}`" target="_blank" rel="noopener noreferrer">{{item.original_txID.substr(0,5)}}...{{item.original_txID.substr(-3)}}</a>
           
         </template>

       </v-data-table>
     </v-card>
      <!-- AREA FOR WITHDRWALS END -->




      <v-row dense>
        <v-col>
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
<!-- 
             <v-btn icon class="px-14" :href="`${DEX_LINK}`" target="_blank">
                <img :src="`${DEX_IMG}`" style="margin-left:16px; height: 18px; object-fit: contain;" />
              </v-btn>

              <v-btn icon class="px-14" :href="`${TKN_LINK}`" target="_blank">
                <img :src="`${TKN_IMG}`" style="margin-left:16px; height: 18px; object-fit: contain;" />
              </v-btn> -->


            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>








    </v-container>
</template>
<script>
/* eslint-disable camelcase */
//import Vue from 'vue'
import { Contract, providers, BigNumber,utils } from 'ethers'
// import Axios from 'axios'
//import firebase from 'firebase/app'
//import 'firebase/firestore'
import USDT_STORAGEPROTOCOL_TOKEN from '/abis/Token.json'
import Token from '/abis/ERC20Basic.json'
// import Oracle from '/abis/oracle_contract.json'
import Signer from '/abis/MultiSig_Controller.json'
import Verifier from '/abis/MultiSig_USDT_Owner.json'

//const address_BAO = '0x22a2a6ac2b0c26023353077e6a5572503ec053a5' // 3DFA1F055CA9F5ACBE6FD6A8B07A89EAC0EDE6244EA7953AC8D1D2B50F652A03 0x22a2a6ac2b0c26023353077e6a5572503ec053a5
// const address_VERIFIER = '0x8d7f817325ADF4ffEf8c84A71f6537BCdC75346e';
const address_TKN_receiver = '0x98Dd4e83fb02827bF5f7B41fE5E286216d5bbC8A' //'0xFe7632F3751841Cbbf6F12b82978F0472596390b' //'0x63e5950a52EC412cf3C54856963E8f67aa57D605' //'0x63e5950a52EC412cf3C54856963E8f67aa57D605' //'0xF93e51367f18bbA105D93951B57f875317850393'; // USDT CONTRACT
const address_TKN = '0x8c291D9b42fFB9A23D6cfd791Eb692B286c018C8' //'0x0b5326Da634f9270FB84481DD6F94d3dC2cA7096' //'0x63e5950a52EC412cf3C54856963E8f67aa57D605' //'0xF93e51367f18bbA105D93951B57f875317850393'; // USDT CONTRACT
const address_USDT_storageprotocol = '0x7777777777777777777777777777777777777777'; // 3DFA1F055CA9F5ACBE6FD6A8B07A89EAC0EDE6244EA7953AC8D1D2B50F652A03 0x22a2a6ac2b0c26023353077e6a5572503ec053a5
// const address_ORCL = '0x1509c8F22b7a14bab4EA829324BFcc44371A5667'; // NOT NEEDED
const address_SIGNER = '0x9990000000000000000000000000000123456789';
const address_SIGNER_authority = "0x8FB9b11E94C762a43247d09E8BCFAc78AD307BfA" //"0x6668685991423444373955509583400957353773" ; //"0xcaded6e8b266b9fb5781506885283552387c748b"; //'0xB5e919EAfCF60932060Cd4A9d9b9568736CDFBf0'; // transmitter client for receipts VERY IMPORTANT TO UPDATE


// const address_DEVFUND = '0xBA57dFe21F78F921F53B83fFE1958Bbab50F6b46'; // NOT NEEDED

const TOKEN_CONFIRMATIONS = 5;
const ETHO_CONFIRMATIONS = 1;

// const ETH_LOGO = "../assets/images/storageprotocol/tether_256.png";
// console.log("ETH_LOGO",ETH_LOGO)
const EXPLORER_ETH_ADDRESS = "https://goerli.etherscan.io/address/";
const EXPLORER_ETHO_ADDRESS = "https://explorer.storageprotocol.com/address/";
const EXPLORER_ETHO_TX = "https://explorer.storageprotocol.com/tx/";
// const EXPLORER_ETHO_TX = "https://testnetexplorer2.storageprotocol.com/tx/";
//const EXPLORER_ETH_TX = "https://testnet.bscscan.com/tx/";
//const EXPLORER_ETH_TX = "https://ropsten.etherscan.io/tx/";
const EXPLORER_ETH_TX = "https://goerli.etherscan.io/tx/";
// const EXPLORER_ETH_TX = "https://sepolia.etherscan.io/tx/";

// const providerO = new providers.JsonRpcProvider('https://rpc.storageprotocol.com')

//const token_rpc_url = "https://ropsten.infura.io/v3/ad333b804b7c4892a5826cfcc09db9ba";

//const mainnet_rpc_url = "https://rpc.storageprotocol.com";
// const token_chainID = 1; //"0x3";

const TOKEN_NETWORKNAME = "Ethereum"
const TOKEN_NETWORK_TICKER = "USDT"
const TOKEN_NETWORK_LOGO = require('@/assets/images/storageprotocol/eth-logo.svg')
const TOKEN_NETWORK_ERC20 = require('@/assets/images/etho/erc20.jpg')
const TOKEN_ASSET_LOGO = require('@/assets/images/storageprotocol/tether_256.png')
// const TOKEN_SWITCH_MESSAGE = "Please switch to "+TOKEN_NETWORKNAME+" Network to unlock your Withdrawal."
const TOKEN_SWITCH_MESSAGE = "Please confirm in Metamask the Network switch to "+TOKEN_NETWORKNAME+" Network to withdraw your USDT."
const TOKEN_UNLOCK_MSG = "Please submit in Metamask the withdrawal of your USDT"


const MAINNET_NETWORKNAME = "Storage Protocol"
const MAINNET_NETWORK_TICKER = "USDT"
const MAINNET_ASSET_LOGO = require('@/assets/images/storageprotocol/sUSDT_256.png')
const MAINNET_NETWORK_ERC20 = require('@/assets/images/etho/src20.jpg')
const MAINNET_NETWORK_LOGO = require('@/assets/images/storageprotocol/cubits_black_256.png')

const DEX_LINK = "https://info.uniswap.org/#/tokens/0x0b5326Da634f9270FB84481DD6F94d3dC2cA7096"
const DEX_IMG = require('@/assets/images/etho/uniswap.png')
const TKN_LINK = "https://etherscan.io/token/0x0b5326Da634f9270FB84481DD6F94d3dC2cA7096"
const TKN_IMG = require('@/assets/images/etho/etherscan-logo.png')

const token_chainID = 5; //"0x3";
const mainnet_chainID = 420500100; //"0x14095a";

// const ETHO_ETH_FEE_DEPOSIT = 0.9 // in dollars
// const ETH_FEE_WITHDRAWAL = 0 // in dollars

const ETHO_ETH_FEE_DEPOSIT = 0 // in dollars
const ETH_FEE_WITHDRAWAL = 0.01 // in dollars

const ETHO_ETH_MIN_DEPOSIT = 0.1 // in dollars
const ETH_MIN_WITHDRAWAL = 0.09 // in dollars

//const provider_TOKEN = new providers.InfuraProvider(3, 'ad333b804b7c4892a5826cfcc09db9ba');
// const provider_TOKEN = new providers.InfuraProvider(11155111, '9aa3d95b3bc440fa88ea12eaa4456161');
// const provider_TOKEN = new providers.JsonRpcProvider('https://mainnet.infura.io/v3/a289a5a7fb754ae8aa8fa1915f87eda0');
const provider_TOKEN = new providers.JsonRpcProvider('https://goerli.infura.io/v3/a289a5a7fb754ae8aa8fa1915f87eda0');
const provider_ETHO = new providers.JsonRpcProvider('https://rpc.storageprotocol.com');
//const provider_TOKEN = new providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
//const provider_TOKEN = new providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/')


// const ORCL = new Contract(address_ORCL, Oracle.abi, provider_ETHO)

// console.log(TKN_ETH_NETWORK)
// console.dir(TKN_ETH_NETWORK)
// console.log(TKN_ETH_NETWORK.populateTransaction)
// console.dir(TKN_ETH_NETWORK.populateTransaction)

// mainnetblock = await provider_TOKEN.getBlock("latest")
// console.log(provider_TOKEN.getBlock("latest"))
// const TRANSMITTER_ACCOUNT = "0xcaded6e8b266b9fb5781506885283552387c748b"
const TRANSMITTER_ACCOUNT = utils.arrayify(address_SIGNER_authority)

const TKN_ETH_NETWORK = new Contract(address_TKN, Token.abi, provider_TOKEN)
const TKN_STORAGEPROTOCOL = new Contract(address_USDT_storageprotocol, USDT_STORAGEPROTOCOL_TOKEN.abi, provider_ETHO)
const SGNR = new Contract(address_SIGNER, Signer.abi, provider_ETHO) 

const VERIFIER = new Contract(address_TKN_receiver, Verifier.abi, provider_TOKEN)

// const ETH_PRICE_URL = "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=eth"


// async function test() {
//   let withdrawal_tx = await SGNR.validated_transactions("0x7f9c2bd1e3cedb61d5d76ed1f2398197e59f7c49f5593b07bfaf621ff40d6342");
//   console.log("withdrawal_tx",withdrawal_tx)
// }


// test()

// let _tx = "0x6a7d68cdd80d2b645ffce5aafcae892bdcc1389375665f92439cb6209de0b637"

// let encodePacked =  utils.concat([ TRANSMITTER_ACCOUNT, utils.toUtf8Bytes(_tx) ])
// const _hash = utils.keccak256(encodePacked)

// console.log("_hash",_hash)
// let result_hashes = SGNR.GetBridgeReceipt(_hash);

// console.log("result_hashes",result_hashes)
// console.log("_tx",_tx)



//const url_baseIP = "https://ethobridge.uc.r.appspot.com";
//const url_baseIP = "http://localhost:5533";
//const url_baseIP = "http://52.50.170.41:5533";
// const url_baseIP = "https://bridge.storageprotocol.com/api";
//const url_baseIP = "https://52.50.170.41/api";


//const url_baseIP = "http://77.20.0.26:5533";

// const url_ethoTOwetho = url_baseIP+"/ETHOtoWETHO/ETH/";
// const url_wethoTOetho = url_baseIP+"/WETHOtoETHO/ETH/";

//// console.log("TKN",TKN)

function removeTrailingZeros(str) {
  if (str === '0') return str
  if (str.slice(-1) === '0') return removeTrailingZeros(str.substr(0, str.length - 1))
  if (str.slice(-1) === '.') return str.substr(0, str.length - 1)
  return str
}
function numstrToBN(input) {
  const spl = input.split('.')
  if (spl[1]) spl[1] = spl[1].substr(0, 18)
  return BigNumber.from(spl.join('') + '000000000000000000'.substr(0, 18 - (spl[1] || '').length))
}
function InputFix(str) {
  let check_zeros = str.split(".")
  if (parseFloat(check_zeros[1]) > 0) {
    return check_zeros.join(".");
  } else {
    return check_zeros[0];
  }
}
function BNStrToNumstr(str, precision = 2) {
  ////// console.log("str",str)
  str = str.toString()
  if (str === '0') return str
  if (isNaN(Number(str))) return 'NaN'
  if (str.length <= 18) return removeTrailingZeros(('0.' + '000000000000000000'.substr(0, 18 - str.length) + str).substr(0, 18 - str.length + precision + 2))
  else return [str.substr(0, str.length - 18), str.slice(-18)].join('.').substr(0, str.length - 18 + precision + 1)
}
function EthoWithComma(amount) {
    var str = amount.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
    // str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, "");
    if (parseFloat(str[1]) > 0) {
      return str.join(".");
    } else {
      return str[0];
    }

}

function Sleep(milliseconds) {
 return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export default {
  name: "EthBridge",
  data() {
    return {
      live_networkID: -1,
      // withdrawal_nr: -1,
      current_withdrawal: null,
      loading_withdrawals_active: false,
      loading_withdrawals_count: "0/0",
      pending_withdrawals: [],
      withdrawal_headers: [
          {
            text: 'Action',
            align: 'start',
            sortable: false,
            value: 'activity',
          },
          
          // { text: 'Event', value: 'event_name' ,sortable:true},
          { text: 'Amount', value: 'amount' ,sortable:true},
          // { text: 'Fee', value: 'fee' ,sortable:true},
          // {
          //   text: 'Network',
          //   align: 'start',
          //   sortable: false,
          //   value: 'chainID',
          // },

          { text: 'To', value: 'recipient' ,sortable:true},
          { text: 'Signatures', value: 'status' ,sortable:true },
          { text: 'Tx', value: 'original_txID' ,sortable:true},
      ],
      balanceNumStr_Ether1: '',
      directionIndicatorEthToEtho: false,
      directionIndicatorEthoToEth: true,
      provider: providers.Web3Provider | null,
      signer: providers.JsonRpcSigner | null,
      wallet: '',
      token_chainID:token_chainID,
      mainnet_chainID:mainnet_chainID,
      TOKEN_NETWORKNAME:TOKEN_NETWORKNAME,
      MAINNET_NETWORKNAME:MAINNET_NETWORKNAME,
      TOKEN_NETWORK_TICKER:TOKEN_NETWORK_TICKER,
      MAINNET_NETWORK_TICKER:MAINNET_NETWORK_TICKER,
      TOKEN_NETWORK_LOGO:TOKEN_NETWORK_LOGO,
      TOKEN_SWITCH_MESSAGE:TOKEN_SWITCH_MESSAGE,
      TOKEN_UNLOCK_MSG:TOKEN_UNLOCK_MSG,
      TOKEN_ASSET_LOGO:TOKEN_ASSET_LOGO,
      TOKEN_NETWORK_ERC20:TOKEN_NETWORK_ERC20,
      MAINNET_ASSET_LOGO:MAINNET_ASSET_LOGO,
      MAINNET_NETWORK_ERC20:MAINNET_NETWORK_ERC20,
      MAINNET_NETWORK_LOGO:MAINNET_NETWORK_LOGO,
      EXPLORER_ETHO_TX:EXPLORER_ETHO_TX,
      EXPLORER_ETHO_ADDRESS:EXPLORER_ETHO_ADDRESS,
      EXPLORER_ETH_ADDRESS:EXPLORER_ETH_ADDRESS,
      EXPLORER_ETH_TX:EXPLORER_ETH_TX,
      DEX_LINK:DEX_LINK,
      DEX_IMG:DEX_IMG,
      TKN_LINK:TKN_LINK,
      TKN_IMG:TKN_IMG,
      // ETH_PRICE_URL:ETH_PRICE_URL,
      // ETH_LOGO:ETH_LOGO,
      address_TKN:address_TKN,
      address_USDT_storageprotocol:address_USDT_storageprotocol,
      // address_ORCL:address_ORCL,
      address_SIGNER:address_SIGNER,
      bridge_status: 'loading...',
      inbetweenstatus: '',
      total_cubits: -1,
      currentLocked_ETHO: '',
      currentLocked_ETH: '',
      currentBurned: '',
      currentBalance: '',
      currentBalanceETH: '',
      currentBalanceETHO: '',
      balanceNumStr_TKN: '',
      inputAmount: '',
      etho_price: -1,
      last_etho_price: Date.now(),
      costsInfo: {
        WETHOtoETHO: '',
        ETHOtoWETHO: ''
      } | null,
      paramsInfo: {
        minETH: 0,
        minETHO: 0,
        preShutdownFlag: false
      } | null,
      warningMessage: '',
      hintMessage: '',
      successMessage: '',
      loading_request: false,
      loading_controllerInfo: true,
      loading_contractInfo: true,
      // inBetween: false,
      hashes: { txHashCollect: '', txHashDispense: '' } | null,
      hashes2: { txHashCollect: '', txHashDispense: '' } | null,
      // hashes: {
      //   "txHashCollect": "0x30302127d2c7c9045ccea3308878361f124082ae15291abe3592a380ba6c7b03",
      //   "txHashDispense": "0xde4617de79fe530a940f70615a5e8b590d31f3fed2edb006f5b1981f97a8c773" 
      // },
      // hashes2: {
      //   "txHashCollect": "0x30302127d2c7c9045ccea3308878361f124082ae15291abe3592a380ba6c7b03",
      //   "txHashDispense": "0xde4617de79fe530a940f70615a5e8b590d31f3fed2edb006f5b1981f97a8c773" 
      // },
    }
  },
  computed: {
    // eth_logo() {
    //   return require(ETH_LOGO);
    // },
    burnedEnough() {
      if (!this.controllerInfoOk || !this.contractInfoOk) return false
      if(this.directionIndicatorEthoToEth) {
        return this.burnedBN.gte(this.minBNStr)
      } else {
        return this.burnedBN.gte(this.minTKNStr)
      }
    },
    burnedBN() {
      return BigNumber.from(this.currentBurned)
    },
    convertBN(_nr) {
      return BigNumber.from((_nr.toString()))
    },
    amountEqual() {
      if(this.directionIndicatorEthoToEth) {
        return this.amountBN.eq(this.currentLocked_ETHO)
      }
      return this.amountBN.eq(this.currentBurned)
    },
    entryExists() {
      if (!this.contractInfoOk || !this.currentLocked_ETHO) return false
      return this.currentLocked_ETHO.gt(0)
    },
    canMaximizeAmount() {
      return !this.burnedNonZero && ((this.amountBN.lt(this.currentBalanceETHO) && this.directionIndicatorEthoToEth) || (this.amountBN.lt(this.currentBalanceETH) && this.directionIndicatorEthToEtho))
    },
    amountReceivedBNStr_ready() {
      if (!this.controllerInfoOk || !this.contractInfoOk) return 'NaN'
      return this.currentLocked_ETHO.sub(this.feeBNStr).toString()
    },
    providerOk() {
      return !!this.provider && !!this.signer && !!this.wallet
    },
    controllerInfoOk() {
      return !!this.costsInfo && !!this.paramsInfo
    },
    contractInfoOk() {
      // console.log("cinfo",!!this.currentLocked_ETHO,!!this.currentBalance)
      // console.log("cinfo",!!this.currentBalanceETHO,!!this.currentBalanceETH)
      if(this.directionIndicatorEthoToEth) {
        // return !!this.currentLocked_ETHO && !!this.currentBalance
        return !!this.currentBalanceETHO
      } else {
        return !!this.currentBalanceETH
      }
      //return !!this.currentBurned && !!this.currentBalance
      // return !!this.currentBalance

    },
    // directionIndicatorEthoToEth() {
    //   return this.live_networkID != -1 && parseInt(this.live_networkID, 16) == token_chainID
    // },
    // directionIndicatorEthToEtho() {
    //   return this.live_networkID != -1 && parseInt(this.live_networkID, 16) == mainnet_chainID
    // },
    allSafe() {
      return this.providerOk && this.controllerInfoOk && this.contractInfoOk && !this.warningMessage && !this.loading_controllerInfo
    },
    requestDisabled() {
      return !this.allSafe || !this.balanceEnough || (!this.amountEnough && !this.aboutToNullify)
    },
    aboutToNullify() {
      // return this.amountZero//&& this.entryExists //  && this.inputAmount != 0 
      return false
    },
    amountZero() {
      return this.inputAmount === '0'
    },
    amountValid() {
      return !!Number(this.inputAmount) || this.amountZero
    },
    amountEnough() {
      if (!this.controllerInfoOk) return false
      if(this.directionIndicatorEthoToEth) {
        return this.amountBN.gte(this.minBNStr)
      } else {
        return this.amountBN.gte(this.minTKNStr)
      }
    },
    canSwap() {
      if (!this.controllerInfoOk) return false
      if(this.directionIndicatorEthoToEth) {
        return this.currentBalanceETHO.gte(this.minBNStr)
      } else {
        return this.currentBalanceETH.gte(this.minTKNStr)
      }
    },
    balanceEnough() {
      if (!this.controllerInfoOk) return false
      
      if(this.directionIndicatorEthoToEth) {
        return this.amountBN.lte(this.currentBalanceETHO)
      } else {
        return this.amountBN.lte(this.currentBalanceETH)
      }
    },
    lockedEnough() {
      if (!this.controllerInfoOk || !this.contractInfoOk) return false
      if(this.directionIndicatorEthToEtho) {
        return BigNumber.from(this.currentLocked_ETHO).gte(this.minTKNStr)
        //return true
      } else {
        return true // always enough Wrapped etho existing ;)
      }

    },
    amountBN() {
      if (!this.amountValid) return BigNumber.from(0)
      return numstrToBN(this.inputAmount)
    },
    amountReceivedBNStr() {
      if (!this.controllerInfoOk) return 'NaN'
      return this.amountBN.sub(this.feeBNStr).toString()
    },
    feeBNStr() {
      //// console.log("ETHOOOOOO BRIDGE",this.controllerInfoOk)
      if (!this.controllerInfoOk) return 'NaN'
      if(this.directionIndicatorEthoToEth) {
        //return BigNumber.from(this.costsInfo.ETHOtoWETHO).mul(this.paramsInfo.costToFeeQuotient).div('100').toString()
        return BigNumber.from(this.costsInfo.ETHOtoWETHO).toString()
        
      } else {
        //return BigNumber.from(this.costsInfo.WETHOtoETHO).mul(this.paramsInfo.costToFeeQuotient).div('100').toString()
        return BigNumber.from(this.costsInfo.WETHOtoETHO).toString()
      }
      
    },
    minTKNStr() {
      if (!this.controllerInfoOk) return 'NaN'
      //return BigNumber.from(this.costsInfo.WETHOtoETHO).add(this.paramsInfo.minETH).toString()
      return BigNumber.from(this.paramsInfo.minETHO).toString()
    },
    minBNStr() {
      if (!this.controllerInfoOk) return 'NaN'
      return BigNumber.from(this.paramsInfo.minETH).toString()
      //return BigNumber.from(this.costsInfo.ETHOtoWETHO).add(this.paramsInfo.minETHO).toString()
      //return BigNumber.from(this.costsInfo.ETHOtoWETHO)
      //  .mul(this.paramsInfo.costToFeeQuotient)
      //  .mul(this.paramsInfo.feeToMinQuotient)
      //  .mul('120')
      //  .div('1000000')
      //  .toString()
    },
  },
  async mounted() {
    await this.loadControllerInfo()
    if (this.controllerInfoOk) await this.connectProvider()
    this.loading_contractInfo = false
    setInterval(async () => {
      if (this.controllerInfoOk) {
        this.loading_controllerInfo = true
        await this.loadControllerInfo()
      }
    }, 30000)
    window.ethereum.on('networkChanged', () => {
      this.updateNetwork();
    })
    window.ethereum.on('accountsChanged', () => {
      this.handleAccountsChanged();
    })
    


    // this.resetWithdrawalLoader()
    this.loadWithdrawals()


  },
  methods: {
    count_signatures(_list) {
      // return _list.length
      return new Set(_list).size;
    },
    changeEthDirection(manually_changed) {
      console.log("CHANGEDIRECTION MANUALLY:",manually_changed)
      if(this.directionIndicatorEthToEtho) {
        this.directionIndicatorEthToEtho = false;
        this.directionIndicatorEthoToEth = true;
        this.updateNetwork(manually_changed)
      } else {
        this.directionIndicatorEthToEtho = true;
        this.directionIndicatorEthoToEth = false;
        this.updateNetwork(manually_changed)
      }
    },
    async updateNetwork(manually_changed) {
      this.hashes = null
      this.hashes2 = null
      this.warningMessage = '';
      this.hintMessage = '';
      this.successMessage = '';
      this.loading_controllerInfo = true
      await this.loadControllerInfo()
      if (this.controllerInfoOk) await this.connectProvider(manually_changed)    
      this.loading_contractInfo = false
    },
    BNStrToNumstr,
    EthoWithComma,
    async SwitchNetwork(targetID) {
        console.log("SWITCHING NETWORK NOW...")
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: targetID }], // chainId must be in hexadecimal numbers
          });

          this.live_networkID = targetID
          return targetID
          // if (parseInt(targetID, 16) == token_chainID) {
          //   this.directionIndicatorEthToEtho = true;
          //   this.directionIndicatorEthoToEth = false;
          // } else if (parseInt(targetID, 16) == mainnet_chainID) {
          //   this.directionIndicatorEthToEtho = false;
          //   this.directionIndicatorEthoToEth = true;
          // }

        } catch (error) {
          console.log("FAILED TO SWITCH, REVERT DIRECTION")
          if (parseInt(this.live_networkID, 16) == token_chainID) {
            this.directionIndicatorEthToEtho = true;
            this.directionIndicatorEthoToEth = false;
          } else if (parseInt(this.live_networkID, 16) == mainnet_chainID) {
            this.directionIndicatorEthToEtho = false;
            this.directionIndicatorEthoToEth = true;
          }
          return null
        }
        // console.log("switched_net",switched_net)
    },
    restoreInputAmount() {
      //this.inputAmount = removeTrailingZeros(BNStrToNumstr(this.currentLocked_ETHO, 18))
      //this.inputAmount = removeTrailingZeros(BNStrToNumstr(this.minBNStr, 8))
      if(this.directionIndicatorEthoToEth) {
        this.inputAmount = InputFix(removeTrailingZeros(BNStrToNumstr(this.currentBalanceETHO.sub(BigNumber.from("100000000000000000")),18))) || 0
      } else {
        // this.inputAmount = BNStrToNumstr(this.currentBalanceETH.sub(BigNumber.from("1000000000000000000")),18) || 0
        this.inputAmount = InputFix(removeTrailingZeros(BNStrToNumstr(this.currentBalanceETH,18))) || 0
      }

    },
    handleClickAppend() {
      if (this.burnedEnough && !this.amountEqual) this.restoreInputAmount()
      else if (this.canMaximizeAmount) this.maximizeInputAmount()
    },
    async loadEther1Balance() {
      if(this.directionIndicatorEthoToEth) {
        try {

          // ETHO BALANCE
          // let thisBalance = await provider_ETHO.getBalance(this.wallet);
          let thisBalance = await TKN_STORAGEPROTOCOL.balanceOf(this.wallet);
          this.currentBalanceETHO = BigNumber.from(thisBalance);

          this.balanceNumStr_Ether1 = this.currentBalanceETHO;

        } catch (error) {
          console.error("a1"+error)
        }
      } else if(this.directionIndicatorEthToEtho) {
        try {

          // ETH BALANCE
          let thisBalance = await TKN_STORAGEPROTOCOL.balanceOf(this.wallet);
          this.currentBalanceETHO = BigNumber.from(thisBalance); //thisBalance.toString();  

          this.balanceNumStr_Ether1 = this.currentBalanceETHO;

        } catch (error) {
          console.error("a2"+error)
        }
      }
    },
    async loadTKNBalance() {
      try {
        let balanceAmount = await TKN_ETH_NETWORK.balanceOf(this.wallet);
        this.balanceNumStr_TKN = BigNumber.from(balanceAmount);
        
        // ETH BALANCE
        this.currentBalanceETH = BigNumber.from(balanceAmount);
      } catch (error) {
        console.error("a3"+error)
      }
    },
    async getEthoPrice() {
      try { 
        // let xmlHttp = new XMLHttpRequest();
        // xmlHttp.open( "GET", "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=usd", false );
        // xmlHttp.send( null );
        // let jsonData = JSON.parse(xmlHttp.responseText)
        // console.log("etho_price","--",jsonData,jsonData["ether-1"]["usd"])

        // if (jsonData["ether-1"] !== undefined) {
        //   return parseFloat(jsonData["ether-1"]["usd"])
        // } else {
        //   return -1
        // }
        // console.log("get price")
        // let cubits_per_usdt = await TKN_ETH_NETWORK.cubits_buy_value(BigNumber.from("1000000000000000000"));

        var locked_ETHO_Amount = await TKN_STORAGEPROTOCOL.cubitsUSDTpool(); 
        let currentLocked_ETHO = BigNumber.from(locked_ETHO_Amount)

        var locked_ETH_Amount = await TKN_STORAGEPROTOCOL.real_supply_cubits();
        let currentLocked_ETH = BigNumber.from(locked_ETH_Amount)

        // let cubits_per_usdt = currentLocked_ETHO.mul('10000000000000000000000').div(currentLocked_ETH);
        let cubits_per_usdt = currentLocked_ETH.mul('1000000000000000000').div(currentLocked_ETHO);
        // let cubits_per_usdt = await TKN_ETH_NETWORK.cubits_sell_value(BigNumber.from("100000000000000000000"));
        console.log("cubits_per_usdt",cubits_per_usdt)
        let display_nr = EthoWithComma(BNStrToNumstr(cubits_per_usdt))
        // return parseInt(cubits_per_usdt)
        console.log("cubits_per_usdt2",display_nr)
        let res_ratio = Math.round(display_nr * 1000) / 1000
        if (isNaN(res_ratio)) {
          return 100
        } else {
          return res_ratio
        }
         
      } catch (error) {
        // console.error("--",error)
        // return -1
        return 100
      }

    },
    async getTotalCubits() {
      try { 
        
        let cubits_per_usdt = await TKN_STORAGEPROTOCOL.totalcubits();
        if (cubits_per_usdt) {
          return BigNumber.from(cubits_per_usdt) ; //parseFloat(cubits_per_usdt)
        }
      } catch (error) {
        return -1
      }

    },
    async connectProvider(manually_changed) {
      try {
        var insideScope = this.$parent.$parent.showEthBridge
        if (insideScope) {
          if (!window.ethereum) throw new Error('Please set up MetaMask properly')
          await (window.ethereum).enable()
          this.provider = new providers.Web3Provider((window.ethereum) || window.web3)

          var found_chainID = (await this.provider.getNetwork()).chainId

          this.live_networkID = "0x"+found_chainID.toString(16)

          //console.log("manually_changed",manually_changed,"A token_chainID",token_chainID,"found_chainID",found_chainID,"controllerInfoOk",this.controllerInfoOk,"direction",this.directionIndicatorEthoToEth,"showEthBridge",insideScope)

        
          if(this.directionIndicatorEthoToEth) {
            if (found_chainID !== mainnet_chainID) {
              if (found_chainID === token_chainID && manually_changed !== true) { // swap networks..
                
                this.changeEthDirection()
              } else {
                // if (manually_changed === true) {
                  this.SwitchNetwork("0x"+mainnet_chainID.toString(16))
                // }

                throw new Error('You selected wrong network in MetaMask. Make sure you imported and selected '+`${MAINNET_NETWORKNAME}`+' Chain Network and refresh the page')
              }
            }
          } else if (this.directionIndicatorEthToEtho) {
            if (found_chainID !== token_chainID) {
              if (found_chainID === mainnet_chainID && manually_changed !== true) { // swap networks..
                
                this.changeEthDirection()
              } else {
                // if (manually_changed === true) {
                  this.SwitchNetwork("0x"+token_chainID.toString(16))
                // }
                throw new Error('You selected wrong network in MetaMask. Make sure you selected '+`${TOKEN_NETWORKNAME}`+' Chain Network and refresh the page')
              }
            }
          }

          this.signer = this.provider.getSigner()
          this.wallet = await this.signer.getAddress()
          //// console.log("this.wallet",this.wallet)
          await this.loadContractInfo()
        }


        //if (this.entryExists) this.restoreInputAmount()
      } catch (error) {
        // this.warningMessage = 'Could not connect MetaMask. Error: ' + error.message
        // if (error.message.indexOf("Make sure you selected") != -1) {
        //   this.warningMessage = 'Please confirm Network switch in Metamask'
        // } else {
        //   this.warningMessage = 'Could not connect MetaMask. Error: ' + error.message
        // }
        this.warningMessage = 'Could not connect MetaMask. Error: ' + error.message
        
        console.error("a4"+error)
      }
    },
    async handleClickRequest() {
      this.hashes = null
      this.hashes2 = null
      this.warningMessage = '';
      this.hintMessage = '';
      this.successMessage = '';
      this.loading_request = true
      // this.signer = this.provider.getSigner()

      if(this.directionIndicatorEthoToEth) {
        try {
          if (this.aboutToNullify) {
            await this.deposit()
          } else {
            //if ((this.entryExists && !this.amountEqual) || !this.entryExists) 
            var local_tx = await this.deposit()
            //// console.log("local_tx",local_tx)
            if (local_tx) {
              await this.requestOE(local_tx)
            }
            
          }
          
          this.inputAmount = ''
        } catch (error) {
          this.hintMessage = error.message
          console.error("a5"+error)
        }
      } else if(this.directionIndicatorEthToEtho) {
        try {
          if ((this.burnedNonZero && !this.amountEqual) || !this.burnedNonZero) 
            var local_tx2 = await this.burn()
            //// console.log("local_tx2",local_tx2)
            if (local_tx2) {
              await this.requestOE(local_tx2)
            }
            
            this.inputAmount = ''
        } catch (error) {
          this.hintMessage = error.message
          console.error("a6"+error)
        }
      }
 
      // this.inBetween = false
      this.inbetweenstatus = ''
      this.loading_request = false
    },
    async burn() { // going from eth -> storage protocol
      var execute_chainID = (await this.provider.getNetwork()).chainId
      if (execute_chainID !== token_chainID) { // make sure user doesn't send into false network for sum bug reason
        throw new Error('You selected wrong network in MetaMask. Make sure you selected '+TOKEN_NETWORKNAME+' Mainnet Chain network and refresh the page')
      }

      //// console.log("BURN...",this.amountBN,this.amountBN.toString())


      //// console.log("TKN",TKN)

      //const ptx = await TKN.populateTransaction.burn(address_BA, this.amountBN)
      // const ptx = await TKN.populateTransaction.burn(this.amountBN.toString()) // make user burn directly
 
      const LOCAL_TKN_ETH_NETWORK = new Contract(address_TKN, Token.abi, this.signer)

      const ptx = await LOCAL_TKN_ETH_NETWORK.populateTransaction.transfer( address_TKN_receiver, this.amountBN.toString()) // make user burn directly
      console.log({
        ptx,
        amt: this.amountBN.toString(),
        bal: this.currentBalanceETH.toString(),
      })

      let tx = null
      try {
        tx = await this.signer.sendTransaction(ptx)
      } catch (error) {
        console.error("a21"+error)
      }

      if (tx) {
         //// console.log({ tx })
        // this.inBetween = true

        console.log("-->tx:",tx)

        // let mainnetblock = await provider_TOKEN.getBlock("latest")
        // let tokenblock = await provider_ETHO.getBlock("latest")
        // console.log("token:",mainnetblock)
        // console.log("etho:",tokenblock)
        // console.log("tokennumber:",mainnetblock.number)
        // console.log("ethonumber:",tokenblock.number)

        // this.inbetweenstatus = "Swap to USDT-bridge initiated. Waiting for Multisig Confirmation.1"
        this.inbetweenstatus = "Swap to USDT-bridge initiated. Locking USDT into Multisig Contract on "+TOKEN_NETWORKNAME+" Chain."

        const receipt = await tx.wait()

        console.log("--->receipt:",receipt)
        console.log("token:",provider_TOKEN.blockNumber)
        console.log("etho:",provider_ETHO.blockNumber)

        // this.inbetweenstatus = "Swap initiated3. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete."

        // this.inbetweenstatus = "Requesting Withdrawal Confirmation...."
        // this.inbetweenstatus = "Accepted. Waiting for Signature Approval..."
        this.inbetweenstatus = "Accepted. Waiting for Transaction Submission..."
        let blockwaiting_counting = await this.block_waiting2();
        console.log("blockwaiting_counting",blockwaiting_counting)
        // wait here for x blocks

        let multisig_required_amount = await SGNR.getRequired()
        let transaction_index = await this.signature_waiting2(receipt.transactionHash,multisig_required_amount);
        // let withdrawal_tx = await SGNR.validated_transactions(_receipt.hash);
        console.log("transaction_index found->",transaction_index)

        let sig_counting = await this.signature_count_waiting2(multisig_required_amount,transaction_index);

        console.log("SIG COUNTING DONE",sig_counting)

        // txHashCollect

        // let _tx = await this.loadWithdraw(withdrawal_nr)
        // console.log("_tx",_tx)
        // if (_tx != null) {
        //   this.withdraw(_tx)
        //   this.inbetweenstatus = "Please unlock your Withdrawal."
        //   this.inbetweenstatus = "Please unlock your Withdrawal."
        // } else {
        //   this.inbetweenstatus = "Unlocking Withdrawal failed."
        // }
        // this.inbetweenstatus = "Please switch to Goerli network to unlock your Withdrawal."
        // let current_withdrawal = await this.loadWithdraw(withdrawal_nr)

        // this.inbetweenstatus = TOKEN_SWITCH_MESSAGE 
        // let switched_nr = await this.SwitchNetwork('0x'+token_chainID.toString(16))
        // console.log("switched_nr",switched_nr)

        // if (switched_nr == null) {
        //   console.error("a7","USER DENIED WITHDRAW")
        // } 
        
        this.loading_request = false // now it replaces the loader with withdraw button !


        await this.loadContractInfo() // pull this earlier since we changed contract
        
        //// console.log(receipt,receipt.transactionHash)
        return receipt.transactionHash
      }
      
    },
    waitForTxID(_multisig_required_amount,hash, cb) {
      var _this = this

      SGNR.validated_transactions(hash).then((withdrawal_tx_id) => { 
        console.log("resresresresresresresres receipt",withdrawal_tx_id) 
        if (withdrawal_tx_id != 0) {
          cb(withdrawal_tx_id);

        } else {
          setTimeout(function () {
            _this.waitForTxID(_multisig_required_amount,hash, cb);
          }, 5000);
        }
      })

      // this.inbetweenstatus = "Accepted. Waiting for Signature Approval... " + 0 + " / " + _multisig_required_amount 

      // let last_transaction = await SGNR.GetFullTransactionData(_nr,"")
      // console.log(_this,hash,cb)

    },
    async signature_waiting(hash,multisig_required_amount) {
      // let multisig_required_amount = await SGNR.getRequired()
      return new Promise((resolve) => {
        console.log("WAITING FOR SIGNATURES...")
        this.waitForTxID(multisig_required_amount,hash, function (withdrawal_tx) {
            // console.log(receipt.log)
            console.log(withdrawal_tx) // don't forget here to add log of contract id array that is return from web3
            console.log("withdrawal_tx received!:",withdrawal_tx)
            // return withdrawal_tx
            resolve(withdrawal_tx)
          });
        })
    },
    waitForTxID2(_multisig_required_amount,hash, cb) {
      var _this = this

      // let found_transaction_index = await this.findTransactionIndexFromTX(hash);
      // console.log("FOUND found_transaction_index",found_transaction_index)
      // console.log("FOUND found_transaction_index",found_transaction_index)
      // console.log("FOUND found_transaction_index",found_transaction_index)


      this.findTransactionIndexFromTX(hash).then((found_transaction_index) => { 
        console.log("resrfound_transaction_index receipt",found_transaction_index) 
        if (found_transaction_index != 0) {
          cb(found_transaction_index);

        } else {
          setTimeout(function () {
            _this.waitForTxID2(_multisig_required_amount,hash, cb);
          }, 5000);
        }
      })


     

      // let last_transaction = await SGNR.GetFullTransactionData(_nr,"")
      // console.log(_this,hash,cb)

    },
    async signature_waiting2(hash,multisig_required_amount) {

      return new Promise((resolve) => {
        console.log("WAITING FOR SIGNATURES2...")
        this.waitForTxID2(multisig_required_amount,hash, function (transaction_i) {
            console.log(transaction_i) // don't forget here to add log of contract id array that is return from web3
            console.log("transaction_i received2!:",transaction_i)

            resolve(transaction_i)
          });
        })

    },
    async signature_count_waiting2(multisig_required_amount,transaction_i) {
      let now_signatures = 0
      while (now_signatures < multisig_required_amount) {
        let _signatures = await this.get_signatures(transaction_i)
        console.log("_signatures",_signatures)
        now_signatures = this.count_signatures(_signatures)
        console.log("now_signatures",now_signatures)
        this.inbetweenstatus = "Signatures received ... " + now_signatures + " / " + multisig_required_amount 
        await Sleep(1000) // slow down requests....
      }
      return true

    },
    async block_waiting2() {
      let tokenblock = await provider_TOKEN.getBlock("latest")
      let startblock = tokenblock.number
  
      // ETHO_CONFIRMATIONS
      let currentblock = 0
      while (currentblock < TOKEN_CONFIRMATIONS) {
        var display_offset = 0
        let tokenblock = await provider_TOKEN.getBlock("latest")
        currentblock = tokenblock.number - startblock - display_offset
        if (currentblock < 0) {
          currentblock = 0
        }
        this.inbetweenstatus = "Swap initiated. Approving request via Multisig-Bridge controller. This may take up to 5 minutes to complete. Confirmation Block: "+currentblock+" / "+(TOKEN_CONFIRMATIONS-display_offset)
      }

      this.inbetweenstatus = "Confirmation Blocks Reached. Requesting Confirmation."

      return true

    },
    async block_waiting() {

      let tokenblock = await provider_ETHO.getBlock("latest")
      let startblock = tokenblock.number

      let currentblock = 0
      while (currentblock < ETHO_CONFIRMATIONS) {
        var display_offset = 0
        let tokenblock = await provider_ETHO.getBlock("latest")
        currentblock = tokenblock.number - startblock - display_offset
        if (currentblock < 0) {
          currentblock = 0
        }
        this.inbetweenstatus = "Swap initiated. Approving request via Multisig-Bridge controller. This may take between 1-2 minutes to complete. Confirmation Block: "+currentblock+" / "+(ETHO_CONFIRMATIONS-display_offset)
      }

      this.inbetweenstatus = "Confirmation Blocks Reached. Requesting Confirmation."

      return true

    },

    async deposit() {
      var execute_chainID = (await this.provider.getNetwork()).chainId
      if (execute_chainID !== mainnet_chainID) { // make sure user doesn't send into false network for sum bug reason
          throw new Error('You selected wrong network in MetaMask. Make sure you imported and selected Etho Protocol network and refresh the page')
      }

      const LOCAL_TKN_STORAGEPROTOCOL = new Contract(address_USDT_storageprotocol, USDT_STORAGEPROTOCOL_TOKEN.abi, this.signer)
      const ptx = await LOCAL_TKN_STORAGEPROTOCOL.populateTransaction.burn(this.amountBN) // burn sUSDT

      //const ptx = {
      //  from: this.wallet,
      //  to: address_BA
      // }

      // ptx.value = this.amountBN
      // console.log({
      //   ptx,
      //   amt: this.amountBN.toString(),
      //   bal: this.currentBalanceETHO.toString(),
      // })


      this.inbetweenstatus = 'Please confirm sUSDT to USDT swap via Metamask burn' 

      let tx = null
      try {
        tx = await this.signer.sendTransaction(ptx)
      } catch (error) {
        this.current_withdrawal = null
        console.error("a20"+error)
      }
      
      if (tx) {

        //// console.log({ tx })
        // this.inBetween = true

        // console.log("-->tx:",_tx)
        // let mainnetblock = await provider_TOKEN.getBlock("latest")
        // let tokenblock = await provider_ETHO.getBlock("latest")
        // console.log("token:",mainnetblock)
        // console.log("etho:",tokenblock)
        // console.log("tokennumber:",mainnetblock.number)
        // console.log("ethonumber:",tokenblock.number)

        // this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete."
        // this.inbetweenstatus = "Swap to USDT-bridge initiated. Waiting for Block Confirmation. This may take between 5-10 minutes to complete."
        this.inbetweenstatus = "Swap to USDT-bridge initiated. Waiting for Multisig Controller to unlock your USDT funds."


        const receipt = await tx.wait()

        console.log("--->logtx:",tx)
        console.log("--->receipt:",receipt)
        console.log("token:",provider_TOKEN.blockNumber)
        console.log("etho:",provider_ETHO.blockNumber)

        // this.inbetweenstatus = "Swap initiated3. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete."

        // this.inbetweenstatus = "Requesting Withdrawal Confirmation...."

        this.inbetweenstatus = "Accepted. Waiting for Transaction Submission..."
        let blockwaiting_counting = await this.block_waiting();
        console.log("blockwaiting_counting",blockwaiting_counting)
        // wait here for x blocks

        let multisig_required_amount = await SGNR.getRequired()
        let transaction_index = await this.signature_waiting2(receipt.transactionHash,multisig_required_amount);

        console.log("transaction_index found->",transaction_index)

        let sig_counting = await this.signature_count_waiting2(multisig_required_amount,transaction_index);

        console.log("SIG COUNTING DONE",sig_counting)

        // IMPORTANT! the code checks for the first tx found in transactions, the below code actually checks only for executed ones and should be better, but gives less reponse to userinterface!

        // console.log("receipt.hash",receipt.transactionHash)
        // let withdrawal_nr = await this.signature_waiting(receipt.transactionHash,multisig_required_amount);
        // console.log("withdrawal_nr",withdrawal_nr)
        // let current_withdrawal = await this.loadWithdraw(withdrawal_nr)


        let current_withdrawal = await this.loadWithdraw(transaction_index)

        this.inbetweenstatus = TOKEN_SWITCH_MESSAGE 
        let switched_nr = await this.SwitchNetwork('0x'+token_chainID.toString(16))
        console.log("switched_nr",switched_nr)

        if (switched_nr == null) {
          console.error("a7","USER DENIED WITHDRAW")
        } else {
          this.withdraw(current_withdrawal)
          this.inbetweenstatus = TOKEN_UNLOCK_MSG 

        }
        
        this.loading_request = false // now it replaces the loader with withdraw button !



        //// console.log({ receipt })
        await this.loadContractInfo()
        
        return receipt.transactionHash
      }
    },
    async requestOE(_tx) {
      // console.log("requestOE",_tx)
      // var _this = this

      async function getOE(_this) {

        // const SGNR = new Contract(address_SIGNER, Signer.abi, provider_ETHO.getSigner())
        // const SGNR = new Contract(address_SIGNER, Signer.abi, _this.signer)
        // console.log("OE",_tx)
        var _hash = utils.keccak256(utils.concat([ TRANSMITTER_ACCOUNT, utils.toUtf8Bytes(_tx) ]))
        // console.log("_hash",_hash)
        var result_hashes = null
        if(_this.directionIndicatorEthoToEth) {
          try {

            result_hashes = await SGNR.GetBridgeReceipt(_hash);


          } catch (error) {
            console.error("a8",{ ...error })
            throw new Error("FAILED TO CONTACT CONTRACT")
          }
          if (result_hashes !== null && result_hashes !== "" && result_hashes !== false) {
            // console.log("result_hashes",result_hashes)
            // console.log("_tx",_tx)
            return {
              "txHashCollect": _tx,
              "txHashDispense": result_hashes
            }
          } else {
            return null
          }
        } else if (_this.directionIndicatorEthToEtho) {
          try {


            result_hashes = await SGNR.GetBridgeReceipt(_hash);

          } catch (error) {
            console.error("a9",{ ...error })
            throw new Error("FAILED TO CONTACT CONTRACT")
          }
          if (result_hashes !== null && result_hashes !== "" && result_hashes !== false) {
            // console.log("result_hashes2",result_hashes)
            // console.log("_tx2",_tx)
            return {
              "txHashCollect": result_hashes,
              "txHashDispense":  _tx
            }
          } else {
            return null
          }
        }
      }

      var OE_response = null
      while (OE_response === null) {
        OE_response = await getOE(this)
        // console.log("OE_response",OE_response)
        if (OE_response === null) {
          await Sleep(3000) // sleep for 3 seconds
        } else {
          // updates balances after successful trade over
          // await Sleep(2000) // sleep for 3 seconds
          // await this.loadTKNBalance()
          // await this.loadEther1Balance()
          await Sleep(1000) // sleep for 0.5 seconds to be sure network request is "updated" ?
          await this.updateBalances()
          console.log("OE FINISHED",OE_response)
        }
      }
      //// console.log(" WHILE LOOP IS OVER!")
      //await this.loadContractInfo()
      this.inbetweenstatus = ''
      this.successMessage = 'Success! Save transaction hashes below'

      this.hashes = OE_response


    },
    async withdraw(_tx) {
      this.warningMessage = '';
      this.hintMessage = '';
      this.successMessage = ''
      this.hashes2 = null
      
      this.current_withdrawal = _tx
      console.log("with _tx",_tx)
      // tx_hash,_error = VERIFIER.AddSignaturePackage(original_txID,chainID,recipient,amount,fee,event_type,signatures) 
      console.log("VERIFIER",VERIFIER)
     
      console.log("_tx.original_txID,_tx.chainID,_tx.recipient,_tx.amount,_tx.fee,_tx.event_type,_tx.signatures") 
      console.log(_tx.original_txID,_tx.chainID,_tx.recipient,_tx.amount,_tx.fee,_tx.event_type,_tx.signatures)

      var execute_chainID = (await this.provider.getNetwork()).chainId
      if (execute_chainID !== token_chainID) { // make sure user doesn't send into false network for sum bug reason
        throw new Error('You selected wrong network in MetaMask. Make sure you selected '+TOKEN_NETWORKNAME+' Mainnet Chain network and refresh the page')
      }

      const ptx = await VERIFIER.populateTransaction.submitSignaturePackage(_tx.original_txID,_tx.recipient,_tx.amount,_tx.fee,_tx.event_type,_tx.chainID,_tx.signatures) 

      let tx = null
      try {
        tx = await this.signer.sendTransaction(ptx)
      } catch (error) {
        this.current_withdrawal = null
        console.error("a20"+error)
      }
      
      if (tx) {
        this.loading_request = true
        // this.inbetweenstatus = "Sending Signature Package to "+TOKEN_NETWORKNAME+" Network"
        // this.inbetweenstatus = "Sending USDT withdrawal to "+TOKEN_NETWORKNAME+" Network"
        this.inbetweenstatus = "Unlocking USDT in "+TOKEN_NETWORKNAME+" Network"

        let receipt = await tx.wait()

        this.inbetweenstatus = ''
        this.successMessage = 'Success! Save transaction hashes below'

        this.hashes2 = {
            "txHashCollect": _tx.original_txID,
            "txHashDispense":  receipt.transactionHash
          }

        this.loading_request = false
        this.inputAmount = ''
        //  reset the amount ?
        // this.triggerinbetweentracking3("etho",tx)

        // console.log("--->receipt:",_receipt)
        // console.log("token:",provider_TOKEN.blockNumber)
        // console.log("etho:",provider_ETHO.blockNumber)

        // this.inbetweenstatus = "Unlocking USDT on "+TOKEN_NETWORKNAME+" Network"

        await this.loadContractInfo()

        this.resetWithdrawalLoader()
        this.loadWithdrawals()


        // return receipt.transactionHash


        //const ptx = {
        //  from: this.wallet,
        //  to: address_BA
        //}

        // ptx.value = this.amountBN
        // console.log({
        //   ptx,
        //   amt: this.amountBN.toString(),
        //   bal: this.currentBalanceETHO.toString(),
        // })

        // let tx = await this.signer.sendTransaction(ptx)

        // this.triggerinbetweentracking(tx)

        // let receipt = await tx.wait()

        // this.triggerinbetweentracking2("etho",tx)

        // await this.loadContractInfo()

        // console.log("tx",tx)
        // console.log("receipt.transactionHash",receipt.transactionHash)
      }

    },
    hexToBytes(hex) {
      let bytes = [];
      for (let c = 0; c < hex.length; c += 2)
          bytes.push(parseInt(hex.substr(c, 2), 16));
      return bytes;
    },
    async handleAccountsChanged() {
      // console.log("A HANDLE ACCOUNT CHANGED")

      this.balanceNumStr_Ether1 = ""
      this.balanceNumStr_TKN = ""
      this.currentBalanceETH = ""
      this.feeBNStr = ""
      this.minTKNStr = ""
      this.currentBalanceETHO = ""

      this.warningMessage = '';
      this.hintMessage = '';
      this.successMessage = '';

      this.signer = this.provider.getSigner()
      this.wallet = await this.signer.getAddress()
      //// console.log("this.wallet",this.wallet)
      await this.loadContractInfo()
    },
    async loadControllerInfo() {
      this.loading_controllerInfo = false


      if (this.etho_price == -1 || ((this.last_etho_price-Date.now())/1000) > 15) { // more than 30 seconds passed
        this.etho_price = await this.getEthoPrice()
        // this.total_cubits = await this.getTotalCubits()
      }

      
      
      // console.log("etho_price",etho_price)
      if (this.etho_price !== -1) {
        this.last_etho_price = Date.now()
        try {
          let  ETH_TO_ETHO_current_fee = BigNumber.from(parseInt(ETH_FEE_WITHDRAWAL *100) +"0000000000000000"); 
          let  ETHO_TO_ETH_current_fee = BigNumber.from(parseInt(ETHO_ETH_FEE_DEPOSIT*100) +"0000000000000000"); 
          // console.log("ETHO_TO_ETH_current_fee",ETHO_TO_ETH_current_fee)
          let  ETHO_TO_ETH_current_minimum = ETHO_TO_ETH_current_fee.add( BigNumber.from(parseInt(ETHO_ETH_MIN_DEPOSIT *100) +"0000000000000000"));
          let  ETH_TO_ETHO_current_minimum = ETH_TO_ETHO_current_fee.add( BigNumber.from(parseInt(ETH_MIN_WITHDRAWAL*100) +"0000000000000000"));
          // let  ETHO_TO_ETH_current_minimum = ETHO_TO_ETH_current_fee;
          // let  ETH_TO_ETHO_current_minimum = ETH_TO_ETHO_current_fee;

          this.paramsInfo = {
            "minETH":ETHO_TO_ETH_current_minimum,
            "minETHO":ETH_TO_ETHO_current_minimum,
          }
          this.costsInfo = {
            "WETHOtoETHO": ETH_TO_ETHO_current_fee,
            "ETHOtoWETHO": ETHO_TO_ETH_current_fee
          }


        } catch (error) {
          this.warningMessage = 'Could not load info from controller. Usage is blocked. Try refreshing the page, try later or contact support'
          console.error("a10"+error)
        }


      }

      // this.pending_withdrawals = []
      // this.current_withdrawal = null
      // this.loading_withdrawals_active = false
      // this.loadWithdrawals()

     
      
    },

    async updateBalances() {
      // let BURNED_AMOUNT_ETH = "9180703289000000000000000"

      // var locked_ETHO_Amount = await provider_ETHO.getBalance(address_BA);
      var locked_ETHO_Amount = await TKN_STORAGEPROTOCOL.totalUSDT();
      this.currentLocked_ETHO = BigNumber.from(locked_ETHO_Amount) //.sub(BURNED_AMOUNT_ETH);

      var locked_ETH_Amount = await TKN_ETH_NETWORK.balanceOf(address_TKN_receiver); //await provider_ETHO.getBalance(address_BA);
      this.currentLocked_ETH = BigNumber.from(locked_ETH_Amount) //.sub(BURNED_AMOUNT_ETH);

      // ETH BALANCE
      // var balanceAmount = await TKN.balanceOf(this.wallet);
      // this.currentBalanceETH = balanceAmount.toString()

      // ETHO BALANCE
      // var thisBalance = await this.signer.getBalance();
      // this.currentBalanceETHO = thisBalance.toString()

      await this.loadTKNBalance();
      await this.loadEther1Balance();

      // let combinedETHO = BigNumber.from(this.currentLocked_ETH).sub(BURNED_AMOUNT_ETH)
      // console.log("currentLocked_ETH22",BigNumber.from(this.currentLocked_ETH),BigNumber.from(this.currentLocked_ETHO))
      if (this.currentLocked_ETHO.lte(this.currentLocked_ETH)) {
      // if (this.currentLocked_ETHO.lte(this.currentLocked_ETH)) {
        this.bridge_status = "OK"
      } else {
        this.bridge_status = "FAILED!"
      }

      this.etho_price = await this.getEthoPrice() // update price changes also...
    },

    async loadContractInfo() {

      await this.updateBalances();

      if(this.directionIndicatorEthoToEth) {
        try {
          //const BAO = new Contract(address_BAO, USDT_STORAGEPROTOCOL_TOKEN.abi, this.signer)
          //var lockedAmount = await BAO.entryOf(this.wallet);
          //var locked_ETH_Amount = await providerO.getBalance(address_BA);
          //var thisBalance = await this.signer.getBalance();
          //var thisBalance = "30073900000000000000000";
          
          //const res = await Promise.all([BAO.entryOf(this.wallet), this.signer.getBalance()])

          // this.currentBalance = this.currentBalanceETHO;
          // console.log("this.currentBalance",this.currentBalance);
          // console.log("this.currentBalance2",BigNumber.from(this.currentBalance));
          // console.log("this.minBNStr",this.minBNStr);
          //this.restoreInputAmount(BNStrToNumstr(this.currentBalance))
          ////// console.log("thisBalance",thisBalance,"this.minBNStr",this.minBNStr,"this.minTKNStr",this.minTKNStr,"this.currentLocked_ETHO",this.currentLocked_ETHO,"this.currentLocked_ETH",this.currentLocked_ETH)

          if (this.currentBalanceETHO.lt(this.minBNStr) && this.inbetweenstatus != '') this.warningMessage = 'Your '+`${MAINNET_NETWORK_TICKER}`+' balance is lower than minimum amount. Usage is blocked'
          
          // await this.loadTKNBalance()
        } catch (error) {
          console.error("a11"+error)

          this.warningMessage = 'Could not load info from contract. Usage is blocked. Try refreshing the page, try later or contact support'
        }
      }
      else if(this.directionIndicatorEthToEtho) {
        try {
          //const TKN = new Contract(address_TKN, Token.abi, this.signer)
          //// console.log("This Wallet: " + this.wallet);
          //var burnedAmount = await TKN.balanceOf(this.wallet) // FALSE !!! //await TKN.allowance(this.wallet, address_BA);
          //var balanceAmount = await TKN.balanceOf(this.wallet);
          //const res = await Promise.all([TKN.allowance(this.wallet, address_BA), TKN.balanceOf(this.wallet)])
          ////// console.log(res0);



          //this.currentBurned = burnedAmount.toString();
          ////// console.log(this.currentBurned);
          ////// console.log("balanceAmount",balanceAmount.toString());
          // this.currentBalance = this.currentBalanceETH.toString()
          //this.restoreInputAmount(BNStrToNumstr(this.currentBalance))
          //// console.log(this.currentBalance);
          //// console.log("this.minTKNStr",this.minTKNStr)
          //// console.log("this.currentLocked_ETHO",this.currentLocked_ETHO)
          //// console.log("balanceAmount",balanceAmount.toString())
          if (this.currentLocked_ETH.lt(this.minTKNStr) && this.inbetweenstatus != '') this.warningMessage = 'Your '+`${TOKEN_NETWORK_TICKER}`+' balance is lower than minimum amount. Usage is blocked'
          // else if ((this.currentLocked_ETHO.add(numstrToBN(this.inputAmount))).lt(this.currentLocked_ETH)) {
          //   this.hintMessage = 'Your ERC-20 '+`${TOKEN_NETWORK_TICKER}`+' balance is higher than the locked amount.'
          // }

          // await this.loadEther1Balance()
        } catch (error) {
          this.warningMessage = 'Could not load info from contract. Usage is blocked. Try refreshing the page, try later or contact support'
        }
        // this.loadWithdrawals()
      }
      
    },
    async get_signatures(_nr) {
      let last_transaction = await SGNR.GetFullTransactionData(_nr,"") // SGNR_ETH and SGNR_BSC are same contract

      let signers = []
      let messageHash = ""
      let ethSignedMessageHash = ""

      if (last_transaction["sigs"] !== undefined) {
        let sigs = last_transaction["sigs"]
        messageHash = await SGNR.getMessageHash(last_transaction["transaction"]["to"], last_transaction["transaction"]["amount"], last_transaction["transaction"]["fee"], last_transaction["transaction"]["tx_id"], last_transaction["transaction"]["event_name"], last_transaction["transaction"]["target_network"]);
        ethSignedMessageHash = await SGNR.getEthSignedMessageHash(messageHash);
        console.log("ethSignedMessageHash",ethSignedMessageHash)

        for (var i3 = 0; i3 < sigs.length; i3++)
        {
          let _signer = await SGNR.recoverSigner(ethSignedMessageHash, sigs[i3])
          if (Array.isArray(signers) && !signers.includes(_signer)) {
            signers.push(_signer)
          } else {
            console.log("DUPLICATE SIGNER or INVALID:",_signer)
          }
        }
        console.log("ALL SIGNERS:",signers)

      } 
      return signers;
     

    },
    async loadWithdraw(_nr) {
      if (_nr > 0) {
        let last_transaction = await SGNR.GetFullTransactionData(_nr,"") // SGNR_ETH and SGNR_BSC are same contract
        if (last_transaction["transaction"]["tx_id"] == "") {
          return null
        }

        console.log(last_transaction["transaction"]["event_name"])

        if (last_transaction["transaction"]["event_name"].startsWith("Set")) {
          return null
        }

        if (last_transaction["transaction"]["target_network"] == token_chainID && last_transaction["transaction"]["event_name"] == "burn") {
          console.log("CHECK FOR WITHDRAWAL!")

          console.log("last_transaction",last_transaction)
          console.log("last_transaction",last_transaction["transaction"]["tx_id"])
          console.log("last_transaction",last_transaction["transaction"]["to"],this.wallet,last_transaction["transaction"]["to"] ==this.wallet)

          if (last_transaction["transaction"]["to"] == this.wallet) {
            let withdrawal_tx_id = await SGNR.validated_transactions(last_transaction["transaction"]["tx_id"]);
            console.log("withdrawal_tx_id",withdrawal_tx_id)
            let withdrawal_status = -1
            if (withdrawal_tx_id != 0) {
              let validated_tx = await VERIFIER.validated_transactions(last_transaction["transaction"]["tx_id"]);
              console.log("validated_tx",validated_tx)
              let this_status = last_transaction["transaction"]["status"] == "EXECUTED" ? "WITHDRAW" : "WAITING"
              let _activity = last_transaction["transaction"]["status"] == "EXECUTED" ? true : false

              if (last_transaction["sigs"] !== undefined) {
                let multisig_required_amount = await SGNR.getRequired()

                console.log("last_transaction sigs",last_transaction["sigs"])

                let _signatures = last_transaction["sigs"].map(sig => this.hexToBytes(sig.slice(2)))

                console.log("_signatures",_signatures)

                console.log("multisig_required_amount",multisig_required_amount)
                console.log("validated_tx",validated_tx)
                if (validated_tx == 0x0000000000000000000000000000000000000000) {
                  console.log("CAN BE WITHDRAWN")

                  let signers = []
                  let messageHash = ""
                  let ethSignedMessageHash = ""

                  if (last_transaction["sigs"] !== undefined) {
                    let sigs = last_transaction["sigs"]
                    messageHash = await SGNR.getMessageHash(last_transaction["transaction"]["to"], last_transaction["transaction"]["amount"], last_transaction["transaction"]["fee"], last_transaction["transaction"]["tx_id"], last_transaction["transaction"]["event_name"], last_transaction["transaction"]["target_network"]);
                    ethSignedMessageHash = await SGNR.getEthSignedMessageHash(messageHash);
                    console.log("ethSignedMessageHash",ethSignedMessageHash)
                    signers = []
                    
                    for (var i3 = 0; i3 < sigs.length; i3++)
                    {
                      let _signer = await SGNR.recoverSigner(ethSignedMessageHash, sigs[i3])
                      signers.push(_signer)
                    }
                    console.log("ALL SIGNERS:",signers)
                  }

                  let amt_req_multi = 0
                  if (this_status == "WITHDRAW") { // if this is an "older" required amount frmo before an onchain change, let's use the prev max.
                    amt_req_multi = this.count_signatures(signers) + " / " + this.count_signatures(signers)
                  } else {
                    amt_req_multi = this.count_signatures(signers) + " / " + multisig_required_amount
                  }

                  withdrawal_status = 1
                  return {
                    original_txID: last_transaction["transaction"]["tx_id"],
                    signatures: _signatures,
                    signatures_required: multisig_required_amount,
                    amount: last_transaction["transaction"]["amount"].toString(), 
                    event_type: "burn",
                    signature_count: amt_req_multi,
                    chainID: last_transaction["transaction"]["target_network"].toString(),
                    fee: 0,
                    activity: _activity,
                    status: this_status,
                    receipt: EXPLORER_ETHO_TX + last_transaction["transaction"]["tx_id"],
                    recipient: last_transaction["transaction"]["to"]
                  }
                } else {
                  console.log("ALREADY WITHDRAWN")
                  withdrawal_status = validated_tx
                }
              }
            }

            console.log("withdrawal_tx_id",withdrawal_tx_id)
            console.log("withdrawal_status",withdrawal_status)
          } else {
            console.log("NOT MY USDT RECEIVE WALLET!",last_transaction["transaction"]["to"],this.wallet)
          }



        }
      }
     
    },
    async resetWithdrawalLoader() {
      this.pending_withdrawals = []
      this.current_withdrawal = null
      this.loading_withdrawals_active = false

    },
    async loadWithdrawals() { // does this only load receipts for the tokens? BUT NOT FOR CUBIT -> sUSDT ? // 2024

      if (!this.loading_withdrawals_active && this.pending_withdrawals.length == 0) { // only reload if withdarwal pending list is empty!
        this.loading_withdrawals_active = true;
        // this.pending_withdrawals = []
        this.loading_withdrawals_count = ""

        console.log("etho_multisi_transactions ETH",SGNR)
        let max_transactions_eth = await SGNR.transaction_i()
        
        console.log("max_transactions_eth",max_transactions_eth)

        // var max_lookup = 50; // max amount of "valid" txs, like burn or mint, that we add before breaking
        var max_add = 25; // max amount of "valid" txs, like burn or mint, that we add before breaking
        var add_count = 0; // max amount of "valid" txs, like burn or mint, that we add before breaking

        let start_count = max_transactions_eth - max_add
        
        for (let i = start_count; i < max_transactions_eth; i++) { // let's add alternating receipts
          // this.loading_withdrawals_count ++
          this.loading_withdrawals_count = (i-start_count).toString() + " / " + (max_transactions_eth-start_count).toString()
          if (add_count > max_add) {
            break; // break our final loop if we hit max tx count
          }
          let _tx = await this.loadWithdraw(max_transactions_eth-i)
          // console.log("_tx",_tx)
          if (_tx != null) {
            this.pending_withdrawals.push(_tx)
            return // avoid loading multiple ?
          }
        
        }
        this.loading_withdrawals_active = false
      }
    

        // add_count += 1;
        // if (last_transaction["transaction"]["target_network"]  == token_chainID) {
        //   this.addReceipt(last_transaction,EXPLORER_ETH_TX,EXPLORER_ETH_ADDRESS,multisig_required_amount,max_transactions_eth-i)
        // } else {
        //   this.addReceipt(last_transaction,EXPLORER_BSC_TX,EXPLORER_BSC_ADDRESS,multisig_required_amount,max_transactions_eth-i) 
        // }
       

    },
    async findTransactionIndexFromTX(_txID) {
      return new Promise((resolve) => {
          SGNR.transaction_i().then(async (max_transactions_eth) => {
              var max_check = 25;
              let start_count = max_transactions_eth - max_check;

              for (let i = start_count; i < max_transactions_eth; i++) {
                  let _nr = max_transactions_eth - i;
                  let last_transaction = await SGNR.GetFullTransactionData(_nr, "");

                  if (last_transaction["transaction"]["tx_id"] == _txID) {
                      resolve(_nr);
                      return;
                  }
              }

              resolve(0);
          }).catch((error) => {
              resolve(error); // or reject(error) if you want to handle errors separately
          });
      });
    }
  },
};
</script>
