<!-- <template>
<div>

  <div class="copyright-section index-footer bg-light">
    <center>
      <a href="https://www.facebook.com/Ether1Project/" target="_blank" class="social-link"> <i class="fa fa-facebook"></i> </a>
      <a href="https://twitter.com/EthoProtocol" target="_blank" class="social-link"> <i class="fa fa-twitter" style="padding:10px;"></i> </a>
      <a href="https://www.instagram.com/ether1official/" target="_blank" class="social-link"> <i class="fa fa-instagram" style="padding:10px;"></i> </a>
      <a href="https://github.com/Ether1Project" target="_blank" class="social-link"> <i class="fa fa-github" style="padding:10px;"></i> </a>
      <a href="https://play.google.com/store/apps/details?id=com.ether1.trust.wallet" target="_blank" class="social-link"> <i class="fa fa-android" style="padding:10px;"></i> </a>
      <a href="https://t.me/Ether_1" target="_blank" class="social-link"> <i class="fa fa-telegram" style="padding:10px;"></i> </a>
      <a href="https://old.reddit.com/r/etho1/" target="_blank" class="social-link"> <i class="fa fa-reddit" style="padding:10px;"></i> </a>
      <a href="https://ethoprotocol.medium.com" target="_blank" class="social-link"> <i class="fa fa-medium" style="padding:10px;"></i> </a>
      <a href="https://www.youtube.com/channel/UC3nvSLrJq3m32v_oVHLwFng/" target="_blank" class="social-link"> <i class="fa fa-youtube" style="padding:10px;"></i> </a>
      <a href="https://discordapp.com/invite/MFn9Tmz" target="_blank" class="social-link"> <i class="fa fa-commenting" style="padding:10px;"></i> </a>
    </center>
    <p>© 2022 The ETHO Protocol | Ether-1 All Rights Reserved.</p>
  </div>

  <div class="tap-top">
    <div>
      <i class="fa fa-angle-double-up"></i>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'Footer',
};
</script> -->

<template>
  <!-- <div>
  
    <div class="copyright-section index-footer bg-light">
      <center>
        <a href="https://www.facebook.com/Ether1Project/" target="_blank" class="social-link"> <i class="fa fa-facebook"></i> </a>
        <a href="https://twitter.com/EthoProtocol" target="_blank" class="social-link"> <i class="fa fa-twitter" style="padding:10px;"></i> </a>
        <a href="https://www.instagram.com/ether1official/" target="_blank" class="social-link"> <i class="fa fa-instagram" style="padding:10px;"></i> </a>
        <a href="https://github.com/Ether1Project" target="_blank" class="social-link"> <i class="fa fa-github" style="padding:10px;"></i> </a>
        <a href="https://play.google.com/store/apps/details?id=com.ether1.trust.wallet" target="_blank" class="social-link"> <i class="fa fa-android" style="padding:10px;"></i> </a>
        <a href="https://t.me/Ether_1" target="_blank" class="social-link"> <i class="fa fa-telegram" style="padding:10px;"></i> </a>
        <a href="https://old.reddit.com/r/etho1/" target="_blank" class="social-link"> <i class="fa fa-reddit" style="padding:10px;"></i> </a>
        <a href="https://ethoprotocol.medium.com" target="_blank" class="social-link"> <i class="fa fa-medium" style="padding:10px;"></i> </a>
        <a href="https://www.youtube.com/channel/UC3nvSLrJq3m32v_oVHLwFng/" target="_blank" class="social-link"> <i class="fa fa-youtube" style="padding:10px;"></i> </a>
        <a href="https://discordapp.com/invite/MFn9Tmz" target="_blank" class="social-link"> <i class="fa fa-commenting" style="padding:10px;"></i> </a>
      </center>
      <p>Copyright © 2023. All rights reserved. ERPCX is a registered trademark of ERPCX International, Inc.
  2234 North Federal Highway, Suite 450, Boca Raton, FL, 33431, USA. Phone +1 561 810 1650. Email: support@erpcx.com.</p>
    </div>
  
  
    <div class="tap-top">
      <div>
        <i class="fa fa-angle-double-up"></i>
      </div>
    </div>
  
  </div> -->
  
  
    <footer class="footer footer-fullwidth">
        
        <div class="footer-info">
      
            <div class="row align-items-center">
              <div class="pt-0 px-2 py-0 m-3">
                  <p class="text-white">

                    Copyright © {{copyrighyear}} Storageprotocol.
            
                    <!-- All rights reserved. ERPCX is a registered trademark of ERPCX International, Inc. <br>2234 North Federal Highway, Suite 450, Boca Raton, FL, 33431, USA. Email: support@erpcx.com.	 -->
                    <!-- Copyright ©Storage Protocol 2023. All rights reserved. <br>support@storageprotocol.com.								 -->

                  </p>

                  
              </div>
              <div class="col-lg-2 col-12" style="text-align:center;margin-left: auto;margin-right: calc(50% - 162px);">
                <!-- <img :src="require('@/assets/images/erpcx/siteseal_gd_3_h_l_m.gif')"/> -->
                <v-btn block class="metamask_button main_color_header text-white" @click="AddMetamask()">Add Storage Protocol</v-btn>
              </div>
            </div>
      
      </div>
  
      <div class="tap-top">
        <div>
          <i class="fa fa-angle-double-up"></i>
        </div>
      </div>
    </footer>
  </template>
  <script>
  export default {
    name: 'Footer',
    data() {
    return {
        copyrighyear: 2023,
      }
    },
    async mounted() {
      this.copyrighyear = new Date().getFullYear();
    },
    methods: {
      AddMetamask() {
        window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [{
            chainId: "0x19105284",
            rpcUrls: ["https://rpc.storageprotocol.com/"],
            chainName: "StorageProtocol Mainnet",
            nativeCurrency: {
              name: "CUBITS",
              symbol: "CUBITS",
              decimals: 18
            },
            blockExplorerUrls: ["https://explorer.storageprotocol.com/"]
          }]
        });
        return true
      }
    }

  };


  </script>

  

