<template >
    <v-container fluid>

      <v-card v-if="past_transactions.length == 0" class="mt-4">
        <v-card-text>
          <strong>There are no Bridge Transactions yet.</strong>
        </v-card-text>
      </v-card>

      <v-card class="mt-4 pb-2 pt-2">
        <p class="m-0 p-1">All Transactions are verified through Multisig Contracts: </p>
        ETH SIGNER:
        <a icon :href="`${EXPLORER_ETHO_ADDRESS}${address_SIGNER_ETH}`" target="_blank" rel="noopener noreferrer">{{address_SIGNER_ETH}} </a>
        ETH VERIFIER:
        <a icon :href="`${EXPLORER_ETH_ADDRESS}${address_VERIFIER_ETH}`" target="_blank" rel="noopener noreferrer">{{address_VERIFIER_ETH}} </a>
        <br> 
        BSC SIGNER: 
        <a icon :href="`${EXPLORER_ETHO_ADDRESS}${address_SIGNER_BSC}`" target="_blank" rel="noopener noreferrer">{{address_SIGNER_BSC}} </a>
        BSC VERIFIER: 
        <a icon :href="`${EXPLORER_BSC_ADDRESS}${address_VERIFIER_BSC}`" target="_blank" rel="noopener noreferrer">{{address_VERIFIER_BSC}} </a>
        <p class="m-0 p-1"> Current Multisig Signatures:
        <!-- {{ MULTISIGOWNERS  }} -->
        <a v-for="item in MULTISIGOWNERS" :key="item">
          <a icon :href="`${EXPLORER_ETHO_ADDRESS}${item}`" target="_blank" rel="noopener noreferrer">{{ getMultisigName(item) }} </a>
        </a> </p>

      </v-card>

      <v-card v-if="past_transactions.length > 0" class="mt-4">
       
        <v-data-table :headers="headers" :items="past_transactions" height="34vh">
        <!-- <v-data-table :items="past_transactions" height="34vh"> -->

          <!-- <template v-slot:item.target_network="{ item }">
            {{item.target_network == 0x14095a ? "ETHO" : item.target_network == 0x1 ? "ETH" : "BSC"}}
          </template> -->

          <template v-slot:item.amount="{ item }">
            <b>{{BNStrToNumstr(item.amount,1)}}</b> $
          </template>

          <template v-slot:item.fee="{ item }">
            <b>{{BNStrToNumstr(item.fee,1)}}</b>  $
          </template>

          <template v-slot:item.event_name="{ item }">
            <img :src='"../assets/images/storageprotocol/cubits_black_256.png"' style="height: 28px; object-fit: contain;margin-right:3px; " v-if="item.target_network == mainnet_chainID"/>
            <img :src='"../assets/images/storageprotocol/eth-logo.svg"' style="height: 28px; object-fit: contain;margin-right:10px;margin-left:3px;" v-if="item.target_network == token_chainID"/>
            <img :src='"../assets/images/storageprotocol/bsclogo.png"' style="height: 28px; object-fit: contain;margin-right:3px;margin-left:0px;" v-if="item.target_network == token_chainID2"/>

            <b>{{item.event_name.toUpperCase()}}</b> {{item.target_network == token_chainID ? 'ERC-20' : item.target_network == token_chainID2 ? 'BEP-20' : 'SRC-20'}}
          </template>
          

          <!-- <template v-slot:item.signature_count="{ item }">
            {{item.signature_count}}
          </template> -->


          <template v-slot:item.target_network="{ item }">
            <!-- <img :src='"../assets/images/etho/eth-logo.svg"' style="height: 20px; object-fit: contain;" /> -->
            <!-- <img :src='"../assets/images/etho/erc20.jpg"' style="height: 30px; object-fit: contain;" />
            {{item.target_network == 0x14095a ? "ETHO" : item.target_network == 0x1 ? "ETH" : "BSC"}} -->

            <img :src='"../assets/images/storageprotocol/sUSDT_to_tether_swap_256.png"' style="height: 19px; object-fit: contain;" v-if="item.target_network != mainnet_chainID"/>
            <img :src='"../assets/images/storageprotocol/tether_to_sUSDT_swap_256.png"' style="height: 19px; object-fit: contain;" v-if="item.target_network == mainnet_chainID"/>


            <!-- <img :src='"../assets/images/storageprotocol/tether_to_sUSDT_swap_256.png"' style="height: 30px; object-fit: contain;" v-if="item.target_network == token_chainID"/> -->
            <!-- <img :src='"../assets/images/storageprotocol/tether_to_sUSDT_swap_256.png"' style="height: 30px; object-fit: contain;" v-if="item.target_network == token_chainID"/> -->


            <!-- {{item.target_network}} -->
          </template>


          <template v-slot:item.status="{ item }">

            <img :src='"../assets/images/etho/hourglass3.png"' style="height: 25px;margin-top:-2px; object-fit: contain;" v-if="item.status == 'WAITING'"/>
            <img :src='"../assets/images/etho/check.png"' style="height: 24px;margin-top:-4px; object-fit: contain;" v-if="item.status == 'EXECUTED'"/>
            <img :src='"../assets/images/etho/close.png"' style="height: 27px;margin-top:-3px; object-fit: contain;" v-if="item.status == 'FAILED'"/>
            <!-- <a style="margin-left:11px;" v-b-tooltip.hover title="`${item.signature_names}`">{{item.signature_count}}</a>  -->
            <a style="margin-left:11px;" :id="`tooltip_${item.tx_id.substr(0,8)}`">{{item.signature_count}}</a> 
            <b-tooltip :target="`tooltip_${item.tx_id.substr(0,8)}`" triggers="hover">
              {{item.signature_names}}
            </b-tooltip>
          </template>

          <template v-slot:item.tx_id="{ item }">

            <!-- <v-btn icon style="color:black;" class="px-1" :href="`${item.receipt}`" target="_blank" rel="noopener noreferrer" v-if="item.target_network == 0x1">{{item.tx_id.substr(0,5)}}...{{item.tx_id.substr(-3)}}</v-btn>
            <v-btn icon style="color:black;" class="px-1" :href="`${item.receipt}`" target="_blank" rel="noopener noreferrer" v-if="item.target_network == 0x38">{{item.tx_id.substr(0,5)}}...{{item.tx_id.substr(-3)}}</v-btn>
            <v-btn icon style="color:black;" class="px-1" :href="`${item.receipt}`" target="_blank" rel="noopener noreferrer" v-if="item.target_network == 0x14095a">{{item.tx_id.substr(0,5)}}...{{item.tx_id.substr(-3)}}</v-btn> -->

            <a icon style="color:black;" class="px-1" :href="`${item.receipt}`" target="_blank" rel="noopener noreferrer">{{item.tx_id.substr(0,5)}}...{{item.tx_id.substr(-3)}}</a>
            <!-- <a icon style="color:black;" class="px-1" :href="`${item.receipt}`" target="_blank" rel="noopener noreferrer">{{item.tx_id}}</a> -->


          </template>

          <template v-slot:item.to="{ item }">
            
            <a icon style="color:black;" class="px-1" :href="`${item.address}`" target="_blank" rel="noopener noreferrer">{{item.to}}</a>
            <!-- <b><a icon style="color:black;" class="px-1" :href="`${item.address}`" target="_blank" rel="noopener noreferrer">{{item.to}}</a></b> -->
            <!-- <a icon style="color:black;" class="px-1" :href="`${item.address}`" target="_blank" rel="noopener noreferrer">{{item.to.substr(0,5)}}...{{item.to.substr(-3)}}</a> -->

          </template>


          <!-- <template v-slot:item.amount="{ item }">
            <v-btn style="width:105px">{{item.amount}}</v-btn>
          </template> -->

          
              <!-- <template v-slot:item.contractName="{ item }">


                      <v-btn :href="'//data.ethoprotocol.com/ipfs/' + item.mainContentHash" x-small target="_blank" style="width:105px">{{getShortContractName2(item.hostingContractName)}}</v-btn>


              </template>
              <template v-slot:item.contractStorageUsed="{ item }">

                  <v-row justify="center" align="center">
                    {{ getStorageSize(item.contractStorageUsed) }}

                  </v-row>

              </template>

              <template v-slot:item.hostingReplicationFactor="{ item }" style="margin-right:2px">


                      <v-row justify="center" align="center"> 

                              {{ item.hostingReplicationFactor }}


                      </v-row>

              </template>

              <template v-slot:item.ExpirationTime="{ item }">
  
                  <input type="date" name="dateofcontract" :min="getExpirationDateMin(item)" :max="getmaxDate()" :value="ethofs.getExpirationDate(item)" @change="showConfirmExtension(item,$event)">

              </template>


              <template v-slot:item.contractActions="{ item }">
                  <v-row justify="center" align="center">
                    <v-icon light @click="openDownload(item.mainContentHash)">mdi-download</v-icon>
                    <v-icon light @click="showSetOwner(item)">mdi-account</v-icon>
                    <v-icon light @click="showConfirmRemoval(item)">mdi-delete</v-icon>
                  </v-row>
            </template>
    -->
            
            </v-data-table>


      </v-card>


      <v-card class="mt-4 pb-4">
        <br> Multisig Wallet (ETHO NETWORK): <a icon :href="`${EXPLORER_ETHO_ADDRESS}${DEV_WALLET_ETHO}`" target="_blank" rel="noopener noreferrer">{{DEV_WALLET_ETHO}} </a>
        <br> Multisig Wallet (ETH NETWORK): <a icon :href="`${EXPLORER_ETH_ADDRESS}${DEV_WALLET_ETH}`" target="_blank" rel="noopener noreferrer">{{DEV_WALLET_ETH}} </a>
        <br> Multisig Wallet (BSC NETWORK): <a icon :href="`${EXPLORER_BSC_ADDRESS}${DEV_WALLET_BSC}`" target="_blank" rel="noopener noreferrer">{{DEV_WALLET_BSC}} </a>
      </v-card>

    </v-container>
</template>
<script>
/* eslint-disable camelcase */
//import Vue from 'vue'
import { Contract, providers, BigNumber } from 'ethers'
// import Axios from 'axios'
//import firebase from 'firebase/app'
//import 'firebase/firestore'
import BridgeAssistO from '/abis/Token.json'
// import Verifier from '/abis/MultiSig_USDT_Owner.json'
import Token from '/abis/ERC20Basic.json'
// import Oracle from '/abis/oracle_contract.json'
import Signer from '/abis/MultiSig_Controller.json'

//const address_BAO = '0x22a2a6ac2b0c26023353077e6a5572503ec053a5' // 3DFA1F055CA9F5ACBE6FD6A8B07A89EAC0EDE6244EA7953AC8D1D2B50F652A03 0x22a2a6ac2b0c26023353077e6a5572503ec053a5
const address_TKN = '0x0b5326Da634f9270FB84481DD6F94d3dC2cA7096'; //'0xb2aE079a9A39b70a1Fcc6403926490d5933ed71E' //'0x99676c9fa4c77848aeb2383fcfbd7e980dc25027'
const address_BA = '0x370f083A3c4794DDBb49cfB4E7C7e09B37d57545'; // 3DFA1F055CA9F5ACBE6FD6A8B07A89EAC0EDE6244EA7953AC8D1D2B50F652A03 0x22a2a6ac2b0c26023353077e6a5572503ec053a5
// const address_ORCL = '0x1509c8F22b7a14bab4EA829324BFcc44371A5667';
const address_SIGNER_ETH = '0x9990000000000000000000000000000123456789';
const address_VERIFIER_ETH = '0x63e5950a52EC412cf3C54856963E8f67aa57D605';
const address_MULTISIG_ETH = '0xdacbF5Ad9e86aC93eded4c302f3246CBf1327B92';
const address_SIGNER_BSC = '0x9990000000000000000000000000000123456789';
const address_VERIFIER_BSC = '0x63e5950a52EC412cf3C54856963E8f67aa57D605';
const address_MULTISIG_BSC = '0xdacbF5Ad9e86aC93eded4c302f3246CBf1327B92';
// const address_SIGNER_authority = "0xfb7f3074B16E5B2eF73C775ADCD3bf48B8cFF959" ; //"0xcaded6e8b266b9fb5781506885283552387c748b"; //'0xB5e919EAfCF60932060Cd4A9d9b9568736CDFBf0'; // transmitter client for receipts

const DEV_WALLET_ETHO = '0x9999999999999999999999999999999999999999';
const DEV_WALLET_ETH = '0xdacbF5Ad9e86aC93eded4c302f3246CBf1327B92';
const DEV_WALLET_BSC = '0x1509c8F22b7a14bab4EA829324BFcc44371A5667';

const TOKEN_CONFIRMATIONS = 12;
const ETHO_CONFIRMATIONS = 22;

// const ETH_LOGO = "../assets/images/etho/eth-logo.svg";
// console.log("ETH_LOGO",ETH_LOGO)
const EXPLORER_ETHO_TX = "https://explorer.storageprotocol.com/tx/";
const EXPLORER_ETHO_ADDRESS = "https://explorer.storageprotocol.com/address/";
const EXPLORER_ETH_ADDRESS = "https://goerli.etherscan.io/address/";
const EXPLORER_BSC_ADDRESS = "https://bscscan.com/address/";
// const EXPLORER_ETHO_TX = "https://testnetexplorer2.ethoprotocol.com/tx/";
//const EXPLORER_ETH_TX = "https://testnet.bscscan.com/tx/";
//const EXPLORER_ETH_TX = "https://ropsten.etherscan.io/tx/";
const EXPLORER_ETH_TX = "https://goerli.etherscan.io/tx/";
const EXPLORER_BSC_TX = "https://bscscan.com/tx/";
// const EXPLORER_ETH_TX = "https://sepolia.etherscan.io/tx/";

// const providerO = new providers.JsonRpcProvider('https://rpc.ethoprotocol.com')

//const token_rpc_url = "https://ropsten.infura.io/v3/ad333b804b7c4892a5826cfcc09db9ba";

//const mainnet_rpc_url = "https://rpc.ethoprotocol.com";
const token_chainID = 5; //"0x3";
const token_chainID2 = 0x38; //"0x3";
const mainnet_chainID = 420500100; //"0x14095a";

//const provider_TOKEN = new providers.InfuraProvider(3, 'ad333b804b7c4892a5826cfcc09db9ba');
// const provider_TOKEN = new providers.InfuraProvider(11155111, '9aa3d95b3bc440fa88ea12eaa4456161');
const provider_TOKEN = new providers.JsonRpcProvider('https://goerli.infura.io/v3/a289a5a7fb754ae8aa8fa1915f87eda0');
const provider_ETHO = new providers.JsonRpcProvider('https://rpc.storageprotocol.com');
//const provider_TOKEN = new providers.JsonRpcProvider('https://bsc-dataseed.binance.org/');
//const provider_TOKEN = new providers.JsonRpcProvider('https://data-seed-prebsc-1-s1.binance.org:8545/')

const TKN = new Contract(address_TKN, Token.abi, provider_TOKEN)
// const VERIFIER = new Contract(address_VERIFIER_ETH, Verifier.abi, provider_TOKEN)
// const ORCL = new Contract(address_ORCL, Oracle.abi, provider_ETHO)

// mainnetblock = await provider_TOKEN.getBlock("latest")
// console.log(provider_TOKEN.getBlock("latest"))
// const TRANSMITTER_ACCOUNT = "0xcaded6e8b266b9fb5781506885283552387c748b"
// const TRANSMITTER_ACCOUNT = utils.arrayify(address_SIGNER_authority)


const SGNR_ETH = new Contract(address_SIGNER_ETH, Signer.abi, provider_ETHO) 
// const SGNR_BSC = new Contract(address_SIGNER_BSC, Signer.abi, provider_ETHO) 

const ETH_PRICE_URL = "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=eth"
// const ETHO_ETH_MIN_DEPOSIT = 1 // in dollars
// const ETH_MIN_WITHDRAWAL = 0.5 // in dollars

const MULTISIG_NAMES = {
  "0x6830EBcA6Da5277f2154aB94E36F807B4Af6Cc7a":"Allan",
  "0xA5D20A1e38ee9dFb04e518ebBd5815d9cA3ab321":"Chris",
  "0x66D35434Fa243F28c0B121BCecf5AeB3D699fd4C":"Def",
  "0xfb7f3074B16E5B2eF73C775ADCD3bf48B8cFF959":"Dox",
  "0x87F0EA8C325caFBBB8BB748756ef147b7A50405d":"Dylie",
  "0x68EfF0F98Cbe958e8865684d3Dca06a78b0dA46e":"Exlo",
  "0x83ba450AD84e1De69E577F1a62d75dc9bF6B8118":"Frank",
  "0x8278617661CbE273aF2D81972ae89037526e9d72":"Saiyan",
}

console.log(MULTISIG_NAMES)
console.log(address_VERIFIER_ETH)
console.log(address_VERIFIER_BSC)
console.log(EXPLORER_ETH_ADDRESS)
console.log(EXPLORER_BSC_ADDRESS)
// let _tx = "0x6a7d68cdd80d2b645ffce5aafcae892bdcc1389375665f92439cb6209de0b637"

// let encodePacked =  utils.concat([ TRANSMITTER_ACCOUNT, utils.toUtf8Bytes(_tx) ])
// const _hash = utils.keccak256(encodePacked)

// console.log("_hash",_hash)
// let result_hashes = SGNR.GetBridgeReceipt(_hash);

// console.log("result_hashes",result_hashes)
// console.log("_tx",_tx)



//const url_baseIP = "https://ethobridge.uc.r.appspot.com";
//const url_baseIP = "http://localhost:5533";
//const url_baseIP = "http://52.50.170.41:5533";
// const url_baseIP = "https://bridge.ethoprotocol.com/api";
//const url_baseIP = "https://52.50.170.41/api";


//const url_baseIP = "http://77.20.0.26:5533";

// const url_ethoTOwetho = url_baseIP+"/ETHOtoWETHO/ETH/";
// const url_wethoTOetho = url_baseIP+"/WETHOtoETHO/ETH/";

//// console.log("TKN",TKN)

function removeTrailingZeros(str) {
  if (str === '0') return str
  if (str.slice(-1) === '0') return removeTrailingZeros(str.substr(0, str.length - 1))
  if (str.slice(-1) === '.') return str.substr(0, str.length - 1)
  return str
}
function numstrToBN(input) {
  const spl = input.split('.')
  if (spl[1]) spl[1] = spl[1].substr(0, 18)
  return BigNumber.from(spl.join('') + '000000000000000000'.substr(0, 18 - (spl[1] || '').length))
}
function BNStrToNumstr(str, precision = 3) {
  ////// console.log("str",str)
  str = str.toString()
  if (str === '0') return str
  if (isNaN(Number(str))) return 'NaN'
  if (str.length <= 18) return removeTrailingZeros(('0.' + '000000000000000000'.substr(0, 18 - str.length) + str).substr(0, 18 - str.length + precision + 2))
  else return [str.substr(0, str.length - 18), str.slice(-18)].join('.').substr(0, str.length - 18 + precision + 1)
}
function EthoWithComma(amount) {
    var str = amount.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
}


// function Sleep(milliseconds) {
//  return new Promise(resolve => setTimeout(resolve, milliseconds));
// }

export default {
  name: "History",
  data() {
    return {
      past_transactions: [],
      MULTISIGOWNERS: [],
      headers: [
            // { text: 'Signatures', value: 'status' ,sortable:true , width:'150px'},
            // { text: 'Id', value: 'tx_nr' ,sortable:true},
            
            { text: 'Signatures', value: 'status' ,sortable:true },
            // { text: 'Signed', value: 'signature_count' ,sortable:false },
            { text: 'Event', value: 'event_name' ,sortable:true},
            { text: 'Amount', value: 'amount' ,sortable:true},
            { text: 'Fee', value: 'fee' ,sortable:true},
            {
              text: 'Network',
              align: 'start',
              sortable: false,
              value: 'target_network',
            },
            
            
            //{ text: 'Data', value: 'dataLink' },
            { text: 'To', value: 'to' ,sortable:true},
            { text: 'Tx', value: 'tx_id' ,sortable:true},
     
            // { text: 'Storage Cost', value: 'storagecost_etho' },
            //{ text: 'Expiration (Date)', value: 'expiration_date' },
            // { text: 'Expiration Date', value: 'ExpirationTime', width:'10px', sortable: true},
            // { text: 'Copies', align: 'start', value: 'hostingReplicationFactor', sortable: true},
            // { text: 'Action', align: 'start', value: 'contractActions', sortable: false, width:'100px'},
        ],

        // amount: last_transaction["amount"],
        // event_name: last_transaction["event_name"],
        // fee: last_transaction["fee"],
        // message_hash: last_transaction["message_hash"],
        // status: last_transaction["status"],
        // target_network: last_transaction["target_network"],
        // to: last_transaction["to"],
        // tx_id: last_transaction["tx_id"],


      balanceNumStr_Ether1: '',
      directionIndicatorEthToEtho: false,
      directionIndicatorEthoToEth: true,
      provider: providers.Web3Provider | null,
      signer: providers.JsonRpcSigner | null,
      wallet: '',
      mainnet_chainID:mainnet_chainID,
      token_chainID:token_chainID,
      token_chainID2:token_chainID2,
      DEV_WALLET_ETHO:DEV_WALLET_ETHO,
      DEV_WALLET_ETH:DEV_WALLET_ETH,
      DEV_WALLET_BSC:DEV_WALLET_BSC,
      EXPLORER_ETHO_ADDRESS:EXPLORER_ETHO_ADDRESS,
      EXPLORER_ETH_ADDRESS:EXPLORER_ETH_ADDRESS,
      EXPLORER_BSC_ADDRESS:EXPLORER_BSC_ADDRESS,
      EXPLORER_ETHO_TX:EXPLORER_ETHO_TX,
      EXPLORER_ETH_TX:EXPLORER_ETH_TX,
      EXPLORER_BSC_TX:EXPLORER_BSC_TX,
      address_VERIFIER_ETH:address_VERIFIER_ETH,
      address_VERIFIER_BSC:address_VERIFIER_BSC,
      address_MULTISIG_ETH:address_MULTISIG_ETH,
      address_MULTISIG_BSC:address_MULTISIG_BSC,
      ETH_PRICE_URL:ETH_PRICE_URL,
      // ETH_LOGO:ETH_LOGO,
      address_TKN:address_TKN,
      address_BA:address_BA,
      // address_ORCL:address_ORCL,
      address_SIGNER_ETH:address_SIGNER_ETH,
      address_SIGNER_BSC:address_SIGNER_BSC,
      bridge_status: 'loading...',
      inbetweenstatus: '',
      currentLocked_ETHO: '',
      currentLocked_ETH: '',
      currentBurned: '',
      currentBalance: '',
      currentBalanceETH: '',
      currentBalanceETHO: '',
      balanceNumStr_TKN: '',
      inputAmount: '',
      etho_price: -1,
      last_etho_price: Date.now(),
      costsInfo: {
        WETHOtoETHO: '',
        ETHOtoWETHO: ''
      } | null,
      paramsInfo: {
        minETH: 0,
        minETHO: 0,
        preShutdownFlag: false
      } | null,
      warningMessage: '',
      hintMessage: '',
      successMessage: '',
      loading_request: false,
      loading_controllerInfo: true,
      loading_contractInfo: true,
      // inBetween: false,
      hashes: { txHashCollect: '', txHashDispense: '' } | null,
    }
  },
  computed: {
    // eth_logo() {
    //   return require(ETH_LOGO);
    // },
    burnedEnough() {
      if (!this.controllerInfoOk || !this.contractInfoOk) return false
      if(this.directionIndicatorEthoToEth) {
        return this.burnedBN.gte(this.minBNStr)
      } else {
        return this.burnedBN.gte(this.minTKNStr)
      }
    },
    burnedBN() {
      return BigNumber.from(this.currentBurned)
    },
    convertBN(_nr) {
      return BigNumber.from((_nr.toString()))
    },
    amountEqual() {
      if(this.directionIndicatorEthoToEth) {
        return this.amountBN.eq(this.currentLocked_ETHO)
      }
      return this.amountBN.eq(this.currentBurned)
    },
    entryExists() {
      if (!this.contractInfoOk || !this.currentLocked_ETHO) return false
      return this.currentLocked_ETHO.gt(0)
    },
    canMaximizeAmount() {
      return !this.burnedNonZero && ((this.amountBN.lt(this.currentBalanceETHO) && this.directionIndicatorEthoToEth) || (this.amountBN.lt(this.currentBalanceETH) && this.directionIndicatorEthToEtho))
    },
    amountReceivedBNStr_ready() {
      if (!this.controllerInfoOk || !this.contractInfoOk) return 'NaN'
      return this.currentLocked_ETHO.sub(this.feeBNStr).toString()
    },
    providerOk() {
      return !!this.provider && !!this.signer && !!this.wallet
    },
    controllerInfoOk() {
      return !!this.costsInfo && !!this.paramsInfo
    },
    contractInfoOk() {
      // console.log("cinfo",!!this.currentLocked_ETHO,!!this.currentBalance)
      // console.log("cinfo",!!this.currentBalanceETHO,!!this.currentBalanceETH)
      if(this.directionIndicatorEthoToEth) {
        // return !!this.currentLocked_ETHO && !!this.currentBalance
        return !!this.currentBalanceETHO
      } else {
        return !!this.currentBalanceETH
      }
      //return !!this.currentBurned && !!this.currentBalance
      // return !!this.currentBalance

    },
    allSafe() {
      return this.providerOk && this.controllerInfoOk && this.contractInfoOk && !this.warningMessage && !this.loading_controllerInfo
    },
    requestDisabled() {
      return !this.allSafe || !this.balanceEnough || (!this.amountEnough && !this.aboutToNullify)
    },
    aboutToNullify() {
      // return this.amountZero//&& this.entryExists //  && this.inputAmount != 0 
      return false
    },
    amountZero() {
      return this.inputAmount === '0'
    },
    amountValid() {
      return !!Number(this.inputAmount) || this.amountZero
    },
    amountEnough() {
      if (!this.controllerInfoOk) return false
      if(this.directionIndicatorEthoToEth) {
        return this.amountBN.gte(this.minBNStr)
      } else {
        return this.amountBN.gte(this.minTKNStr)
      }
    },
    canSwap() {
      if (!this.controllerInfoOk) return false
      if(this.directionIndicatorEthoToEth) {
        return this.currentBalanceETHO.gte(this.minBNStr)
      } else {
        return this.currentBalanceETH.gte(this.minTKNStr)
      }
    },
    balanceEnough() {
      if (!this.controllerInfoOk) return false
      
      if(this.directionIndicatorEthoToEth) {
        return this.amountBN.lte(this.currentBalanceETHO)
      } else {
        return this.amountBN.lte(this.currentBalanceETH)
      }
    },
    lockedEnough() {
      if (!this.controllerInfoOk || !this.contractInfoOk) return false
      if(this.directionIndicatorEthToEtho) {
        return BigNumber.from(this.currentLocked_ETHO).gte(this.minTKNStr)
        //return true
      } else {
        return true // always enough Wrapped etho existing ;)
      }

    },
    amountBN() {
      if (!this.amountValid) return BigNumber.from(0)
      return numstrToBN(this.inputAmount)
    },
    amountReceivedBNStr() {
      if (!this.controllerInfoOk) return 'NaN'
      return this.amountBN.sub(this.feeBNStr).toString()
    },
    feeBNStr() {
      //// console.log("ETHOOOOOO BRIDGE",this.controllerInfoOk)
      if (!this.controllerInfoOk) return 'NaN'
      if(this.directionIndicatorEthoToEth) {
        //return BigNumber.from(this.costsInfo.ETHOtoWETHO).mul(this.paramsInfo.costToFeeQuotient).div('100').toString()
        return BigNumber.from(this.costsInfo.ETHOtoWETHO).toString()
        
      } else {
        //return BigNumber.from(this.costsInfo.WETHOtoETHO).mul(this.paramsInfo.costToFeeQuotient).div('100').toString()
        return BigNumber.from(this.costsInfo.WETHOtoETHO).toString()
      }
      
    },
    minTKNStr() {
      if (!this.controllerInfoOk) return 'NaN'
      //return BigNumber.from(this.costsInfo.WETHOtoETHO).add(this.paramsInfo.minETH).toString()
      return BigNumber.from(this.paramsInfo.minETHO).toString()
    },
    minBNStr() {
      if (!this.controllerInfoOk) return 'NaN'
      return BigNumber.from(this.paramsInfo.minETH).toString()
      //return BigNumber.from(this.costsInfo.ETHOtoWETHO).add(this.paramsInfo.minETHO).toString()
      //return BigNumber.from(this.costsInfo.ETHOtoWETHO)
      //  .mul(this.paramsInfo.costToFeeQuotient)
      //  .mul(this.paramsInfo.feeToMinQuotient)
      //  .mul('120')
      //  .div('1000000')
      //  .toString()
    },


  },
  async mounted() {
    // await this.loadControllerInfo()
    // if (this.controllerInfoOk) await this.connectProvider()
    // this.loading_contractInfo = false
    // setInterval(async () => {
    //   if (this.controllerInfoOk) {
    //     this.loading_controllerInfo = true
    //     // await this.loadControllerInfo()
    //   }
    // }, 30000)
    // window.ethereum.on('networkChanged', () => {
    //   this.updateNetwork();
    // })
    // window.ethereum.on('accountsChanged', () => {
    //   this.handleAccountsChanged();
    // })


    this.loadReceipts()


  },
  methods: {
    count_signatures(_list) {
      // return _list.length
      return new Set(_list).size;
    },
    // get_signature_names(_list) {
    //   var results = "";
    //   for (var i = 0; i < _list.length; i++)
    //   {
        
    //     results += this.getMultisigName(_list[i])
    //   }
    //   return results
    // },
    getMultisigName(_name) {
      // console.log(_name)
      // console.log(MULTISIG_NAMES[_name] == undefined)
      // console.log(MULTISIG_NAMES["0x32532"] == undefined)
      if (MULTISIG_NAMES[_name] == undefined) {
        return _name
      } else {
        return MULTISIG_NAMES[_name]
      }
      
    },
    changeEthDirection(manually_changed) {
      console.log("CHANGEDIRECTION MANUALLY:",manually_changed)
      if(this.directionIndicatorEthToEtho) {
        this.directionIndicatorEthToEtho = false;
        this.directionIndicatorEthoToEth = true;
        this.updateNetwork(manually_changed)
      } else {
        this.directionIndicatorEthToEtho = true;
        this.directionIndicatorEthoToEth = false;
        this.updateNetwork(manually_changed)
      }
    },
    async updateNetwork(manually_changed) {
      this.hashes = null
      this.warningMessage = '';
      this.hintMessage = '';
      this.successMessage = '';
      this.loading_controllerInfo = true
      // await this.loadControllerInfo()
      if (this.controllerInfoOk) await this.connectProvider(manually_changed)    
      this.loading_contractInfo = false
    },
    BNStrToNumstr,
    EthoWithComma,
    async SwitchNetwork(targetID) {
        console.log("SWITCHING NETWORK NOW...")
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: targetID }], // chainId must be in hexadecimal numbers
        });
    },
    restoreInputAmount() {
      //this.inputAmount = removeTrailingZeros(BNStrToNumstr(this.currentLocked_ETHO, 18))
      //this.inputAmount = removeTrailingZeros(BNStrToNumstr(this.minBNStr, 8))
      if(this.directionIndicatorEthoToEth) {
        this.inputAmount = BNStrToNumstr(this.currentBalanceETHO.sub(BigNumber.from("100000000000000000")),18) || 0
      } else {
        // this.inputAmount = BNStrToNumstr(this.currentBalanceETH.sub(BigNumber.from("1000000000000000000")),18) || 0
        this.inputAmount = BNStrToNumstr(this.currentBalanceETH,18) || 0
      }

    },
    handleClickAppend() {
      if (this.burnedEnough && !this.amountEqual) this.restoreInputAmount()
      else if (this.canMaximizeAmount) this.maximizeInputAmount()
    },
    async loadEther1Balance() {
      if(this.directionIndicatorEthoToEth) {
        try {

          // ETHO BALANCE
          let thisBalance = await provider_ETHO.getBalance(this.wallet);
          this.currentBalanceETHO = BigNumber.from(thisBalance);

          this.balanceNumStr_Ether1 = this.currentBalanceETHO;

        } catch (error) {
          console.error(error)
        }
      } else if(this.directionIndicatorEthToEtho) {
        try {

          // ETHO BALANCE
          let thisBalance = await provider_ETHO.getBalance(this.wallet);
          this.currentBalanceETHO = BigNumber.from(thisBalance); //thisBalance.toString();

          this.balanceNumStr_Ether1 = this.currentBalanceETHO;

        } catch (error) {
          console.error(error)
        }
      }
    },
    async loadTKNBalance() {
      try {
        let balanceAmount = await TKN.balanceOf(this.wallet);
        this.balanceNumStr_TKN = BigNumber.from(balanceAmount);
        
        // ETH BALANCE
        this.currentBalanceETH = BigNumber.from(balanceAmount);
      } catch (error) {
        console.error(error)
      }
    },
    async getEthoPrice() {
      try {
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.open( "GET", "https://api.coingecko.com/api/v3/simple/price?ids=ether-1&vs_currencies=usd", false );
        xmlHttp.send( null );
        let jsonData = JSON.parse(xmlHttp.responseText)
        console.log("etho_price","--",jsonData,jsonData["ether-1"]["usd"])

        if (jsonData["ether-1"] !== undefined) {
          return parseFloat(jsonData["ether-1"]["usd"])
        } else {
          return -1
        }
      } catch (error) {
        return -1
      }

    },
    async connectProvider(manually_changed) {
      try {
        var insideScope = this.$parent.$parent.showEthBridge
        if (insideScope) {
          if (!window.ethereum) throw new Error('Please set up MetaMask properly')
          await (window.ethereum).enable()
          this.provider = new providers.Web3Provider((window.ethereum) || window.web3)

          var found_chainID = (await this.provider.getNetwork()).chainId

          

          //console.log("manually_changed",manually_changed,"A token_chainID",token_chainID,"found_chainID",found_chainID,"controllerInfoOk",this.controllerInfoOk,"direction",this.directionIndicatorEthoToEth,"showEthBridge",insideScope)

        
          if(this.directionIndicatorEthoToEth) {
            if (found_chainID !== mainnet_chainID) {
              if (found_chainID === token_chainID && manually_changed !== true) { // swap networks..
                
                this.changeEthDirection()
              } else {
                // if (manually_changed === true) {
                  this.SwitchNetwork("0x"+mainnet_chainID.toString(16))
                // }

                throw new Error('You selected wrong network in MetaMask. Make sure you imported and selected Etho Protocol network and refresh the page')
              }
            }
          } else if (this.directionIndicatorEthToEtho) {
            if (found_chainID !== token_chainID) {
              if (found_chainID === mainnet_chainID && manually_changed !== true) { // swap networks..
                
                this.changeEthDirection()
              } else {
                // if (manually_changed === true) {
                  this.SwitchNetwork("0x"+token_chainID.toString(16))
                // }
                throw new Error('You selected wrong network in MetaMask. Make sure you selected Binance Smart Chain network and refresh the page')
              }
            }
          }

          this.signer = this.provider.getSigner()
          this.wallet = await this.signer.getAddress()
          //// console.log("this.wallet",this.wallet)
          await this.loadContractInfo()
        }


        //if (this.entryExists) this.restoreInputAmount()
      } catch (error) {
        this.warningMessage = 'Could not connect MetaMask. Error: ' + error.message
        console.error(error)
      }
    },
    async handleClickRequest() {
      this.hashes = null
      this.warningMessage = '';
      this.hintMessage = '';
      this.successMessage = '';
      this.loading_request = true
      // this.signer = this.provider.getSigner()

      if(this.directionIndicatorEthoToEth) {
        try {
          if (this.aboutToNullify) {
            await this.deposit()
          } else {
            //if ((this.entryExists && !this.amountEqual) || !this.entryExists) 
            var local_tx = await this.deposit()
            //// console.log("local_tx",local_tx)
            await this.requestOE(local_tx)
          }
          this.inputAmount = ''
        } catch (error) {
          this.hintMessage = error.message
          console.error(error)
        }
      } else if(this.directionIndicatorEthToEtho) {
        try {
          if ((this.burnedNonZero && !this.amountEqual) || !this.burnedNonZero) 
            var local_tx2 = await this.burn()
            //// console.log("local_tx2",local_tx2)
            await this.requestOE(local_tx2)
            this.inputAmount = ''
        } catch (error) {
          this.hintMessage = error.message
          console.error(error)
        }
      }

      // this.inBetween = false
      this.inbetweenstatus = ''
      this.loading_request = false
    },
    async burn() {
      var execute_chainID = (await this.provider.getNetwork()).chainId
      if (execute_chainID !== token_chainID) { // make sure user doesn't send into false network for sum bug reason
        throw new Error('You selected wrong network in MetaMask. Make sure you selected Ethereum Mainnet Chain network and refresh the page')
      }

      //// console.log("BURN...",this.amountBN,this.amountBN.toString())

      const TKN = new Contract(address_TKN, Token.abi, this.signer)

      //// console.log("TKN",TKN)

      //const ptx = await TKN.populateTransaction.burn(address_BA, this.amountBN)
      const ptx = await TKN.populateTransaction.burn(this.amountBN.toString()) // make user burn directly
      console.log({
        ptx,
        amt: this.amountBN.toString(),
        bal: this.currentBalanceETH.toString(),
      })
      
      const tx = await this.signer.sendTransaction(ptx)
      //// console.log({ tx })
      // this.inBetween = true

      this.triggerinbetweentracking(tx)

      const receipt = await tx.wait()

      this.triggerinbetweentracking2("token",receipt)

      await this.loadContractInfo() // pull this earlier since we changed contract
      
      //// console.log(receipt,receipt.transactionHash)
      return receipt.transactionHash
    },
    async triggerinbetweentracking(_tx) {
      console.log("-->tx:",_tx)
      let mainnetblock = await provider_TOKEN.getBlock("latest")
      let tokenblock = await provider_ETHO.getBlock("latest")
      console.log("token:",mainnetblock)
      console.log("etho:",tokenblock)
      console.log("tokennumber:",mainnetblock.number)
      console.log("ethonumber:",tokenblock.number)

      this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete."
    },
    async triggerinbetweentracking2(_net,_receipt) {
      console.log("--->receipt:",_receipt)
      console.log("token:",provider_TOKEN.blockNumber)
      console.log("etho:",provider_ETHO.blockNumber)
      let startblock = -1
      let startblock2 = -1
      if (_net == "etho") {
        let ethoblock = await provider_ETHO.getBlock("latest")
        startblock = ethoblock.number
      } else {
        let tokenblock = await provider_TOKEN.getBlock("latest")
        startblock = tokenblock.number
      }
      
      if (startblock == -1) {
        this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete."
      } else {
        var waitblocks = 20
        if (_net == "etho") {
          waitblocks = ETHO_CONFIRMATIONS
        } else {
          waitblocks = TOKEN_CONFIRMATIONS
        }
        let currentblock = 0
        while (currentblock < waitblocks) {
          if (_net == "etho") {
            let ethoblock = await provider_ETHO.getBlock("latest")
            currentblock = ethoblock.number- startblock - 2
            if (currentblock < 0) {
              currentblock = 0
            }
            this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete. Confirmation Block: "+currentblock+" / "+(ETHO_CONFIRMATIONS-2)
          } else {
            let tokenblock = await provider_TOKEN.getBlock("latest")
            currentblock = tokenblock.number - startblock - 2
            if (currentblock < 0) {
              currentblock = 0
            }
            this.inbetweenstatus = "Swap initiated. Sending request to multisig-bridge controller. This may take between 5-10 minutes to complete. Confirmation Block: "+currentblock+" / "+(TOKEN_CONFIRMATIONS-2)
          }
        }

        this.inbetweenstatus = "Confirmation Blocks Reached. Requesting Confirmation."

      if (_net == "etho") {
        let tokenblock = await provider_TOKEN.getBlock("latest")
        startblock2 = tokenblock.number
      } else {
        let ethoblock = await provider_ETHO.getBlock("latest")
        startblock2 = ethoblock.number
      }

        let waitblocks2 = 3
        let currentblock2 = 0
        while (currentblock2 < waitblocks2) { // CHECK OPPOSITE NETWORK, since we act crosschain now...
          if (_net == "etho") {
            let tokenblock = await provider_TOKEN.getBlock("latest")
            currentblock2 = tokenblock.number - startblock2
          } else {
            let ethoblock = await provider_ETHO.getBlock("latest")
            currentblock2 = ethoblock.number- startblock2
          }
          if (!this.successMessage) {
            this.inbetweenstatus = "Crosschain Confirmation: "+currentblock2+" / "+waitblocks2
          }
          
        }
        if (!this.successMessage) {
          this.inbetweenstatus = "Requesting Receipt from Controller...."
        }

      }

    },
    async deposit() {
      var execute_chainID = (await this.provider.getNetwork()).chainId
      if (execute_chainID !== mainnet_chainID) { // make sure user doesn't send into false network for sum bug reason
          throw new Error('You selected wrong network in MetaMask. Make sure you imported and selected Etho Protocol network and refresh the page')
      }

      const BAO = new Contract(address_BA, BridgeAssistO.abi, this.signer)
      const ptx = await BAO.populateTransaction.deposit()

      //const ptx = {
      //  from: this.wallet,
      //  to: address_BA
      //}

      ptx.value = this.amountBN
      console.log({
        ptx,
        amt: this.amountBN.toString(),
        bal: this.currentBalanceETHO.toString(),
      })
      const tx = await this.signer.sendTransaction(ptx)
      //// console.log({ tx })
      // this.inBetween = true
      this.triggerinbetweentracking(tx)

      const receipt = await tx.wait()

      this.triggerinbetweentracking2("etho",tx)

      //// console.log({ receipt })
      await this.loadContractInfo()
      
      return receipt.transactionHash
    },

    async handleAccountsChanged() {
      // console.log("A HANDLE ACCOUNT CHANGED")

      this.balanceNumStr_Ether1 = ""
      this.balanceNumStr_TKN = ""
      this.currentBalanceETH = ""
      this.feeBNStr = ""
      this.minTKNStr = ""
      this.currentBalanceETHO = ""

      this.warningMessage = '';
      this.hintMessage = '';
      this.successMessage = '';

      this.signer = this.provider.getSigner()
      this.wallet = await this.signer.getAddress()
      //// console.log("this.wallet",this.wallet)
      await this.loadContractInfo()
    },
   

    async updateBalances() {
      var locked_ETHO_Amount = await provider_ETHO.getBalance(address_BA);
      this.currentLocked_ETHO = BigNumber.from(locked_ETHO_Amount);

      var locked_ETH_Amount = await TKN.totalSupply();
      this.currentLocked_ETH = BigNumber.from(locked_ETH_Amount);

      // ETH BALANCE
      // var balanceAmount = await TKN.balanceOf(this.wallet);
      // this.currentBalanceETH = balanceAmount.toString()

      // ETHO BALANCE
      // var thisBalance = await this.signer.getBalance();
      // this.currentBalanceETHO = thisBalance.toString()


      await this.loadTKNBalance();
      await this.loadEther1Balance();


      if (this.currentLocked_ETH.lte(this.currentLocked_ETHO)) {
        this.bridge_status = "OK"
      } else {
        this.bridge_status = "NOT OK!"
      }
    },

    async loadContractInfo() {

      await this.updateBalances();

      if(this.directionIndicatorEthoToEth) {
        try {
          //const BAO = new Contract(address_BAO, BridgeAssistO.abi, this.signer)
          //var lockedAmount = await BAO.entryOf(this.wallet);
          //var locked_ETH_Amount = await providerO.getBalance(address_BA);
          //var thisBalance = await this.signer.getBalance();
          //var thisBalance = "30073900000000000000000";
          
          //const res = await Promise.all([BAO.entryOf(this.wallet), this.signer.getBalance()])

          // this.currentBalance = this.currentBalanceETHO;
          // console.log("this.currentBalance",this.currentBalance);
          // console.log("this.currentBalance2",BigNumber.from(this.currentBalance));
          // console.log("this.minBNStr",this.minBNStr);
          //this.restoreInputAmount(BNStrToNumstr(this.currentBalance))
          ////// console.log("thisBalance",thisBalance,"this.minBNStr",this.minBNStr,"this.minTKNStr",this.minTKNStr,"this.currentLocked_ETHO",this.currentLocked_ETHO,"this.currentLocked_ETH",this.currentLocked_ETH)

          if (this.currentBalanceETHO.lt(this.minBNStr) && this.inbetweenstatus != '') this.warningMessage = 'Your mainnet balance is lower than minimum amount. Usage is blocked'
          
          // await this.loadTKNBalance()
        } catch (error) {
          console.error(error)

          this.warningMessage = 'Could not load info from contract. Usage is blocked. Try refreshing the page, try later or contact support'
        }
      }
      else if(this.directionIndicatorEthToEtho) {
        try {
          //const TKN = new Contract(address_TKN, Token.abi, this.signer)
          //// console.log("This Wallet: " + this.wallet);
          //var burnedAmount = await TKN.balanceOf(this.wallet) // FALSE !!! //await TKN.allowance(this.wallet, address_BA);
          //var balanceAmount = await TKN.balanceOf(this.wallet);
          //const res = await Promise.all([TKN.allowance(this.wallet, address_BA), TKN.balanceOf(this.wallet)])
          ////// console.log(res0);



          //this.currentBurned = burnedAmount.toString();
          ////// console.log(this.currentBurned);
          ////// console.log("balanceAmount",balanceAmount.toString());
          // this.currentBalance = this.currentBalanceETH.toString()
          //this.restoreInputAmount(BNStrToNumstr(this.currentBalance))
          //// console.log(this.currentBalance);
          //// console.log("this.minTKNStr",this.minTKNStr)
          //// console.log("this.currentLocked_ETHO",this.currentLocked_ETHO)
          //// console.log("balanceAmount",balanceAmount.toString())
          if (this.currentLocked_ETH.lt(this.minTKNStr) && this.inbetweenstatus != '') this.warningMessage = 'Your token balance is lower than minimum amount. Usage is blocked'
          else if (this.currentLocked_ETHO.lt(this.currentLocked_ETH)) {
            //// console.log("balanceAmount",balanceAmount.toString(),"this.minTKNStr",this.minTKNStr,"this.currentLocked_ETHO",this.currentLocked_ETHO,"this.currentLocked_ETH",this.currentLocked_ETH)
            this.hintMessage = 'Your token balance is higher than the locked amount.'
          }

          // await this.loadEther1Balance()
        } catch (error) {
          this.warningMessage = 'Could not load info from contract. Usage is blocked. Try refreshing the page, try later or contact support'
        }
      }
      
    },

    async addReceipt(last_transaction,result_receipt_network,result_address_network,multisig_required_amount,tx_nr) {
      // if (last_transaction["transaction"]) {
      // if (last_transaction["transaction"]["status"] == "EXECUTED") {
        let _receipt = ""
        let _address = ""
        let signers = []
        let messageHash = ""
        let ethSignedMessageHash = ""
        let signers_str = ""
        // if ( last_transaction["transaction"]["event_name"] == "collect" || last_transaction["transaction"]["event_name"] == "force_collect") {
        //   _receipt = result_receipt_network + last_transaction["transaction"]["tx_id"]
        //   _address = EXPLORER_ETHO_ADDRESS + last_transaction["transaction"]["to"]
          

        // } else {

        //   _receipt = EXPLORER_ETHO_TX + last_transaction["transaction"]["tx_id"]
        //   _address = result_address_network + last_transaction["transaction"]["to"]
        // }

        _receipt = result_receipt_network + last_transaction["transaction"]["tx_id"]
        _address = result_address_network + last_transaction["transaction"]["to"]

        console.log("last_transaction",last_transaction["transaction"]["tx_id"])

        // let withdrawal_tx = await SGNR_ETH.validated_transactions(last_transaction["transaction"]["tx_id"]);
        // console.log("withdrawal_tx",withdrawal_tx)
        // let withdrawal_status = -1
        // if (withdrawal_tx != 0) {
        //   let validated_tx = await VERIFIER.validated_transactions(last_transaction["transaction"]["tx_id"]);
        //   console.log("validated_tx",validated_tx)
        //   if (validated_tx == 0x0000000000000000000000000000000000000000) {
        //     console.log("CAN BE WITHDRAWN")
        //     withdrawal_status = 1
        //   } else {
        //     console.log("ALREADY WITHDRAWN")
        //     withdrawal_status = validated_tx
        //   }
        // }



        if (last_transaction["sigs"] !== undefined) {
          let sigs = last_transaction["sigs"]
          messageHash = await SGNR_ETH.getMessageHash(last_transaction["transaction"]["to"], last_transaction["transaction"]["amount"], last_transaction["transaction"]["fee"], last_transaction["transaction"]["tx_id"], last_transaction["transaction"]["event_name"], last_transaction["transaction"]["target_network"]);
          ethSignedMessageHash = await SGNR_ETH.getEthSignedMessageHash(messageHash);
          console.log("ethSignedMessageHash",ethSignedMessageHash)
          signers = []
          
          for (var i = 0; i < sigs.length; i++)
          {
            let _signer = await SGNR_ETH.recoverSigner(ethSignedMessageHash, sigs[i])
            let _signername = this.getMultisigName(_signer)
            signers.push(_signername)
            signers_str += _signername + " "
          }
          console.log("ALL SIGNERS:",signers)

        }

        if (signers_str == "") {
          signers_str = "no signatures yet."
        }

        // const max_transactions_eth = await SGNR_ETH.transaction_i()

        this.past_transactions.push({
          amount: last_transaction["transaction"]["amount"],
          event_name: last_transaction["transaction"]["event_name"],
          // fee: last_transaction["transaction"]["fee"],
          fee: 0,
          message_hash: last_transaction["transaction"]["message_hash"],
          status: last_transaction["transaction"]["status"],
          target_network: last_transaction["transaction"]["target_network"],
          to: last_transaction["transaction"]["to"],
          tx_id: last_transaction["transaction"]["tx_id"],
          signature_count: this.count_signatures(signers) + " / " + multisig_required_amount,
          signature_names: signers_str,
          receipt: _receipt,
          address: _address,
          tx_nr: tx_nr,
        })
      // }
      // }
    },

    async loadReceipts() { // does this only load receipts for the tokens? BUT NOT FOR CUBIT -> sUSDT ? // 2024
      this.past_transactions = []
      // past_transactions
      // console.log("etho_multisi_transactions BSC",SGNR_BSC)
      console.log("etho_multisi_transactions ETH",SGNR_ETH)
      const max_transactions_eth = await SGNR_ETH.transaction_i()
      // const max_transactions_bsc = await SGNR_BSC.transaction_i()

      // const last_transaction = await SGNR.GetFullTransactionData(max_transactions_eth,"")
      // const etho_multisi_transactions = await SGNR.transactions()
      const etho_multisigowners = await SGNR_ETH.getOwners()
      const multisig_required_amount = await SGNR_ETH.getRequired()
      console.log("etho_multisigowners",etho_multisigowners)
      console.log("multisig_required_amount",multisig_required_amount)

      // let msghash = await SGNR_ETH.transaction_i()

      this.MULTISIGOWNERS = etho_multisigowners
 
      console.log("max_transactions_eth",max_transactions_eth)
      // console.log("last_transaction",last_transaction)
      // this.past_transactions = [last_transaction["transaction"]]
      // past_transactions = last_transaction["transaction"][0]
      var max_lookup = 25;
      var max_add = 10; // max amount of "valid" txs, like burn or mint, that we add before breaking
      var add_count = 0; // max amount of "valid" txs, like burn or mint, that we add before breaking
      for (let i = 0; i < max_lookup; i++) { // let's add alternating receipts
        if (add_count > max_add) {
          break; // break our final loop if we hit max tx count
        }
        let last_transaction = await SGNR_ETH.GetFullTransactionData(max_transactions_eth-i,"") // SGNR_ETH and SGNR_BSC are same contract
        if (last_transaction["transaction"]["tx_id"] == "") {
          break 
        }
        // if (last_transaction["transaction"]["event_name"] == "transferOwnership") {
          console.log(last_transaction["transaction"]["event_name"])
        // if (last_transaction["transaction"]["event_name"] == "transferOwnership") {
        if (last_transaction["transaction"]["event_name"].startsWith("Set")) {
          break
        }
        // let result_receipt = await SGNR_BSC.GetBridgeReceipt(last_transaction["transaction"]["tx_id"]);
        // console.log("result_receipt",result_receipt)
        add_count += 1;
        if (last_transaction["transaction"]["target_network"]  == token_chainID) {
          this.addReceipt(last_transaction,EXPLORER_ETH_TX,EXPLORER_ETH_ADDRESS,multisig_required_amount,max_transactions_eth-i)
        } else {
          this.addReceipt(last_transaction,EXPLORER_BSC_TX,EXPLORER_BSC_ADDRESS,multisig_required_amount,max_transactions_eth-i) 
        }
       

        // last_transaction = await SGNR_ETH.GetFullTransactionData(max_transactions_eth-i,"")
        // console.log("last_transaction",last_transaction)
        // if (last_transaction["transaction"]["tx_id"] == "") {
        //   break
        // }
        // console.log(last_transaction["transaction"]["event_name"])
        // // if (last_transaction["transaction"]["event_name"] == "transferOwnership") {
        // if (last_transaction["transaction"]["event_name"].startsWith("Set")) {
        //   break
        // }

        // result_receipt = await SGNR_ETH.GetBridgeReceipt(last_transaction["transaction"]["tx_id"]);

        // this.addReceipt(last_transaction,EXPLORER_ETH_TX,EXPLORER_ETH_ADDRESS,multisig_required_amount,max_transactions_eth-i)

      }
    },

  },
};
</script>
